import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Divider,
  Stack,
  IconButton,
  Card,
  Alert,
  Chip,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import GlobalHeader from "../../components/header";
import { useEffect, useState } from "react";
import useSnack from "../../hooks/useSnack";
import SnackAlert from "../../components/SnackAlert";
import useApi from "../../hooks/useApi";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { LoadingButton } from "@mui/lab";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import ChatIcon from "@mui/icons-material/Chat";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import ModalPatient from "./modalPatient";
import dateToInput from "../../utils/DateToInput";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteRegister from "./deleteRegister";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import BackClients from "./back";
import DeleteRegisterFile from "./deleteFile";
import { TapsHeaders } from "../../components/tapsHeaders";
import { fetchOccupation } from "../../utils/profesion";
import {
  ValidateInfo,
  ValidateModalAndBill,
  ValidateModal,
} from "../../utils/validateModal";

export default function InfoClient() {
  const { clientid } = useParams();
  const navigate = useNavigate();
  const { loadApi, loadingApi } = useApi();
  const { msgSB, openSB, showSnack, typeSB } = useSnack();
  const [openbackd, setOpenbackd] = useState(false);
  const [centers, setCenters] = useState();
  const [info, setInfo] = useState({
    address: null,
    email: null,
    lastname: null,
    name: null,
    national_id: "",
    note: null,
    phone: null,
  });
  const [infoError, setInfoError] = useState({
    address: false,
    email: false,
    lastname: false,
    name: false,
    national_id: false,
    note: false,
    phone: false,
  });
  const [openModal, setOpenModal] = useState(false);
  const [openModaldelete, setOpenModaldelete] = useState(false);
  const [openModaldeleteFile, setOpenModaldeleteFile] = useState(false);
  const [namecomplete, setNamecomplete] = useState("");
  const [photo_profile, setPhoto_profile] = useState();
  const [registros, setRegistros] = useState([]);
  const [enableAdditionalFields, setEnableAdditionalFields] = useState(true);
  const [occupation,setOccupation] = useState("");
  const [mainData, setMainData] = useState({
    center_id: "",
    date: "",
    note: "",
    file: "",
  });
  const [mainDataError, setMainDataError] = useState({
    center_id: false,
    date: false,
    note: false,
    file: false,
  });

  const [mainDataNewBill, setMainDataNewBill] = useState({
    center_id: "",
    client_id: clientid,
    name_rate: "",
    amount: "",
    date: "",
    status: "",
    method: "",
  });
  const [newBillError, setNewBillError] = useState({
    center_id: false,
    client_id: false,
    name_rate: false,
    amount: false,
    date: false,
    status: false,
    method: false,
  });
  useEffect(() => {
    loadApi("center/centers", true, "get")
      .then((response) => {
        setCenters(response.data);
      })
      .catch((e) => {
        showSnack(e.message);
      });
    fetchOccupation(loadApi,showSnack,setOccupation);
  }, []);
  const [centersfiltred, setCentersfiltred] = useState();
  useEffect(() => {
    loadApi("center/centers_on", true, "get")
      .then((response) => {
        setCentersfiltred(response.data);
      })
      .catch((e) => {
        showSnack(e.message);
      });
  }, []);


  const handleCloseModal = () => {
    setOpenModal(false);
    setMainDataError({
      center_id: false,
      date: false,
      note: false,
      file: false,
    });
    setMainData({
      center_id: "",
      date: "",
      note: "",
      file: "",
    });
    setMainDataNewBill({
      center_id: "",
      client_id: clientid,
      name_rate: "",
      amount: "",
      date: "",
      status: "",
      method: "",
    });
    setNewBillError({
      center_id: false,
      client_id: false,
      name_rate: false,
      amount: false,
      date: false,
      status: false,
      method: false,
    });
  };
  const handleCloseModaldelete = () => {
    setOpenModaldelete(false);
  };
  // consulta la foto del profesional
  useEffect(() => {
    setOpenbackd(true);
    loadApi("profile", true, "get")
      .then((response) => {
        setPhoto_profile(response.data.photo_profile.url);
      })
      .catch((e) => {
        showSnack(e.message);
      })
      .finally((e) => {
        setOpenbackd(false);
      });
  }, []);
  // consulta la iinformacion personal del paciente
  useEffect(() => {
    setOpenbackd(true);
    loadApi(`client/client/${clientid}`, true, "get")
      .then((response) => {
        setInfo(response.data);

        setNamecomplete(response.data.name + " " + response.data.lastname);
      })
      .catch((e) => {
        showSnack(e.message);
        if (e.message) {
          setTimeout(() => {
            navigate("/clients");
          }, 800);
        }
        setOpenbackd(true);
      })
      .finally((e) => {
        setOpenbackd(false);
      });
  }, []);
  useEffect(() => {
    setOpenbackd(true);
    loadApi(`querie/queries/${clientid}`, true, "get")
      .then((response) => {
        setRegistros(response.data);
      })
      .catch((e) => {
        showSnack(e.message);
      })
      .finally((e) => {
        setOpenbackd(false);
      });
  }, []);
  const handleSubmitinfo = async (e) => {
    e.preventDefault();
    if (!ValidateInfo(info, setInfoError)) return;
    setOpenbackd(true);
    loadApi(`client/update/${clientid}`, true, "put", {
      ...info,
    })
      .then((response) => {
        showSnack(response.data.message, "success");
        setInfoError({
          address: false,
          email: false,
          lastname: false,
          name: false,
          national_id: false,
          note: false,
          phone: false,
        });
      })
      .catch((e) => {
        showSnack(e.message);
      })
      .finally((e) => {
        setNamecomplete(info.name + " " + info.lastname);
        setOpenbackd(false);
      });
  };
  const handleSubmitnote = async (e) => {
    e.preventDefault();

    setOpenbackd(true);

    loadApi(`client/update_note/${clientid}`, true, "put", {
      note: info.note,
    })
      .then((response) => {
        showSnack(response.data.message, "success");
      })
      .catch((e) => {
        showSnack(e.message);
      })
      .finally((e) => {
        setOpenbackd(false);
      });
  };
  const handleSubmitmodal = async (e) => {
    e.preventDefault();
    if (!ValidateModal(mainData, setMainDataError)) return;

    if (enableAdditionalFields === true) {
      //se manda sin factura
      setOpenModal(false);
      setOpenbackd(true);
      loadApi(
        `querie/${clientid}`,
        true,
        "post",
        {
          ...mainData,
        },
        true
      )
        .then((response) => {
          setRegistros((adata) => {
            const newRecordData = [...adata, response.data.recordData];
            newRecordData.sort((a, b) => new Date(b.date) - new Date(a.date));
            return newRecordData;
          });
          setMainData({
            center_id: "",
            date: "",
            note: "",
          });
          showSnack(response.data.message, "success");
        })
        .catch((e) => {
          showSnack(e.message);
        })
        .finally((e) => {
          setOpenbackd(false);
          handleCloseModal();
        });
    } else {
      // se mandan con factura
      if (!ValidateModalAndBill(mainDataNewBill, setNewBillError)) return;
      setOpenModal(false);
      setOpenbackd(true);
      loadApi(
        `querie/${clientid}`,
        true,
        "post",
        {
          ...mainData,
        },
        true
      )
        .then((response) => {
          setRegistros((adata) => {
            const newRecordData = [...adata, response.data.recordData];
            newRecordData.sort((a, b) => new Date(b.date) - new Date(a.date));
            return newRecordData;
          });
          setMainData({
            center_id: "",
            date: "",
            note: "",
          });
          showSnack(response.data.message, "success");
        })
        .catch((e) => {
          showSnack(e.message);
        })
        .finally((e) => {
          setOpenbackd(false);
          handleCloseModal();
        });
      //-------
      loadApi("invoice", true, "post", {
        ...mainDataNewBill,
      })
        .then((response) => {
          showSnack(response.data.message, "success", 2000);
        })
        .catch((e) => {
          showSnack(e.message);
        })
        .finally((e) => {
          setOpenbackd(false);
        });
    }
  };

  const VisuallyHiddenInput = (props) => (
    <input
      type="file"
      style={{ display: "none" }}
      accept=".xlsx,.xls,.doc,.docx,.pdf,.txt,image/*"
      {...props}
    />
  );
  const deleteregister = (id) => {
    setOpenbackd(true);
    loadApi(`querie/delete/${id}`, true, "delete")
      .then((response) => {
        showSnack(response.data.message, "success", 1000);
        //eliminar por id del registro
        setRegistros((adata) => adata.filter((aitem) => aitem.id !== id));
      })
      .catch((e) => {
        showSnack(e.message);
      })
      .finally((e) => {
        setOpenbackd(false);
      });
  };
  const updateRegister = (id, index) => {
    setOpenbackd(true);

    loadApi(
      `querie/update_querie/${id}`,
      true,
      "put",
      {
        ...registros[index],
      },
      true
    )
      .then((response) => {
        showSnack(response.data.message, "success");
      })
      .catch((e) => {
        showSnack(e.message);
      })
      .finally((e) => {
        setOpenbackd(false);
      });
  };
  useEffect(() => {
    if (!info.name && info.name !== null) {
      setInfoError((adata) => ({
        ...adata,
        name: true,
      }));
    } else {
      setInfoError((adata) => ({
        ...adata,
        name: false,
      }));
    }
    if (!info.lastname && info.lastname !== null) {
      setInfoError((adata) => ({
        ...adata,
        lastname: true,
      }));
    } else {
      setInfoError((adata) => ({
        ...adata,
        lastname: false,
      }));
    }
  }, [info.name, info.lastname]);
  const deleteregisterFile = (id, index) => {
    setRegistros((adata) => {
      let newData = [...adata];
      newData[index].file = "";

      return newData;
    });
    setOpenbackd(true);
    loadApi(
      `querie/update_querie/${id}`,
      true,
      "put",
      {
        ...registros[index],
      },
      true
    )
      .then((response) => {
        showSnack(response.data.message, "success");
      })
      .catch((e) => {
        showSnack(e.message);
      })
      .finally((e) => {
        setOpenbackd(false);
      });
  };

  const menu = [
    { label: "Datos", path: `/client/${clientid}` },
    { label: "Historial", path: `/client/register/${clientid}` },
    { label: "Agenda", path: `/client/diary/${clientid}` },
    { label: "Cobros", path: `/client/bill/${clientid}` },
    { label: "Comunicaciones", path: `/client/chat/${clientid}` },
  ]

  return (
    <>
      <GlobalHeader LogoProfile={photo_profile} />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openbackd}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container
        maxWidth="lg"
        sx={{ alignItems: "center", background: "#FFFFFF", p: 2 }}
      >
        <BackClients />

        <TapsHeaders clientid={clientid} namecomplete={namecomplete} menu={menu} />

        <Divider />

        <Grid container direction="row" spacing={2} mt={1} mb={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Card
              justifyContent="space-between"
              p={0}
              sx={{
                height: "570px",
              }}
            >
              <Stack justifyContent="center" alignItems="center" margin={2}>
                <Typography variant="h6" textAlign="center">
                  Información del 
                </Typography>
              </Stack>
              <Stack p={2} spacing={2}>
                <TextField
                  id="outlined-basic"
                  label="Nombre"
                  variant="outlined"
                  value={info && info.name}
                  InputProps={{
                    sx: { borderRadius: 2 },
                  }}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  onChange={({ target }) =>
                    setInfo((adata) => ({
                      ...adata,
                      name: target.value,
                    }))
                  }
                  required
                  error={infoError?.name}
                />

                <TextField
                  id="outlined-basic"
                  label="Apellidos"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    sx: { borderRadius: 2 },
                  }}
                  value={info && info.lastname}
                  InputLabelProps={{ shrink: true }}
                  onChange={({ target }) =>
                    setInfo((adata) => ({
                      ...adata,
                      lastname: target.value,
                    }))
                  }
                  error={infoError.lastname}
                  required
                />

                <TextField
                  label="N° Identificación"
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    sx: { borderRadius: 2 },
                  }}
                  disabled={true}
                  value={info && info.national_id}
                  InputLabelProps={{ shrink: true }}
                />

                <TextField
                  id="outlined-basic"
                  label="Celular"
                  variant="outlined"
                  type="text"
                  fullWidth
                  InputProps={{
                    sx: { borderRadius: 2 },
                  }}
                  value={info && info.phone}
                  InputLabelProps={{ shrink: true }}
                  onChange={({ target }) =>
                    setInfo((adata) => ({
                      ...adata,
                      phone: target.value,
                    }))
                  }
                  onKeyDown={(event) => {
                    // Permitir sólo números, la tecla de retroceso, la tecla de suprimir, las teclas de flecha izquierda y derecha
                    if (
                      !/[0-9]/.test(event.key) &&
                      event.key !== "Backspace" &&
                      event.key !== "Delete" &&
                      event.key !== "ArrowLeft" &&
                      event.key !== "ArrowRight"
                    ) {
                      event.preventDefault();
                    }
                  }}
                />

                <TextField
                  label="Correo electrónico"
                  id="outlined-basic"
                  variant="outlined"
                  type="email"
                  fullWidth
                  InputProps={{
                    sx: { borderRadius: 2 },
                  }}
                  value={info && info.email}
                  InputLabelProps={{ shrink: true }}
                  onChange={({ target }) =>
                    setInfo((adata) => ({
                      ...adata,
                      email: target.value,
                    }))
                  }
                />

                <TextField
                  id="outlined-basic"
                  label="Dirección"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    sx: { borderRadius: 2 },
                  }}
                  value={info && info.address}
                  InputLabelProps={{ shrink: true }}
                  onChange={({ target }) =>
                    setInfo((adata) => ({
                      ...adata,
                      address: target.value,
                    }))
                  }
                />
              </Stack>
              <Stack px={2}>
                <Button onClick={handleSubmitinfo}>
                  {" "}
                  <SaveOutlinedIcon sx={{ marginInlineEnd: 1 }} />
                  Guardar
                </Button>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Card
              justifyContent="space-between"
              sx={{
                height: "570px",
                px: 2,
              }}
            >
              <Stack spacing={2} height="100%" justifyContent="space-between">
                <Stack pt={2}>
                  <Typography variant="h6" textAlign="center">
                    Notas
                  </Typography>
                </Stack>
                <Stack>
                  <TextField
                    id="outlined-textarea"
                    variant="outlined"
                    placeholder={`Notas acerca del ${occupation}`}
                    multiline
                    rows="16.9"
                    sx={{
                      height: "100%",
                    }}
                    InputProps={{
                      sx: { borderRadius: 2 },
                    }}
                    fullWidth
                    onChange={({ target }) =>
                      setInfo((adata) => ({
                        ...adata,
                        note: target.value,
                      }))
                    }
                    value={info && info?.note}
                  />
                </Stack>

                <Stack pb={2.4}>
                  <Button onClick={handleSubmitnote}>
                    {" "}
                    <SaveOutlinedIcon sx={{ marginInlineEnd: 1 }} />
                    Guardar
                  </Button>
                </Stack>
              </Stack>
            </Card>
          </Grid>
        </Grid>
       
      </Container>
      <ModalPatient
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        centers={centersfiltred}
        mainData={mainData}
        setMainData={setMainData}
        handleSubmitmodal={handleSubmitmodal}
        mainDataError={mainDataError}
        VisuallyHiddenInput={VisuallyHiddenInput}
        CloudUploadIcon={CloudUploadIcon}
        loadApi={loadApi}
        mainDataNewBill={mainDataNewBill}
        setMainDataNewBill={setMainDataNewBill}
        enableAdditionalFields={enableAdditionalFields}
        setEnableAdditionalFields={setEnableAdditionalFields}
        newBillError={newBillError}
      />
      <DeleteRegister
        openModaldelete={openModaldelete}
        handleCloseModaldelete={handleCloseModaldelete}
        deleteregister={deleteregister}
      />
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
      <DeleteRegisterFile
        openModaldeleteFile={openModaldeleteFile}
        setOpenModaldeleteFile={setOpenModaldeleteFile}
        deleteregisterFile={deleteregisterFile}
      />
    </>
  );
}

