import { TapsHeaders } from "../../components/tapsHeaders";
import GlobalHeader from "../../components/header";
import { useState, useEffect, useRef } from "react";
import useSnack from "../../hooks/useSnack";
import SnackAlert from "../../components/SnackAlert";
import useApi from "../../hooks/useApi";
import * as echarts from "echarts";
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  Divider,
  Stack,
  TextField,
  Autocomplete,
} from "@mui/material";
import { fetchInvoice_by_month } from "./fetch";
import { fetchCenter } from "../diary/fetch";
import { fetchClients } from "./fetch";
import { fetchCenterOn } from "./fetch";
import { fetchProfile } from "../ExternalChats.jsx/fetch";
import { fetchOccupation } from "../../utils/profesion";
import FormatearNumeroConSeparador from "../../utils/formatNumber";

export function Collections() {
  const [photo_profile, setPhoto_profile] = useState();
  const { loadApi, loadingApi } = useApi();
  const [openbackd, setOpenbackd] = useState(false);
  const { msgSB, openSB, showSnack, typeSB } = useSnack();
  const [invoice, setInvoice] = useState([]);
  const chartRef = useRef(null);
  const [centers, setCenters] = useState([]);
  const [occupation, setOccupation] = useState();
  const [clients, setClients] = useState([]);
  const [mappedOptions, setMappedOptions] = useState([]);
  const [fetch, setFetch] = useState(false);
  const [centersfiltred, setCentersfiltred] = useState([]);
  const [total, setTotal] = useState(0);
  const [mainDataFilters, setMainDataFilters] = useState({
    name_center: "",
    month: "",
    clientId: "",
    year: "",
  });

  useEffect(() => {
    fetchProfile(setPhoto_profile, showSnack, setOpenbackd, loadApi);
    fetchOccupation(loadApi, showSnack, setOccupation);
    fetchInvoice_by_month(setInvoice, loadApi, showSnack);
    fetchClients(loadApi, setClients, showSnack);
    fetchCenter(setCenters, showSnack, setOpenbackd, loadApi);
    fetchCenterOn(setCentersfiltred, showSnack, loadApi);
  }, []);

  const clearFilter = () => {
    setMainDataFilters({
      name_center: "",
      month: "",
      clientId: "",
      year: "",
    });
  };

  const menu = [
    { label: "Grafico ingresos", path: `/income` },
    { label: "Grafico cobros", path: `/collections` },
    { label: "Detalle", path: `/income/info` },
  ];

  function getUniqueYearsFromIncomes(incomes) {
    const yearsSet = new Set();
    incomes.forEach((income) => {
      const year = new Date(income?.payment_due_date).getFullYear();
      yearsSet.add(year);
    });
    return Array.from(yearsSet);
  }

  const uniqueYears = getUniqueYearsFromIncomes(invoice);

  const filteredData = invoice.filter((item) => {
    const formattedDate = new Date(item.payment_due_date);
    const itemMonth = formattedDate.getUTCMonth() + 1;
    const itemYear = formattedDate.getUTCFullYear();
    const selectedMonth = mainDataFilters.month
      ? Number(mainDataFilters.month)
      : "";
    const selectedYear = mainDataFilters.year
      ? Number(mainDataFilters.year)
      : "";

    return (
      (mainDataFilters.month === "" || itemMonth === selectedMonth) &&
      (mainDataFilters.year === "" || itemYear === selectedYear) &&
      (mainDataFilters.name_center === "" ||
        item.centerName === mainDataFilters.name_center) &&
      (mainDataFilters.clientId === "" ||
        item.client_id === mainDataFilters.clientId)
    );
  });

  // Agrupar y sumar ingresos por mes
  const monthlyTotals = filteredData.reduce((acc, item) => {
    const formattedDate = new Date(item.payment_due_date);
    const month = formattedDate.getUTCMonth(); // Mes en base 0
    // Convertir el monto a número, reemplazando ',' por '.'
    const amount = item?.amount;
    const numericAmount = parseFloat(amount); // Convertir a número
    if (!acc[month]) {
      acc[month] = 0;
    }
    acc[month] += numericAmount;
    return acc;
  }, {});

  useEffect(() => {
    const chart = echarts.init(chartRef.current);
    chart.clear(); // Limpiar el gráfico antes de actualizarlo

    const chartData = Array.from({ length: 12 }, (_, index) => ({
      month: index,
      total: monthlyTotals[index] || 0,
    }));

    const option = {
      title: {
        text: "Cobros",
        left: "center",
        textStyle: {
          color: "#333",
          fontSize: 18,
        },
      },
      tooltip: {
        trigger: "axis",
        formatter: (params) => {
          const month = params[0].name;
          const value = params[0].value;
          return `${month} ${FormatearNumeroConSeparador(value)}`;
        },
      },
      xAxis: {
        type: "category",
        data: [
          "ENE",
          "FEB",
          "MAR",
          "ABR",
          "MAY",
          "JUN",
          "JUL",
          "AGO",
          "SEP",
          "OCT",
          "NOV",
          "DIC",
        ],
      },
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: (value) => {
            return FormatearNumeroConSeparador(value);
          },
        },
      },
      series: [
        {
          name: "Monto",
          type: "bar",
          data: chartData.map((data) => data.total),
          itemStyle: {
            color: "#002B44",
          },
        },
      ],
    };

    chart.setOption(option);
  }, [filteredData]);

  useEffect(() => {
    const options = (clients || []).map((item) => ({
      label: `${item.name} ${item.lastname}`,
      value: item.id,
    }));
    options.push({ label: `sin ${occupation}`, value: null }); // Para usuarios sin cliente
    options.unshift({ label: `Todos los ${occupation}`, value: "" }); // Para todos los clientes
    setMappedOptions(options);
  }, [clients, occupation]);

  return (
    <>
      <GlobalHeader LogoProfile={photo_profile} />
      <Container
        maxWidth="lg"
        sx={{
          alignItems: "center",
          background: "#FFFFFF",
          p: 2,
        }}
      >
        <TapsHeaders namecomplete="Cobros Generales" menu={menu} />

        <Grid container direction="row" spacing={1}>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Stack direction="row" justifyContent="space-between">
              <Typography sx={{ m: 1 }}>Centro:</Typography>
              <FormControl
                sx={{ width: { xs: "70%", sm: "85%", md: "100%" } }}
                required
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  sx={{ borderRadius: 2 }}
                  value={mainDataFilters.name_center}
                  onChange={({ target }) =>
                    setMainDataFilters((adata) => ({
                      ...adata,
                      name_center: target.value,
                    }))
                  }
                  required
                  size="small"
                  displayEmpty
                >
                  <MenuItem value="">Todos los centros...</MenuItem>
                  {centers.map((row, index) => (
                    <MenuItem key={index} value={row.name}>
                      {row.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Stack direction="row" justifyContent="space-between">
              <Typography sx={{ m: 1, minWidth: { xs: 55, md: 40 } }}>
                Mes:
              </Typography>
              <FormControl
                sx={{ width: { xs: "70%", sm: "85%", md: "100%" } }}
                required
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  sx={{ borderRadius: 2 }}
                  value={mainDataFilters.month}
                  onChange={({ target }) =>
                    setMainDataFilters((adata) => ({
                      ...adata,
                      month: target.value,
                    }))
                  }
                  required
                  size="small"
                  displayEmpty
                >
                  <MenuItem value="">Todos los meses</MenuItem>
                  <MenuItem value="01">Enero</MenuItem>
                  <MenuItem value="02">Febrero</MenuItem>
                  <MenuItem value="03">Marzo</MenuItem>
                  <MenuItem value="04">Abril</MenuItem>
                  <MenuItem value="05">Mayo</MenuItem>
                  <MenuItem value="06">Junio</MenuItem>
                  <MenuItem value="07">Julio</MenuItem>
                  <MenuItem value="08">Agosto</MenuItem>
                  <MenuItem value="09">Septiembre</MenuItem>
                  <MenuItem value="10">Octubre</MenuItem>
                  <MenuItem value="11">Noviembre</MenuItem>
                  <MenuItem value="12">Diciembre</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Stack direction="row" justifyContent="space-between">
              <Typography sx={{ m: 1, minWidth: { xs: 55, md: 40 } }}>
                Año:
              </Typography>
              <FormControl
                sx={{ width: { xs: "70%", sm: "85%", md: "100%" } }}
                required
              >
                <Autocomplete
                  size="small"
                  value={mainDataFilters.year}
                  onChange={(event, newValue) =>
                    setMainDataFilters({ ...mainDataFilters, year: newValue })
                  }
                  options={["", ...uniqueYears]} // Incluye opción para "Todos los años"
                  getOptionLabel={(option) =>
                    option === "" ? "Todos los años" : option.toString()
                  }
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                />
              </FormControl>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Stack direction="row" justifyContent="space-between">
              <Typography sx={{ m: 1 }}>Paciente:</Typography>

              <Autocomplete
                size="small"
                sx={{ width: { xs: "70%", sm: "85%", lg: "100%" } }}
                options={mappedOptions}
                getOptionLabel={(option) => option.label}
                value={
                  mappedOptions.find(
                    (option) => option.value === mainDataFilters.clientId
                  ) || null
                }
                onChange={(event, newValue) => {
                  setMainDataFilters((prevFilters) => ({
                    ...prevFilters,
                    clientId: newValue ? newValue.value : "", // Maneja el valor nulo correctamente
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{ borderRadius: 100 }}
                    {...params}
                    variant="outlined"
                  />
                )}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Button
                  fullWidth
                  sx={{
                    bgcolor: "secondary.main",
                    color: "primary.contrastText",
                    boxShadow:
                      "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                    ":hover": {
                      bgcolor: "#ebebeb",
                      boxShadow:
                        "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                    },
                  }}
                  onClick={clearFilter}
                >
                  Limpiar Filtros
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Divider sx={{ mb: 2, mx: 2, mt: 1 }} />
        <Box sx={{ mt: 3 }}>
          <Box
            ref={chartRef}
            sx={{
              height: "400px",
              width: "100%",
              background: "#FFFFFF",
              borderRadius: 1,
              boxShadow: 1,
            }}
          />
        </Box>
      </Container>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
    </>
  );
}
