import {
  Button,
  Dialog,
  DialogContent,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Grid,
  Typography,
  Box,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Checkbox,
} from "@mui/material";
import FormatearNumeroConSeparador from "../../utils/formatNumber";
import { useEffect, useState } from "react";
import dayjs from "dayjs";

// Mapeo de días de la semana en inglés a español
const daysOfWeekMap = {
  monday: "Lunes",
  tuesday: "Martes",
  wednesday: "Miércoles",
  thursday: "Jueves",
  friday: "Viernes",
  saturday: "Sábado",
  sunday: "Domingo",
};

export default function ModalRateUpdate({
  openModalUpdate,
  setOpenModalUpdate,
  setMainDataUpdate,
  mainDataUpdate,
  SubmitmodalUpdate,
  mainDataUpdateError,
  setMainDataUpdateError,
}) {
  const [frequency, setFrequency] = useState(0);

  const [daysOfWeek, setDaysOfWeek] = useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  });
  const [dayOfMonth, setDayOfMonth] = useState("");
  const [endDate, setEndDate] = useState("");
  const [hasEndDate, setHasEndDate] = useState(false);

  useEffect(() => {
    if (mainDataUpdate) {
      setFrequency(mainDataUpdate.frequency);

      setDaysOfWeek(
        mainDataUpdate.daysOfWeek || {
          monday: false,
          tuesday: false,
          wednesday: false,
          thursday: false,
          friday: false,
          saturday: false,
          sunday: false,
        }
      );
      setDayOfMonth(mainDataUpdate.dayOfMonth || "");
      setEndDate(
        mainDataUpdate.endDate
          ? dayjs(mainDataUpdate.endDate).format("YYYY-MM-DD")
          : ""
      );
      setHasEndDate(!!mainDataUpdate.endDate);
    }
  }, [mainDataUpdate]);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    const updatedDaysOfWeek = { ...daysOfWeek, [name]: checked };
    setDaysOfWeek(updatedDaysOfWeek);
    setMainDataUpdate((prev) => ({
      ...prev,
      daysOfWeek: updatedDaysOfWeek,
    }));
  };

  return (
    <Dialog
      open={!!openModalUpdate}
      onClose={() => setOpenModalUpdate(false)}
      PaperProps={{
        sx: {
          width: { xs: "90%", sm: "80%", md: "70%" },
          height: { xs: "auto", sm: "calc(100% - 64px)" },
          maxHeight: { xs: "90vh", sm: "calc(100% - 64px)" },
          maxWidth: "none",
          display: "flex",
          margin: 0,
          borderRadius: 2,
          overflowY: "auto",
        },
      }}
      fullWidth
      maxWidth="xl"
    >
      <DialogContent
        sx={{
          height: { xs: "auto", sm: "calc(100% - 64px)" },
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: 3,
          p: 2,
        }}
      >
        <Grid container spacing={3} item xs={12} sm={6}>
          <Grid item xs={12}>
            <Typography variant="h6">Actualizar Tarifa</Typography>
            <Box sx={{ p: 1 }}>
              <TextField
                label="Nombre Tarifa"
                InputProps={{
                  sx: { borderRadius: 2, mb: 1 },
                }}
                id="name"
                onChange={({ target }) =>
                  setMainDataUpdate((adata) => ({
                    ...adata,
                    name: target.value,
                  }))
                }
                value={mainDataUpdate?.name || ""}
                error={mainDataUpdateError?.name}
                required
                type="text"
                variant="outlined"
                fullWidth
              />

              <FormControl fullWidth required>
                <InputLabel id="cadence-label">Cadencia</InputLabel>
                <Select
                  labelId="cadence-label"
                  sx={{ borderRadius: 2, mb: 1 }}
                  label="Cadencia"
                  value={mainDataUpdate?.concurrence || ""}
                  error={mainDataUpdateError?.concurrence}
                  onChange={({ target }) =>
                    setMainDataUpdate((adata) => ({
                      ...adata,
                      concurrence: target.value,
                    }))
                  }
                >
                  <MenuItem value={"Mensual"}>Mensual</MenuItem>
                  <MenuItem value={"Quincenal"}>Quincenal</MenuItem>
                  <MenuItem value={"Por dia"}>Por dia</MenuItem>
                  <MenuItem value={"Por hora"}>Por hora</MenuItem>
                  <MenuItem value={"Por media hora"}>Por media hora</MenuItem>
                  <MenuItem value={"Por paciente"}>Por paciente</MenuItem>
                </Select>
              </FormControl>

              <TextField
                label="Plazo de cobro en días"
                InputProps={{
                  sx: { borderRadius: 2, mb: 1 },
                }}
                id="term"
                onChange={({ target }) =>
                  setMainDataUpdate((adata) => ({
                    ...adata,
                    term: target.value,
                  }))
                }
                error={mainDataUpdateError?.term}
                required
                value={mainDataUpdate?.term}
                type="number"
                variant="outlined"
                fullWidth
              />

              <TextField
                label="Valor"
                InputProps={{
                  sx: { borderRadius: 2 },
                }}
                id="price"
                onChange={(event) => {
                  let val = FormatearNumeroConSeparador(event.target.value);
                  setMainDataUpdate((adata) => ({
                    ...adata,
                    price: val,
                  }));
                }}
                value={FormatearNumeroConSeparador(mainDataUpdate?.price) || ""}
                error={mainDataUpdateError?.price}
                type="text"
                variant="outlined"
                fullWidth
                required
                onKeyDown={(event) => {
                  if (
                    !/[0-9]/.test(event.key) &&
                    event.key !== "Backspace" &&
                    event.key !== "Delete" &&
                    event.key !== "ArrowLeft" &&
                    event.key !== "ArrowRight"
                  ) {
                    event.preventDefault();
                  }
                }}
              />
            </Box>

            <Box
              mt={2}
              sx={{
                display: { md: "flex", sm: "flex", lg: "flex", xs: "none" },
                justifyContent: "center",
                gap: 2,
              }}
            >
              <Button
                onClick={() => {
                  setOpenModalUpdate(false);
                }}
                variant="contained"
                sx={{
                  px: 3,
                  bgcolor: "#fff",
                  color: "primary.contrastText",
                  boxShadow:
                    "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                  ":hover": {
                    bgcolor: "#ebebeb",
                    boxShadow:
                      "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                  },
                  textTransform: "none",
                  width: "100%",
                }}
              >
                Cancelar
              </Button>

              <Button
                onClick={() => {
                  SubmitmodalUpdate(
                    mainDataUpdate.id, // Assuming you have an ID field in mainDataUpdate
                    mainDataUpdate.index // Assuming you have an index field
                  );
                }}
                variant="contained"
                sx={{
                  px: 3,
                  textTransform: "none",
                  width: "100%",
                }}
              >
                Guardar
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Box
          sx={{
            display: { md: "block", xs: "none", sm: "block" },
            width: "1px",
            height: "100%",
            backgroundColor: "gray", // Color de la línea
            mx: 1, // Margen horizontal (espacio alrededor de la línea)
          }}
        />

        <Grid container spacing={3} item xs={12} sm={6}>
          <Grid item xs={12}>
            <Typography variant="h6">Configuración de Repetición</Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <FormControl component="fieldset">
                <Typography variant="h6" gutterBottom>
                  Frecuencia
                </Typography>
                <RadioGroup
                  value={frequency}
                  onChange={(event) => {
                    setFrequency(event.target.value);
                    setMainDataUpdate((prev) => ({
                      ...prev,
                      frequency: event.target.value,
                    }));
                  }}
                  row
                >
                  <FormControlLabel
                    value="daily"
                    control={<Radio />}
                    label="Diaria"
                  />
                  <FormControlLabel
                    value="weekly"
                    control={<Radio />}
                    label="Semanal"
                  />
                  <FormControlLabel
                    value="monthly"
                    control={<Radio />}
                    label="Mensual"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="Sin repetición"
                  />
                </RadioGroup>
              </FormControl>

              {frequency === "daily" && (
                <FormControl component="fieldset">
                  <Typography variant="subtitle1" gutterBottom>
                    Días de la semana
                  </Typography>
                  <FormGroup row>
                    {Object.keys(daysOfWeekMap).map((day) => (
                      <FormControlLabel
                        key={day}
                        control={
                          <Checkbox
                            name={day}
                            checked={daysOfWeek[day]}
                            onChange={handleCheckboxChange}
                          />
                        }
                        label={daysOfWeekMap[day]}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              )}

              {frequency === "monthly" && (
                <FormControl fullWidth>
                  <TextField
                    label="Día del mes"
                    type="number"
                    size="small"
                    fullWidth
                    placeholder="Día del mes"
                    value={dayOfMonth || ""}
                    onChange={(event) => {
                      setDayOfMonth(event.target.value);
                      setMainDataUpdate((prev) => ({
                        ...prev,
                        dayOfMonth: event.target.value,
                      }));
                    }}
                    inputProps={{ min: 1, max: 31 }}
                  />
                </FormControl>
              )}

              <FormControl component="fieldset">
                <Typography variant="h6" gutterBottom>
                  Finalización
                </Typography>
                <RadioGroup
                  value={hasEndDate ? "end-date" : "no-end-date"}
                  onChange={(event) => {
                    const value = event.target.value;
                    setHasEndDate(value === "end-date");
                    setMainDataUpdate((prev) => ({
                      ...prev,
                      endDate:
                        value === "end-date" ? dayjs(endDate).toDate() : null,
                    }));
                  }}
                  row
                >
                  <FormControlLabel
                    value="no-end-date"
                    control={<Radio />}
                    label="Sin fecha de finalización"
                  />
                  <FormControlLabel
                    value="end-date"
                    control={<Radio />}
                    label="Fecha de finalización"
                  />
                </RadioGroup>
                {hasEndDate && (
                  <FormControl fullWidth>
                    <TextField
                      label="Fecha de finalización"
                      type="date"
                      size="small"
                      variant="outlined"
                      fullWidth
                      value={endDate || ""}
                      onChange={(event) => {
                        setEndDate(event.target.value);
                        setMainDataUpdate((prev) => ({
                          ...prev,
                          endDate: dayjs(event.target.value).toDate(),
                        }));
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormControl>
                )}
              </FormControl>
            </Box>

            <Box
              mt={2}
              sx={{
                display: { md: "none", lg: "none", xs: "flex" },
                justifyContent: "center",
                gap: 2,
              }}
            >
              <Button
                onClick={() => {
                  setOpenModalUpdate(false);
                }}
                variant="contained"
                sx={{
                  px: 3,
                  bgcolor: "#fff",
                  color: "primary.contrastText",
                  boxShadow:
                    "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                  ":hover": {
                    bgcolor: "#ebebeb",
                    boxShadow:
                      "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                  },
                  textTransform: "none",
                  width: "100%",
                }}
              >
                Cancelar
              </Button>

              <Button
                onClick={() => {
                  SubmitmodalUpdate(
                    mainDataUpdate.id, // Assuming you have an ID field in mainDataUpdate
                    mainDataUpdate.index // Assuming you have an index field
                  );
                }}
                variant="contained"
                sx={{
                  px: 3,
                  textTransform: "none",
                  width: "100%",
                }}
              >
                Guardar
              </Button>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
