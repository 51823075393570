import DateCusmton from "../../utils/DateCusmton";
import { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close"; // Ícono de cerrar
import FormatearNumeroConSeparador from "../../utils/formatNumber";
export const getStatusStyles = (status) => {
  switch (status) {
    case "Pendiente":
      return {
        backgroundColor: "#ffe0b2",
        width: { xs: "100%", md: "80%" },
        ":hover": {
          backgroundColor: "#ffe0b2 !important",
          borderColor: "inherit",
        },
      }; // Amarillo de fondo
    case "Cobrado":
      return {
        backgroundColor: "#c8e6c9",
        width: { xs: "100%", md: "80%" },
        ":hover": {
          backgroundColor: "#c8e6c9 !important",
          borderColor: "inherit",
        },
      }; // Verde de fondo
    case "Atrasado":
      return {
        backgroundColor: "#ffcdd2",
        width: { xs: "100%", md: "80%" },
        ":hover": {
          backgroundColor: "#ffcdd2 !important",
          borderColor: "inherit",
        },
      }; // Rojo claro de fondo
    default:
      return {
        backgroundColor: "#e0e0e0",
        width: { xs: "100%", md: "80%" },
        ":hover": {
          backgroundColor: "#e0e0e0 !important",
          borderColor: "inherit",
        },
      };
  }
};

const buttonStyles = {
  cancel: {
    px: 5,
    bgcolor: "#fff",
    color: "primary.contrastText",
    boxShadow:
      "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
    ":hover": {
      bgcolor: "#ebebeb",
      boxShadow:
        "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
    },
    textTransform: "none",
  },
  save: {
    px: 5,
    textTransform: "none",
  },
  closeButton: {
    position: "absolute",
    right: 8,
    top: 8,
    color: "grey.500",
  },
};

export default function ModalBillsAsociate({
  openModalNoAsociate,
  setOpenModalNoAsociate,
  loadApi,
  showSnack,
  setOpenbackd,
  setFetch,
  selectedRows,
  setSelectedRows,
  setFilteredData2,
}) {
  const [selectedIds, setSelectedIds] = useState([]);

  useEffect(() => {
    // Verificar si selectedRows tiene elementos
    if (selectedRows && selectedRows.length > 0) {
      // Extraer los ids de cada elemento
      const ids = selectedRows.map((row) => row.id);
      // Actualizar el estado con los ids
      setSelectedIds(ids);
    } else {
      // Si no hay elementos, limpiar el array de IDs
      setSelectedIds([]);
    }
  }, [selectedRows]);

  const handleClose = () => {
    setOpenModalNoAsociate(false);
  };
  useEffect(() => {}, [selectedRows]);

  const handleUpdate = (status) => {
    const mainData = {
      ids: selectedIds,
      status: status,
    };

    setOpenbackd(true);
    loadApi(`invoice/update_without_clients/`, true, "put", mainData)
      .then((response) => {
        showSnack(response.data.message, "success", 2000);
        // Actualiza la lista de facturas para que muestre los cambios solo se esta cambiando los estados de todos los elemntos segun el aray de ids usando setFilteredData2 dejar los datos que tiene y en status cambiar por mainData.status
        // Actualiza el estado de `filteredData2` cambiando el `status` de las facturas cuyos IDs están en `selectedIds`
        setFilteredData2((prevData) =>
          prevData.map((item) =>
            selectedIds.includes(item.id)
              ? { ...item, status: mainData.status }
              : item
          )
        );
        setSelectedRows([]);
      })
      .catch((error) => {
        showSnack(error.message, "error");
      })
      .finally(() => {
        setOpenbackd(false);
        handleClose();
      });
  };
  return (
    <Dialog open={openModalNoAsociate} onClose={handleClose} fullWidth>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        Actualización múltiple
        {/* Botón para cerrar el modal */}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={buttonStyles.closeButton}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers sx={{ overflowX: "hidden", pt: 2, width: "90%" }}>
        {selectedRows && selectedRows.length > 0 ? (
          <Stack
            spacing={2}
            sx={{ justifyContent: "center", alignItems: "center" }}
          >
            <Button
              onClick={() => handleUpdate("Pendiente")}
              variant="outlined"
              sx={getStatusStyles("Pendiente")}
            >
              Pendiente
            </Button>

            <Button
              onClick={() => handleUpdate("Cobrado")}
              variant="outlined"
              sx={getStatusStyles("Cobrado")}
            >
              Cobrado
            </Button>

            <Button
              onClick={() => handleUpdate("Atrasado")}
              variant="outlined"
              sx={getStatusStyles("Atrasado")}
            >
              Atrasado
            </Button>
          </Stack>
        ) : (
          // Mensaje opcional si no hay selectedRows
          <Typography> No hay facturas seleccionadas</Typography>
        )}
      </DialogContent>
    </Dialog>
  );
}
