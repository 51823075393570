export async function fetchUpdateStatus(
  loadApi,
  onClose,
  setFilteredData2,
  showSnack,
  filteredData2,
  status,
  row
) {
  try {
    const response = await loadApi(
      `invoice/update_status/${row?.id}`,
      true,
      "put",
      { status: status }
    );
    showSnack(response.data.message, "success", 2000);
    const updatedData = filteredData2.map((item) =>
      item.id === row?.id ? { ...item, status } : item
    );
    setFilteredData2(updatedData);
    onClose();
  } catch (e) {
    showSnack(e.message);
  }
}
