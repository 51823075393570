import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Checkbox,
} from "@mui/material";
import dayjs from "dayjs"; // Para el manejo de fechas (opcional)

// Mapeo de días de la semana en inglés a español
const daysOfWeekMap = {
  monday: "Lunes",
  tuesday: "Martes",
  wednesday: "Miércoles",
  thursday: "Jueves",
  friday: "Viernes",
  saturday: "Sábado",
  sunday: "Domingo",
};

export function ComponentRepeticion({ setMainData2, mainData2 }) {
  const [frequency, setFrequency] = useState(mainData2?.frequency || "false");
  const [daysOfWeek, setDaysOfWeek] = useState(mainData2?.daysOfWeek || {});
  const [dayOfMonth, setDayOfMonth] = useState(mainData2?.dayOfMonth || "");
  const [endDate, setEndDate] = useState(mainData2?.endDate || "");
  const [hasEndDate, setHasEndDate] = useState(!!mainData2?.endDate);

  useEffect(() => {
    if (frequency === "weekly") {
      // Selecciona todos los días si la frecuencia es semanal
      const allDays = Object.keys(daysOfWeekMap).reduce((acc, day) => {
        acc[day] = true;
        return acc;
      }, {});
      setDaysOfWeek(allDays);
    } else {
      // Limpia los días si la frecuencia no es semanal
      const updatedDaysOfWeek = Object.keys(daysOfWeekMap).reduce(
        (acc, day) => {
          acc[day] = false;
          return acc;
        },
        {}
      );
      setDaysOfWeek(updatedDaysOfWeek);
    }
    setMainData2((prev) => ({
      ...prev,
      frequency,
      daysOfWeek:
        frequency === "weekly"
          ? Object.fromEntries(
              Object.keys(daysOfWeekMap).map((day) => [day, true])
            )
          : daysOfWeek,
    }));
  }, [frequency]);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    const updatedDaysOfWeek = { ...daysOfWeek, [name]: checked };
    setDaysOfWeek(updatedDaysOfWeek);
    setMainData2((prev) => ({
      ...prev,
      daysOfWeek: updatedDaysOfWeek,
    }));
  };

  const handleSubmit = () => {
    // Validación simple
    if (frequency === "daily" && !Object.values(daysOfWeek).includes(true)) {
      alert("Por favor, seleccione al menos un día de la semana.");
      return;
    }

    if (frequency === "monthly" && !dayOfMonth) {
      alert("Por favor, ingrese el día del mes.");
      return;
    }

    setMainData2((prev) => ({
      ...prev,
      frequency,
      dayOfMonth,
      endDate: hasEndDate ? dayjs(endDate).toDate() : null,
    }));
  };

  return (
    <Box sx={{ width: "100%" }}>
      {/* Frecuencia */}
      <FormControl component="fieldset" sx={{ mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Frecuencia
        </Typography>
        <RadioGroup
          value={frequency}
          onChange={(event) => {
            setFrequency(event.target.value);
          }}
          row
        >
          <FormControlLabel value="daily" control={<Radio />} label="Diaria" />
          <FormControlLabel
            value="weekly"
            control={<Radio />}
            label="Semanal"
          />
          <FormControlLabel
            value="monthly"
            control={<Radio />}
            label="Mensual"
          />
          <FormControlLabel
            value="false"
            control={<Radio />}
            label="Sin repetición"
          />
        </RadioGroup>
      </FormControl>

      {/* Configuración específica según la frecuencia */}
      {(frequency === "daily" || frequency === "weekly") && (
        <FormControl component="fieldset" sx={{ mb: 3 }}>
          <Typography variant="subtitle1" gutterBottom>
            Días de la semana
          </Typography>
          <FormGroup row>
            {Object.keys(daysOfWeekMap).map((day) => (
              <FormControlLabel
                key={day}
                control={
                  <Checkbox
                    name={day}
                    checked={daysOfWeek[day] || false}
                    onChange={handleCheckboxChange}
                    disabled={frequency === "weekly"} // Deshabilitado si la frecuencia es semanal
                  />
                }
                label={daysOfWeekMap[day]}
              />
            ))}
          </FormGroup>
        </FormControl>
      )}

      {frequency === "monthly" && (
        <FormControl fullWidth sx={{ mb: 3 }}>
          <TextField
            label="Día del mes"
            type="number"
            size="small"
            fullWidth
            placeholder="Día del mes"
            value={dayOfMonth}
            onChange={(event) => {
              setDayOfMonth(event.target.value);
              setMainData2((prev) => ({
                ...prev,
                dayOfMonth: event.target.value,
              }));
            }}
            inputProps={{ min: 1, max: 31 }} // Asegúrate de que el valor sea un día válido
          />
        </FormControl>
      )}

      {/* Finalización */}
      <FormControl component="fieldset" sx={{ mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Finalización
        </Typography>
        <RadioGroup
          value={hasEndDate ? "end-date" : "no-end-date"}
          onChange={(event) => {
            const value = event.target.value;
            setHasEndDate(value === "end-date");
            if (value === "no-end-date") {
              setEndDate("");
              setMainData2((prev) => ({
                ...prev,
                endDate: null,
              }));
            } else {
              setMainData2((prev) => ({
                ...prev,
                endDate: dayjs(endDate).toDate(),
              }));
            }
          }}
          row
        >
          <FormControlLabel
            value="no-end-date"
            control={<Radio />}
            label="Sin fecha de finalización"
          />
          <FormControlLabel
            value="end-date"
            control={<Radio />}
            label="Fecha de finalización"
          />
        </RadioGroup>
        {hasEndDate && (
          <FormControl fullWidth>
            <TextField
              label="Fecha de finalización"
              type="date"
              size="small"
              variant="outlined"
              fullWidth
              value={endDate}
              onChange={(event) => {
                setEndDate(event.target.value);
                setMainData2((prev) => ({
                  ...prev,
                  endDate: dayjs(event.target.value).toDate(),
                }));
              }}
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
        )}
      </FormControl>
    </Box>
  );
}
