import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
  Divider,
  Stack,
  MenuItem,
  FormControl,
  Select,
  TextField,
  Autocomplete,
} from "@mui/material";

import GlobalHeader from "../../components/header";
import { useEffect, useState } from "react";
import useSnack from "../../hooks/useSnack";
import SnackAlert from "../../components/SnackAlert";
import useApi from "../../hooks/useApi";
import { write, utils } from "xlsx";
import { Link, useNavigate } from "react-router-dom";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import capitalizarCadena from "../../utils/Cadena";
import dateToInput from "../../utils/DateToInput";
import DateCusmton from "../../utils/DateCusmton";
import { fetchOccupation } from "../../utils/profesion";

export default function Reports() {
  const [photo_profile, setPhoto_profile] = useState();
  const navigate = useNavigate();
  const { loadApi, loadingApi } = useApi();
  const { msgSB, openSB, showSnack, typeSB } = useSnack();
  const [openbackd, setOpenbackd] = useState(false);
  const [clients, setClients] = useState([]);
  const [occupation, setOccupation] = useState("");
  const [getclients, setGetclients] = useState([]);
  const [getinvoices, setGetinvoices] = useState([]);
  const [getinvoicesPending, setGetinvoicesPending] = useState([]);
  const [clientsfiltered, setClientsfiltered] = useState([]);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [filteredInvoicesPending, setFilteredInvoicesPending] = useState([]);
  const [mappedOptions, setMappedOptions] = useState([]);
  const [filters, setFilters] = useState({
    center: "",
    client: "",
    month: "",
    age: "",
  });

  // get centros
  const [centers, setCenters] = useState();
  useEffect(() => {
    loadApi("center/centers", true, "get")
      .then((response) => {
        setCenters(response.data);
      })
      .catch((e) => {
        showSnack(e.message);
      });
      fetchOccupation(loadApi,showSnack,setOccupation); //
  }, []);

  // get de foto del profesional
  useEffect(() => {
    setOpenbackd(true);
    loadApi("profile", true, "get")
      .then((response) => {
        setPhoto_profile(response.data.photo_profile.url);
      })
      .catch((e) => {
        showSnack(e.message);
      })
      .finally((e) => {
        setOpenbackd(false);
      });
  }, []);
  //get clients
  useEffect(() => {
    setOpenbackd(true);
    loadApi("client/clients", true, "get")
      .then((response) => {
        setClients(response.data);
      })
      .catch((e) => {
        showSnack(e.message);
      })
      .finally((e) => {
        setOpenbackd(false);
      });
  }, []);
  useEffect(() => {
    const options = (clients || []).map((item) => ({
      label: `${item.name} ${item.lastname}`,
      value: `${item.name} ${item.lastname}`,
    }));
    options.push({ label: `sin ${occupation}`, value: null });
    options.unshift({ label: `Todos los ${occupation}`, value: "" });
    setMappedOptions(options);
  }, [clients]);

  useEffect(() => {
    setOpenbackd(true);
    loadApi("reports/reports", true, "get")
      .then((response) => {
        setGetclients(response.data.clients);
        setGetinvoices(response.data.invoices);
        setOpenbackd(false);
      })
      .catch((e) => {
        showSnack(e.message);
        setOpenbackd(true);
      })
      .finally((e) => {});
  }, []);
  // inicalizando invoices pedientes y atrasados
  useEffect(() => {
    setGetinvoicesPending(
      getinvoices.filter(
        (item) => item.status === "Pendiente" || item.status === "Atrasado"
      )
    );
  }, [getinvoices]);
  //--------------filtros---------------
  useEffect(() => {
    const filteredClients = getclients?.filter((client) => {
      return (
        (filters?.center === "" ||
          client?.center
            ?.toLowerCase()
            .includes(filters?.center.toLowerCase())) &&
        (filters.client === "" || client?.name.includes(filters?.client))
      );
    });
    setClientsfiltered(filteredClients);
  }, [filters, getclients]);

  useEffect(() => {
    const invfiltered = getinvoices?.filter((invoice) => {
      const formattedDate = dateToInput(invoice.date);
      const invoiceDate = new Date(formattedDate);
      const invoiceMonth = invoiceDate.getUTCMonth() + 1; // getMonth() returns month from 0-11
      const invoiceYear = invoiceDate.getFullYear();

      return (
        (filters.center === "" ||
          invoice?.center
            .toLowerCase()
            .includes(filters?.center.toLowerCase())) &&
        (filters?.client === "" ||
          (filters?.client === null && invoice?.client === null) ||
          invoice?.client?.includes(filters?.client)) &&
        (filters?.month === "" || parseInt(filters?.month) === invoiceMonth) &&
        (filters?.age === "" || parseInt(filters?.age) === invoiceYear)
      );
    });
    setFilteredInvoices(invfiltered);
  }, [filters, getinvoices]);

  useEffect(() => {
    const invfiltered2 = getinvoicesPending?.filter((invoice) => {
      const invoiceDate = new Date(invoice.date);
      const invoiceMonth = invoiceDate.getMonth() + 1; // getMonth() returns month from 0-11
      const invoiceYear = invoiceDate.getFullYear();

      return (
        (filters?.center === "" ||
          invoice?.center
            .toLowerCase()
            .includes(filters?.center?.toLowerCase())) &&
        (filters?.client === "" ||
          (filters?.client === null && invoice?.client === null) ||
          invoice?.client
            ?.toLowerCase()
            .includes(filters?.client?.toLowerCase())) &&
        (filters?.month === "" || parseInt(filters?.month) === invoiceMonth) &&
        (filters?.age === "" || parseInt(filters?.age) === invoiceYear)
      );
    });
    setFilteredInvoicesPending(invfiltered2);
  }, [filters, getinvoicesPending]);

  const downloadExcelClients = () => {
    // Convertir datos a un formato adecuado para xlsx
    const dataForExcel = (clientsfiltered || [])?.map((item) => ({
      Centro: item?.center !== null ? item?.center : "Centro Eliminado",
      Nombre: item?.name,
      Documento: item?.document,
      Email: item?.email !== null ? item?.email : "Sin Email",
      Celular: item?.phone !== null ? item?.phone : "Sin Celular",
      Dirección: item?.address !== null ? item?.address : "Sin Dirección",
      Ultima_consulta:
        item.lastConsultationDate !== null
          ? DateCusmton(item.lastConsultationDate)
          : "Sin consultas anteriores",
      Facturas_pendientes: item.pendingInvoicesCount,
      Total_consultas: item.totalConsultations,
      Consultas_agendadas: item.upcomingConsultations,
      Nota: item?.note !== null ? item?.note : "Sin Nota",
    }));

    // Crear una nueva hoja de trabajo
    const worksheet = utils.json_to_sheet(dataForExcel);

    // Crear un nuevo libro de trabajo
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "clients Data");

    // Generar archivo Excel
    const excelBuffer = write(workbook, { bookType: "xlsx", type: "array" });

    // Crear un Blob a partir del buffer
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });

    // Crear enlace para descargar el archivo
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `Data_Pacientes.xlsx`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadExcelIncomesGeneral = () => {
    // Convertir datos a un formato adecuado para xlsx
    const dataForExcel = (filteredInvoices || [])?.map((item) => ({
      Centro: item?.center !== null ? item?.center : "Sin centro",
      Nombre: item?.client !== null ? item?.client : `Sin ${occupation}`,
      Documento:
        item?.client_document !== null
          ? item?.client_document
          : "Sin Documento",
      Celular: item?.client_phone !== null ? item?.client_phone : "Sin Celular",
      Fecha: dateToInput(item?.date),
      Descripcion: item?.coment ? item?.coment : "Sin Descripción",
      Tarifa: item?.concept,
      valor: item?.amount,
      plazo: item?.term,
      Estado: item?.status,
    }));

    // Crear una nueva hoja de trabajo
    const worksheet = utils.json_to_sheet(dataForExcel);

    // Crear un nuevo libro de trabajo
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "General invoices Data");

    // Generar archivo Excel
    const excelBuffer = write(workbook, { bookType: "xlsx", type: "array" });

    // Crear un Blob a partir del buffer
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });

    // Crear enlace para descargar el archivo
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `Data_Ingresos.xlsx`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const downloadExcelIncomesPending = () => {
    // Convertir datos a un formato adecuado para xlsx
    const dataForExcel = (filteredInvoicesPending || [])?.map((item) => ({
      Centro: item?.center !== null ? item?.center : "Sin centro",
      Nombre: item?.client !== null ? item?.client : `Sin ${occupation}`,
      Documento:
        item?.client_document !== null
          ? item?.client_document
          : "Sin Documento",
      Celular: item?.client_phone !== null ? item?.client_phone : "Sin Celular",
      Descripcion: item?.coment ? item?.coment : "Sin Descripción",
      Tarifa: item?.concept,      Fecha: dateToInput(item.date),
      valor: item?.amount,
      plazo: item?.term,
      Estado: item?.status,
    }));

    // Crear una nueva hoja de trabajo
    const worksheet = utils.json_to_sheet(dataForExcel);

    // Crear un nuevo libro de trabajo
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Pending invoices Data");

    // Generar archivo Excel
    const excelBuffer = write(workbook, { bookType: "xlsx", type: "array" });

    // Crear un Blob a partir del buffer
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });

    // Crear enlace para descargar el archivo
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `Data_Ingresos_pendientes.xlsx`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const getUniqueYearsFromInvoices = (getinvoices) => {
    const yearsSet = new Set();
    getinvoices.forEach((invoice) => {
      const year = new Date(invoice.date).getFullYear();
      yearsSet.add(year);
    });
    return Array.from(yearsSet);
  };

  const years = getUniqueYearsFromInvoices(getinvoices);

  return (
    <>
      <GlobalHeader LogoProfile={photo_profile} />
      <Container
        maxWidth="lg"
        sx={{
          alignItems: "center",
          background: "#FFFFFF",
          p: 2,
          minHeight: 600,
        }}
      >
        <Grid container direction="row">
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Typography variant="h5" marginTop={2}>
              Reportes Generales
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}></Grid>
        </Grid>
        <Grid container direction="row" marginTop={3} spacing={1}>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Stack direction="row" justifyContent="space-between">
              <Typography sx={{ m: 1 }}>Centro:</Typography>
              <FormControl
                sx={{ width: { xs: "70%", sm: "85%", md: "100%" } }}
                required
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  sx={{ borderRadius: 2 }}
                  value={filters.center}
                  onChange={({ target }) =>
                    setFilters((adata) => ({
                      ...adata,
                      center: target.value,
                    }))
                  }
                  required
                  size="small"
                  displayEmpty
                >
                  <MenuItem value="">Todos los centros...</MenuItem>
                  {centers &&
                    (centers || []).map((row, index) => (
                      <MenuItem value={row.name}>{row.name}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Stack direction="row" justifyContent="space-between">
              <Typography sx={{ m: 1 }}>{occupation}:</Typography>
              <Autocomplete
                size="small"
                sx={{ width: { xs: "70%", sm: "85%", md: "100%" } }}
                options={mappedOptions}
                getOptionLabel={(option) => option.label}
                value={
                  mappedOptions.find(
                    (option) => option.value === filters.client
                  ) || null
                }
                onChange={(event, newValue) => {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    client: newValue ? newValue.value : "",
                  }));
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Stack direction="row" justifyContent="space-between">
              <Typography sx={{ m: 1, minWidth: { sm: 55, lg: 40 } }}>
                Mes:
              </Typography>
              <FormControl
                sx={{ width: { xs: "70%", sm: "85%", md: "100%" } }}
                required
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  sx={{ borderRadius: 2 }}
                  value={filters.month}
                  onChange={({ target }) =>
                    setFilters((adata) => ({
                      ...adata,
                      month: target.value,
                    }))
                  }
                  required
                  size="small"
                  displayEmpty
                >
                  <MenuItem value="">Todos los meses</MenuItem>
                  <MenuItem value="01">Enero</MenuItem>
                  <MenuItem value="02">Febrero</MenuItem>
                  <MenuItem value="03">Marzo</MenuItem>
                  <MenuItem value="04">Abril</MenuItem>
                  <MenuItem value="05">Mayo</MenuItem>
                  <MenuItem value="06">Junio</MenuItem>
                  <MenuItem value="07">Julio</MenuItem>
                  <MenuItem value="08">Agosto</MenuItem>
                  <MenuItem value="09">Septiembre</MenuItem>
                  <MenuItem value="10">Octubre</MenuItem>
                  <MenuItem value="11">Noviembre</MenuItem>
                  <MenuItem value="12">Diciembre</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Stack direction="row" justifyContent="space-between">
              <Typography sx={{ m: 1, minWidth: { sm: 55, md: 35 } }}>
                Año:
              </Typography>
              <Autocomplete
                options={years}
                getOptionLabel={(option) => option.toString()}
                size="small"
                sx={{ width: { xs: "70%", sm: "85%", md: "100%" } }}
                value={filters.age}
                onChange={(event, newValue) => {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    age: newValue,
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    placeholder="Selecciona un año..."
                    {...params}
                    variant="outlined"
                  />
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Button
              fullWidth
              sx={{
                bgcolor: "secondary.main",
                color: "primary.contrastText",
                boxShadow:
                  "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                ":hover": {
                  bgcolor: "#ebebeb",
                  boxShadow:
                    "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                },
              }}
              onClick={() => {
                setFilters({ center: "", client: "", month: "", age: "" });
              }}
            >
              Limpiar Filtros
            </Button>
          </Grid>
        </Grid>
        <Divider sx={{ mt: 1, mb: 2 }} />
        {/*   ----inicio de box  */}
        <Box
          sx={{
            border: "1px solid #e0e0e0",
            padding: 2,
            borderRadius: 1,
            my: 1,
          }}
        >
          <Grid
            container
            row
            spacing={2}
            alignItems="center"
            sx={{ minHeight: 80 }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={3.9}
              lg={3.9}
              align="center"
              sx={{ px: "0!important" }}
            >
              <Typography variant="body1">{occupation ? occupation : ""} </Typography>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              align="center"
              sx={{ px: "0!important" }}
            >
              <Typography variant="body1">
                {" "}
                {filters.center === ""
                  ? "Todos los centros"
                  : capitalizarCadena(filters?.center)}
                {", "}
                {filters.client === ""
                  ? `todos los ${occupation}`
                  : capitalizarCadena(
                      filters?.client !== null
                        ? filters?.client
                        : `sin ${occupation}`
                    )}
              </Typography>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
            <Grid item xs={12} sm={12} md={4} lg={4} align="center">
              <Box>
                <Typography variant="body1">
                  <Button
                    fullWidth
                    sx={{
                      bgcolor: "secondary.main",
                      color: "primary.contrastText",
                      boxShadow:
                        "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                      ":hover": {
                        bgcolor: "#ebebeb",
                        boxShadow:
                          "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                      },
                    }}
                    onClick={downloadExcelClients}
                  >
                    <DownloadOutlinedIcon sx={{ marginInlineEnd: 1 }} />{" "}
                    Descargar
                  </Button>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            border: "1px solid #e0e0e0",
            padding: 2,
            borderRadius: 1,
            my: 1,
          }}
        >
          <Grid
            container
            spacing={2}
            alignItems="center"
            sx={{ minHeight: 80 }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={3.9}
              lg={3.9}
              align="center"
              sx={{ px: "0!important" }}
            >
              <Typography variant="body1">Cobros realizados</Typography>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              align="center"
              sx={{ px: "0!important" }}
            >
              <Typography variant="body1" marginX={2}>
                {filters.center === ""
                  ? "Todos los centros"
                  : capitalizarCadena(filters?.center)}
                {", "}
                {filters.client === ""
                  ? `todos los ${occupation}`
                  : capitalizarCadena(
                      filters?.client !== null
                        ? filters?.client
                        : `sin ${occupation}`
                    )}
                <br></br>
                {filters.month === ""
                  ? "todos los meses"
                  : getMonthName(filters?.month)}
                {", "}
                {filters.age === "" ? " todos los años" : filters?.age}
              </Typography>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
            <Grid item xs={12} sm={12} md={4} lg={4} align="center">
              <Box>
                <Typography variant="body1">
                  <Button
                    fullWidth
                    sx={{
                      bgcolor: "secondary.main",
                      color: "primary.contrastText",
                      boxShadow:
                        "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                      ":hover": {
                        bgcolor: "#ebebeb",
                        boxShadow:
                          "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                      },
                    }}
                    onClick={downloadExcelIncomesGeneral}
                  >
                    <DownloadOutlinedIcon sx={{ marginInlineEnd: 1 }} />{" "}
                    Descargar
                  </Button>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            border: "1px solid #e0e0e0",
            padding: 2,
            borderRadius: 1,
            my: 1,
          }}
        >
          <Grid
            container
            spacing={2}
            alignItems="center"
            sx={{ minHeight: 80 }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={3.9}
              lg={3.9}
              align="center"
              sx={{ px: "0!important" }}
            >
              <Typography variant="body1">Cobranza Pendiente </Typography>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              align="center"
              sx={{ px: "0!important" }}
            >
              <Typography variant="body1" marginX={2}>
                {filters?.center === ""
                  ? "Todos los centros"
                  : capitalizarCadena(filters?.center)}
                ,{" "}
                {filters?.client === ""
                  ? `todos los ${occupation}`
                  : capitalizarCadena(
                      filters?.client !== null
                        ? filters?.client
                        : `sin ${occupation}`
                    )}
                <br></br>
                {filters?.month === ""
                  ? "todos los meses"
                  : getMonthName(filters?.month)}
                {", "}
                {filters?.age === "" ? " todos los años" : filters?.age}
              </Typography>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
            <Grid item xs={12} sm={12} md={4} lg={4} align="center">
              <Box>
                <Typography variant="body1">
                  <Button
                    fullWidth
                    sx={{
                      bgcolor: "secondary.main",
                      color: "primary.contrastText",
                      boxShadow:
                        "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                      ":hover": {
                        bgcolor: "#ebebeb",
                        boxShadow:
                          "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                      },
                    }}
                    onClick={downloadExcelIncomesPending}
                  >
                    <DownloadOutlinedIcon sx={{ marginInlineEnd: 1 }} />{" "}
                    Descargar
                  </Button>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openbackd}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

function getMonthName(monthNumber) {
  const monthNames = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  // Convertir el string a número y restar 1 para obtener el índice correcto
  const index = parseInt(monthNumber, 10) - 1;

  // Validar que el número del mes sea válido
  if (index >= 0 && index < 12) {
    return monthNames[index];
  } else {
    return "Mes inválido";
  }
}
