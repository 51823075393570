import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  IconButton,
} from "@mui/material";
import { FileUpload } from "@mui/icons-material"; // Utiliza un icono para la carga de archivos
import { SiMicrosoftexcel } from "react-icons/si";
import { sendUploadUser } from "./fetch";

export function LoadUserModal({ occupation, loadApi, showSnack }) {
  const [openModal, setOpenModal] = useState(false);
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSave = () => {
    if (file) {
      const formData = { file: file };
      loadApi("upload/clients/", true, "post", formData, true)
        .then((response) => {
          showSnack(response.data.message, "success");
        })
        .catch((e) => {
          showSnack(e.message);
        })
        .finally(() => {
          setOpenModal(false);
          setFile(null);
        });
    } else {
      showSnack("Debe seleccionar un archivo CSV", "error");
    }
  };

  return (
    <>
      <Button
        variant="contained"
        disableElevation
        onClick={() => setOpenModal(true)}
        gap={1}
        sx={{
          bgcolor: "secondary.main",
          color: "primary.contrastText",
          boxShadow:
            "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
          ":hover": {
            bgcolor: "#ebebeb",
            boxShadow:
              "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
          },
        }}
      >
        <SiMicrosoftexcel color="green" size={20} />
        <Typography variant="p1" sx={{ fontWeight: 600, color: "black" }}>
          Cargar
        </Typography>
      </Button>

      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        PaperProps={{ component: "form" }}
        disableScrollLock
        sx={{ overflow: "hidden", zIndex: "500 !important" }}
      >
        <DialogTitle>
          Cargue de masivo de {occupation ? occupation : "pacientes"} por CSV
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            Seleccione un archivo CSV para cargar. Asegúrese de que el archivo
            tenga el formato correcto.
          </DialogContentText>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{ mt: 2, p: 2, border: "1px dashed grey", borderRadius: "4px" }}
          >
            <input
              type="file"
              accept=".xlsx"
              style={{ display: "none" }}
              id="upload-file"
              onChange={handleFileChange}
            />
            <label htmlFor="upload-file">
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <IconButton
                  component="span"
                  sx={{
                    bgcolor: "background.default",
                    borderRadius: "4px",
                    p: 1,
                    mb: 1, // Margen inferior para separar el icono del texto
                    "&:hover": {
                      bgcolor: "action.hover",
                    },
                  }}
                >
                  <FileUpload color="primary" />
                </IconButton>
                <Typography variant="body2">
                  {file ? file.name : "Seleccione un archivo CSV"}
                </Typography>
              </Box>
            </label>
          </Box>
        </DialogContent>

        <DialogActions sx={{ mb: 1 }}>
          <Button
            onClick={() => setOpenModal(false)}
            variant="contained"
            sx={{
              bgcolor: "secondary.main",
              color: "primary.contrastText",
              boxShadow:
                "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              ":hover": {
                bgcolor: "#ebebeb",
                boxShadow:
                  "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              },
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleSave}
            variant="contained"
            sx={{
              px: 5,
              textTransform: "none",
            }}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
