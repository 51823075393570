import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  TextField,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { ComponentRepeticion } from "./repeticion";
import FormatearNumeroConSeparador from "../../utils/formatNumber";

export default function ModalRate({
  openModal,
  handleCloseModal,
  setMainData2,
  mainData2,
  errorName,
  errorConcurrence,
  Submitmodal,
}) {
  return (
    <Dialog
      open={openModal}
      onClose={handleCloseModal}
      PaperProps={{
        sx: {
          width: { xs: "90%", sm: "80%", md: "70%" },
          height: { xs: "auto", sm: "calc(100% - 64px)" }, // Ajusta el alto para pantallas pequeñas y grandes
          maxHeight: { xs: "90vh", sm: "calc(100% - 64px)" }, // Limita la altura máxima
          maxWidth: "none",
          display: "flex",
          margin: 0,
          borderRadius: 2,
          overflowY: "auto", // Permite el desplazamiento vertical
        },
      }}
      fullWidth
    >
      <DialogContent
        sx={{
          height: { xs: "auto", sm: "calc(100% - 64px)" }, // Ajusta el alto para pantallas pequeñas y grandes
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: 3,
          p: 2,
        }}
      >
        {/* Primera columna */}
        <Grid container spacing={3} item xs={12} sm={6}>
          <Grid item xs={12}>
            <Typography variant="h6">Registro de Tarifa</Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <TextField
                label="Nombre Tarifa"
                InputProps={{
                  sx: { borderRadius: 2 },
                  endAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                }}
                id="name" // ID personalizado
                onChange={({ target }) =>
                  setMainData2((adata) => ({ ...adata, name: target.value }))
                }
                value={mainData2.name}
                error={errorName.error}
                required
                type="text"
                variant="outlined"
                fullWidth
              />
              <FormControl fullWidth required>
                <InputLabel id="cadence-label">Cadencia</InputLabel>
                <Select
                  labelId="cadence-label"
                  sx={{ borderRadius: 2 }}
                  label="Cadencia"
                  value={mainData2.concurrence}
                  error={errorConcurrence.error}
                  onChange={({ target }) =>
                    setMainData2((adata) => ({
                      ...adata,
                      concurrence: target.value,
                    }))
                  }
                >
                  <MenuItem value="Mensual">Mensual</MenuItem>
                  <MenuItem value="Quincenal">Quincenal</MenuItem>
                  <MenuItem value="Por dia">Por día</MenuItem>
                  <MenuItem value="Por hora">Por hora</MenuItem>
                  <MenuItem value="Por media hora">Por media hora</MenuItem>
                  <MenuItem value="Por paciente">Por paciente</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label="Plazo de cobro en días"
                InputProps={{
                  sx: { borderRadius: 2 },
                  endAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                }}
                id="term" // ID personalizado
                onChange={({ target }) =>
                  setMainData2((adata) => ({ ...adata, term: target.value }))
                }
                error={errorName.error}
                required
                type="number"
                variant="outlined"
                fullWidth
              />
              <TextField
                label="Valor"
                InputProps={{
                  sx: { borderRadius: 2 },
                }}
                id="price" // ID personalizado
                onChange={(event) => {
                  let val = FormatearNumeroConSeparador(event.target.value);
                  setMainData2((adata) => ({
                    ...adata,
                    price: val,
                  }));
                }}
                value={FormatearNumeroConSeparador(mainData2.price)}
                type="text"
                variant="outlined"
                fullWidth
                onKeyDown={(event) => {
                  // Permitir sólo números, la tecla de retroceso, la tecla de suprimir, las teclas de flecha izquierda y derecha
                  if (
                    !/[0-9]/.test(event.key) &&
                    event.key !== "Backspace" &&
                    event.key !== "Delete" &&
                    event.key !== "ArrowLeft" &&
                    event.key !== "ArrowRight"
                  ) {
                    event.preventDefault();
                  }
                }}
              />
            </Box>
            <Box
              mt={2}
              sx={{
                display: { md: "flex", sm: "flex", lg: "flex", xs: "none" },
                justifyContent: "center",
                gap: 2,
              }}
            >
              <Button
                onClick={handleCloseModal}
                variant="contained"
                sx={{
                  px: 3,
                  bgcolor: "#fff",
                  color: "primary.contrastText",
                  boxShadow:
                    "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                  ":hover": {
                    bgcolor: "#ebebeb",
                    boxShadow:
                      "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                  },
                  textTransform: "none",
                  width: "100%",
                }}
              >
                Cancelar
              </Button>
              <Button
                onClick={Submitmodal}
                variant="contained"
                sx={{
                  px: 3,
                  textTransform: "none",
                  width: "100%",
                }}
              >
                Guardar
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Box
          sx={{
            display: { md: "block", xs: "none", sm: "block" },
            width: "1px",
            height: "100%",
            backgroundColor: "gray", // Color de la línea
            mx: 1, // Margen horizontal (espacio alrededor de la línea)
          }}
        />

        {/* Segunda columna */}
        <Grid container spacing={3} item xs={12} sm={6}>
          <Grid item xs={12}>
            <Typography variant="h6">Configuración de Repetición</Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <ComponentRepeticion
                mainData2={mainData2}
                setMainData2={setMainData2}
              />
            </Box>

            <Box
              mt={2}
              sx={{
                display: { md: "none", lg: "none", xs: "flex" },
                justifyContent: "center",
                gap: 2,
              }}
            >
              <Button
                onClick={handleCloseModal}
                variant="contained"
                sx={{
                  px: 3,
                  bgcolor: "#fff",
                  color: "primary.contrastText",
                  boxShadow:
                    "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                  ":hover": {
                    bgcolor: "#ebebeb",
                    boxShadow:
                      "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                  },
                  textTransform: "none",
                  width: "100%",
                }}
              >
                Cancelar
              </Button>
              <Button
                onClick={Submitmodal}
                variant="contained"
                sx={{
                  px: 3,
                  textTransform: "none",
                  width: "100%",
                }}
              >
                Guardar
              </Button>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
