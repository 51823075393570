import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import GlobalHeader from "../../components/header";
import { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import AbcOutlinedIcon from "@mui/icons-material/AbcOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import PhoneIcon from "@mui/icons-material/Phone";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link, useParams } from "react-router-dom";
import useSnack from "../../hooks/useSnack";
import SnackAlert from "../../components/SnackAlert";
import useApi from "../../hooks/useApi";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import ModalRate from "./modalRate";
import DeleteRate from "./deleteRate";
import ModalRateUpdate from "./modalUpdateRate";
import { fetchProfile } from "../ExternalChats.jsx/fetch";
import FormatearNumeroConSeparador from "../../utils/formatNumber";

export default function InfoCenter() {
  const navigate = useNavigate();
  const [photo_profile, setPhoto_profile] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [openModalUpdate, setOpenModalUpdate] = useState(false);
  const [openModaldelete, setOpenModaldelete] = useState(false);

  const [errorName, setErrorName] = useState({
    error: false,
    Message: "",
  });

  const [errorConcurrence, setErrorConcurrence] = useState({
    error: false,
    Message: "",
  });

  const [errorPrice, setErrorPrice] = useState({
    error: false,
    Message: "",
  });

  const handleClickOpenModal = () => {
    setOpenModal(true);
  };

  const [mainData2, setMainData2] = useState({
    name: "",
    concurrence: "",
    price: "",
    term: 0,
    frequency: "",
    daysOfWeek: {
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
      Saturday: false,
      Sunday: false,
    },
    endDate: null,
  });

  const [mainDataUpdate, setMainDataUpdate] = useState({
    name: "",
    concurrence: "",
    price: "",
    term: 0,
    frequency: "",
    daysOfWeek: {
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
      Saturday: false,
      Sunday: false,
    },
    endDate: null,
  });

  const [mainDataUpdateError, setMainDataUpdateError] = useState({
    name: false,
    concurrence: false,
    price: false,
    term: false,
  });

  const handleCloseModal = () => {
    setMainData2({
      name: "",
      concurrence: "",
      price: "",
      term: 0,
      frequency: "",
      daysOfWeek: {
        Monday: false,
        Tuesday: false,
        Wednesday: false,
        Thursday: false,
        Friday: false,
        Saturday: false,
        Sunday: false,
      },
      endDate: null,
    });
    setErrorPrice({ error: false, Message: "" });
    setErrorName({
      error: false,
      Message: "",
    });
    setErrorConcurrence({ error: false, Message: "" });
    setOpenModal(false);
  };

  const [registros, setRegistros] = useState([]);
  const { loadApi, loadingApi } = useApi();
  const { msgSB, openSB, showSnack, typeSB } = useSnack();
  const { centerid } = useParams();
  const [mainData, setMainData] = useState({
    name: "",
    phone: "",
    email: "",
    work_regime: "",
  });

  const [openbackd, setOpenbackd] = useState(false);

  useEffect(() => {
    loadApi(`rate/rates/${centerid}`, true, "get")
      .then((response) => {
        setRegistros(response.data);
      })
      .catch((e) => {
        showSnack(e.message);
      });
    fetchProfile(setPhoto_profile, showSnack, setOpenbackd, loadApi);
  }, []);
  //cargar foto usuario

  useEffect(() => {
    setOpenbackd(true);
    loadApi(`center/center/${centerid}`, true, "get")
      .then((response) => {
        setMainData({
          name: response.data.name,
          phone: response.data.phone,
          email: response.data.email,
          work_regime: response.data.work_regime,
        });
      })
      .catch((e) => {
        showSnack(e.message);
        if (e.message) {
          setTimeout(() => {
            navigate("/profile");
          }, 800);
        }
        setOpenbackd(true);
      })
      .finally((e) => {
        setOpenbackd(false);
      });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setOpenbackd(true);

    loadApi(`center/update/${centerid}`, true, "put", {
      ...mainData,
    })
      .then((response) => {
        showSnack(response.data.message, "success");
      })
      .catch((e) => {
        showSnack(e.message);
      })
      .finally((e) => {
        setOpenbackd(false);
      });
  };
  //Post
  const Submitmodal = async (e) => {
    e.preventDefault();

    if (mainData2.name !== "" && mainData2.concurrence !== "") {
      if (mainData2.name !== "") {
        setErrorName({
          error: false,
          Message: "",
        });
      }
      if (mainData2.concurrence !== "") {
        setErrorConcurrence({
          error: false,
          Message: "",
        });
      }

      setOpenModal(false);
      setOpenbackd(true);
      loadApi(`rate/${centerid}`, true, "post", {
        ...mainData2,
      })
        .then((response) => {
          showSnack(response.data.message, "success", 2000);

          setMainData2({ name: "" });
          setRegistros((adata) => [...adata, response.data.newRates]);
          handleCloseModal();
        })
        .catch((e) => {
          showSnack(e.message);
        })
        .finally((e) => {
          setOpenbackd(false);
        });
      // Envía datos a la función Submitmodal
    } else {
      // Maneja los errores de campos vacíos
      if (mainData2.name === "") {
        setErrorName({
          error: true,
          Message: "Error nombre de tarifa está vacio",
        });
      }
      if (mainData2.concurrence === "") {
        setErrorConcurrence({
          error: true,
          Message: "Error concurrencia de tarifa está vacio",
        });
      }

      showSnack("campos vacios", "error");
    }
  };

  const deleteR = (id) => {
    setOpenbackd(true);
    loadApi(`rate/delete/${id}`, true, "delete")
      .then((response) => {
        showSnack(response.data.message, "success", 1000);
        //eliminar por id del
        setRegistros((adata) => adata.filter((aitem) => aitem.id !== id));
      })
      .catch((e) => {
        showSnack(e.message);
      })
      .finally((e) => {
        setOpenbackd(false);
      });
  };

  const SubmitmodalUpdate = (id, index) => {
    // Validar datos antes de enviar

    setOpenbackd(true);
    setOpenModalUpdate(false);

    // Enviar solicitud a la API
    loadApi(`rate/update/${id}`, true, "put", {
      ...mainDataUpdate,
    })
      .then((response) => {
        // Actualizar el registro en la lista
        const updateTarifa = registros.map((item) =>
          item.id === id ? { ...item, ...mainDataUpdate } : item
        );
        setRegistros(updateTarifa);
        showSnack(response.data.message, "success");
      })
      .catch((e) => {
        console.error("Error en la solicitud:", e); // Para depuración
        showSnack(e.message);
      })
      .finally(() => {
        setOpenbackd(false);
      });
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openbackd}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <GlobalHeader LogoProfile={photo_profile} />
      <Container
        maxWidth="lg"
        sx={{ alignItems: "center", background: "#FFFFFF", p: 2 }}
      >
        <LoadingButton
          LinkComponent={Link}
          to="/profile"
          sx={{
            svg: { color: "#fff" },
            padding: 1,

            margin: "0!important",
          }}
        >
          <ArrowBackIcon />
          {"Atrás"}
        </LoadingButton>
        <Box component="form" onSubmit={handleSubmit}>
          <Grid container direction="row" sx={{ mb: 2 }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography
                variant="h5"
                sx={{ fontWeight: 600, color: "black", my: 2 }}
              >
                Información del centro
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2} marginBottom={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Nombre"
                InputProps={{
                  sx: { borderRadius: 2 },
                  endAdornment: (
                    <InputAdornment position="start">
                      <AbcOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
                id="Nombre" // ID personalizado
                type="text"
                variant="outlined"
                fullWidth
                onChange={({ target }) =>
                  setMainData((adata) => ({ ...adata, name: target.value }))
                }
                value={mainData?.name}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Celular"
                required
                InputProps={{
                  sx: { borderRadius: 2 },
                  endAdornment: (
                    <InputAdornment position="start">
                      <PhoneIcon />
                    </InputAdornment>
                  ),
                }}
                id="phone" // ID personalizado
                type="text"
                variant="outlined"
                fullWidth
                onChange={({ target }) =>
                  setMainData((adata) => ({ ...adata, phone: target.value }))
                }
                value={mainData?.phone}
                onKeyDown={(event) => {
                  // Permitir sólo números, la tecla de retroceso, la tecla de suprimir, las teclas de flecha izquierda y derecha
                  if (
                    !/[0-9]/.test(event.key) &&
                    event.key !== "Backspace" &&
                    event.key !== "Delete" &&
                    event.key !== "ArrowLeft" &&
                    event.key !== "ArrowRight"
                  ) {
                    event.preventDefault();
                  }
                }}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2} marginBottom={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Correo Electrónico"
                InputProps={{
                  sx: { borderRadius: 2 },
                  endAdornment: (
                    <InputAdornment position="start">
                      <MailOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
                id="correo" // ID personalizado
                type="email"
                variant="outlined"
                fullWidth
                onChange={({ target }) =>
                  setMainData((adata) => ({ ...adata, email: target.value }))
                }
                value={mainData?.email}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormControl fullWidth required>
                <InputLabel id="demo-simple-select-label">Régimen</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  sx={{ borderRadius: 2 }}
                  value={mainData.work_regime}
                  onChange={({ target }) =>
                    setMainData((adata) => ({
                      ...adata,
                      work_regime: target.value,
                    }))
                  }
                  label="regime"
                >
                  <MenuItem value={"Dependiente"}>Dependiente</MenuItem>
                  <MenuItem value={"Independiente"}>Independiente</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Stack>
            <LoadingButton
              loading={!!loadingApi.includes("put__center/update")}
              type="submit"
              variant="contained"
              sx={{
                textTransform: "none",
                width: { md: "20%" },
              }}
            >
              <SaveOutlinedIcon sx={{ marginInlineEnd: 1 }} /> Guardar
            </LoadingButton>
          </Stack>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
            textAlign="center"
            marginY={3}
            justifyContent="space-between"
            px={0.5}
          >
            <Typography
              variant="h5"
              sx={{ fontWeight: 600, color: "black", my: 2 }}
            >
              Tarifas
            </Typography>

            <Button
              variant="contained"
              disableElevation
              onClick={handleClickOpenModal}
              sx={{
                bgcolor: "secondary.main",
                color: "primary.contrastText",
                boxShadow:
                  "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                ":hover": {
                  bgcolor: "#ebebeb",
                  boxShadow:
                    "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                },
              }}
            >
              <AddOutlinedIcon sx={{ marginInlineEnd: 1 }} />{" "}
              <Typography variant="p1" sx={{ fontWeight: 600, color: "black" }}>
                Nueva Tarifa
              </Typography>
            </Button>
          </Stack>

          <Box
            sx={{
              border: "1px solid #e0e0e0",
              paddingY: 1,
              borderRadius: 1,
              my: 1,

              display: { xs: "none", sm: "none", md: "inherit" },
            }}
          >
            <Grid container alignItems="center">
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={2}
                align="center"
                sx={{ px: "0!important" }}
              >
                <Typography variant="body1">Tarifa</Typography>
              </Grid>
              <Divider orientation="vertical" flexItem />
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={2}
                align="center"
                sx={{ px: "0!important" }}
              >
                <Typography variant="body1">Cadencia</Typography>
              </Grid>
              <Divider orientation="vertical" flexItem />
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                align="center"
                sx={{ px: "0!important" }}
              >
                <Typography variant="body1">Plazo de cobro en dias</Typography>
              </Grid>
              <Divider orientation="vertical" flexItem />
              <Grid
                item
                xs={12}
                sm={12}
                md={2.9}
                lg={2.9}
                align="center"
                sx={{ px: "0!important" }}
              >
                <Typography variant="body1">Valor</Typography>
              </Grid>
              <Divider orientation="vertical" flexItem />
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                lg={2}
                align="center"
                sx={{ px: "0!important" }}
              >
                <Typography variant="body1">Acciones</Typography>
              </Grid>
            </Grid>
          </Box>
          {/* poner alert cuando no haya tarifas creadas */}
          {registros?.length < 1 && (
            <Stack mt={2}>
              <Alert severity="warning">
                Este centro no tiene tarifas por favor, agrega una nueva tarifa.
              </Alert>
            </Stack>
          )}
          {registros &&
            (registros || []).map((row, index) => (
              <Box
                sx={{
                  border: "1px solid #e0e0e0",
                  paddingY: 2,
                  borderRadius: 1,
                  my: 1,
                }}
              >
                <Grid container alignItems="center">
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={2}
                    align="center"
                    sx={{ px: "0!important" }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ display: { md: "none" } }}
                    >
                      Tarifa
                    </Typography>
                    <Typography variant="body1" fontWeight={400}>
                      {row.name}
                    </Typography>
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={3}
                    lg={2}
                    align="center"
                    sx={{ px: "0!important" }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ display: { md: "none" } }}
                    >
                      Cadencia
                    </Typography>
                    <Typography variant="body1" fontWeight={400}>
                      {row.concurrence}
                    </Typography>
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={3}
                    lg={3}
                    align="center"
                    sx={{ px: "0!important" }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ display: { md: "none" } }}
                    >
                      Plazo de cobros en días
                    </Typography>
                    <Typography variant="body1" fontWeight={400}>
                      {row.term}
                    </Typography>
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={2.9}
                    lg={2.9}
                    align="center"
                    sx={{ px: "0!important" }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ display: { md: "none" } }}
                    >
                      Valor
                    </Typography>
                    <Typography variant="body1" fontWeight={400}>
                      {FormatearNumeroConSeparador(row.price)}
                    </Typography>
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={2}
                    lg={2}
                    align="center"
                    sx={{ px: "0!important" }}
                  >
                    <IconButton
                      aria-label="edit"
                      size="large"
                      color="secondary"
                      sx={{
                        mx: 1,
                        bgcolor: "primary.main",
                        boxShadow:
                          "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                        ":hover": {
                          bgcolor: "primary.main",
                          boxShadow:
                            "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                        },
                      }}
                      //inicializar valores que iran en el modal para update
                      onClick={() => {
                        setMainDataUpdate({
                          id: row.id,
                          name: row.name,
                          concurrence: row.concurrence,
                          price: row.price,
                          term: row.term,
                          frequency: row.frequency,
                          daysOfWeek: row.daysOfWeek,
                          endDate: row.endDate,
                          dayOfMonth: row.dayOfMonth,
                        });
                        setOpenModalUpdate({
                          index: index,
                          id: row.id,
                        });
                      }}
                    >
                      <CreateOutlinedIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      size="large"
                      color="secondary"
                      sx={{
                        bgcolor: "#D32F2F!important",

                        boxShadow:
                          "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                        ":hover": {
                          bgcolor: "#D32F2F!important",
                          boxShadow:
                            "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                        },
                      }}
                      onClick={() => {
                        setOpenModaldelete(row.id);
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>
            ))}
        </Box>
      </Container>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
      <ModalRate
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        setMainData2={setMainData2}
        mainData2={mainData2}
        errorName={errorName}
        errorConcurrence={errorConcurrence}
        Submitmodal={Submitmodal}
      />
      <DeleteRate
        setOpenModaldelete={setOpenModaldelete}
        openModaldelete={openModaldelete}
        deleteR={deleteR}
      />

      <ModalRateUpdate
        openModalUpdate={openModalUpdate}
        setOpenModalUpdate={setOpenModalUpdate}
        mainDataUpdate={mainDataUpdate}
        setMainDataUpdate={setMainDataUpdate}
        SubmitmodalUpdate={SubmitmodalUpdate}
        mainDataUpdateError={mainDataUpdateError}
        setMainDataUpdateError={setMainDataUpdateError}
      />
    </>
  );
}

function ValidateModalUpdate(mainDataUpdate, setMainDataUpdateError) {
  const newAdata = {
    name: !mainDataUpdate.name,
    concurrence: !mainDataUpdate.concurrence,
    price: !mainDataUpdate.price,
    term: !mainDataUpdate.term,
    daysOfWeek: !Object.values(mainDataUpdate.daysOfWeek).includes(true), // Al menos un día debe estar marcado
  };

  setMainDataUpdateError(newAdata);

  return !Object.values(newAdata).includes(true);
}
