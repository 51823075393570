import Table from "@mui/material/Table";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  Switch,
  TableBody,
  TableContainer,
  TextField,
  Typography,
  MenuItem,
  Select,
  InputLabel
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import FormControlLabel from "@mui/material/FormControlLabel";
import Logosvg from "../../images/perfil.svg";
import PhoneIcon from "@mui/icons-material/Phone";
import Tooltip from "@mui/material/Tooltip";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import AbcOutlinedIcon from "@mui/icons-material/AbcOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import { useEffect, useRef, useState } from "react";
import Centros from "./centros";
import GlobalHeader from "../../components/header";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import useApi from "../../hooks/useApi";
import useSnack from "../../hooks/useSnack";
import SnackAlert from "../../components/SnackAlert";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { useNavigate } from "react-router-dom";
import Deletecenter from "./deletecenter";
import dateToInput from "../../utils/DateToInput";
import { fetchUpdatePictureProfile } from "./fetch";

export default function FormPerfil() {
  const { loadApi, loadingApi } = useApi();
  const [registros, setRegistros] = useState([]);
  const [openModaldelete, setOpenModaldelete] = useState(false);
  const navigate = useNavigate();
  const { msgSB, openSB, showSnack, typeSB } = useSnack();
  const [previewImage, setPreviewImage] = useState(null);
  const [photo, setPhoto] = useState(null);
  const fileInputRef = useRef(null);

  const [mainData, setMainData] = useState({
    name: "",
    lastname: "",
    national_id: "",
    birthday: "",
    email: "",
    phone: "",
    occupation: "",
  });


  const [openbackd, setOpenbackd] = useState(false);

  async function loadInitialData() {
    const [response1, response2] = await Promise.all([
      loadApi("center/centers_professionals", true, "get"),
      await loadApi("profile", true, "get"),
    ]);

    setRegistros(response1.data);
    setMainData({
      name: response2.data.name,
      lastname: response2.data.lastname,
      national_id: response2.data.national_id,
      birthday: response2.data.birthday,
      email: response2.data.email,
      phone: response2.data.phone,
      occupation: response2.data.occupation,
    });
    setPhoto(response2.data.photo_profile.url)
    setOpenbackd(false);
  }


  useEffect(() => {
    setOpenbackd(true);
    loadInitialData()
      .catch((e) => {
        showSnack(e.message);
        setOpenModal(false);
      })
      .finally((e) => {});
  }, []);

  const handleFileChange = (event) => {
    const photo_profile = event.target.files[0];
    if (photo_profile) {
      const foto = {
        photo_profile:photo_profile
      }
      const reader = new FileReader();
      reader.onload = (e) => setPreviewImage(e.target.result);
      reader.readAsDataURL(photo_profile);
      fetchUpdatePictureProfile(loadApi, showSnack, setOpenbackd,foto);
    }
  }

  const handleClickImage = () => {
    fileInputRef.current.click();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setOpenbackd(true);
    loadApi("profile/update",true,"put",{...mainData,})
      .then((response) => {
        showSnack(response.data.message, "success");
        window.location.reload();
      })
      .catch((e) => {
        showSnack(e.message);
        setOpenModal(false);
      })
      .finally((e) => {
        setOpenbackd(false);
      });
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 16,
      maxWidth: "auto",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#FFFFFF",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    "& .MuiSwitch-track": {
      borderRadius: 22 / 2,
      "&::before, &::after": {
        content: '""',
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: 16,
        height: 16,
      },
      "&::before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      "&::after": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "none",
      width: 16,
      height: 16,
      margin: 2,
    },
  }));

  const editcenter = (id) => {
    navigate(`/center/${id}`);
  };
  const deletecenter = (id) => {
    setOpenbackd(true);
    loadApi(`center/delete/${id}`, true, "delete")
      .then((response) => {
        showSnack(response.data.message, "success", 1000);
        setRegistros((adata) => adata.filter((aitem) => aitem.id !== id));
      })
      .catch((e) => {
        showSnack(e.message);
        setOpenModal(false);
      })
      .finally((e) => {
        setOpenbackd(false);
      });
  };
  const handleSwitchChange = (id, sta) => {
    const status = sta === "on" ? "off" : "on";
    setOpenbackd(true);
    loadApi(`center/update_status/${id}`, true, "put", { status })
      .then((response) => {
        showSnack(response.data.message, "success", 1000);
        setRegistros((prevRegistros) =>
          (prevRegistros || [])?.map((reg) =>
            reg.id === id ? { ...reg, status: status } : reg
          )
        );
      })

      .catch((e) => {
        showSnack(e.message);
        setOpenModal(false);
      })
      .finally((e) => {
        setOpenbackd(false);
      });
  };

  const [openModal, setOpenModal] = useState(false);
  const handleClickOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setErrorName({
      errorN: false,
      Message: "",
    });
  };

  const [mainData2, setMainData2] = useState({
    name: "",
  });
  const [errorName, setErrorName] = useState({
    errorN: false,
    Message: "",
  });

  //Guardar Modal
  const Submitmodal = async (e) => {
    e.preventDefault();
    let errname;
    if (mainData2.name.length < 1) {
      setErrorName({
        errorN: true,
        Message: "Error nombre del centro está vacio",
      });
      errname = true;
    } else {
      setErrorName({
        errorN: false,
        Message: "",
      });
      errname = false;
    }
    if (errname === false) {
      handleCloseModal();
      setOpenbackd(true);
      loadApi("center", true, "post", {
        ...mainData2,
      })
        .then((response) => {
          showSnack(response.data.message, "success", 2000);

          setRegistros((adata) => [...adata, response.data.newCenter]);
          setTimeout(() => {
            setMainData2({ name: "" });
            navigate(`/center/${response.data.newCenter.id}`);
          }, 1000);
        })
        .catch((e) => {
          showSnack(e.message);
          setOpenbackd(false);
        })
        .finally((e) => {
          setOpenbackd(false);
        });
    }
  };
  return (
    <>
      <GlobalHeader
        LogoProfile={""}
        previewImage={photo}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openbackd}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container
        maxWidth="lg"
        sx={{ alignItems: "center", background: "#FFFFFF", px: 2 }}
      >
        <Box component="form" onSubmit={handleSubmit}>
          <Grid container direction="row" marginTop={1}>
            <Grid item xs={12} sm={12} md={12} lg={12} alignItems={"center"}>
              <Typography
                variant="h5"
                sx={{ fontWeight: 600, color: "black", my: 2 }}
              >
                Perfil
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box display="flex" justifyContent="start" sx={
                {
                  cursor: 'pointer',
                }
              }>
                <img
                  alt="Foto de perfil"
                  src={previewImage || photo || Logosvg}
                  style={{
                    borderRadius: "50%",
                    height: 120,
                    width: 120,
                    objectFit: "cover",
                  }}
                  onClick={handleClickImage}
                />

                <input
                  style={{ display: "none" }}
                  type="file"
                  accept=".jpg, .jpeg, .png, .webp, .gif, .svg"
                  onChange={handleFileChange}
                  ref={fileInputRef}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2} sx={{ my: 2 }}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Nombre"
                InputProps={{
                  sx: { borderRadius: 2 },
                  endAdornment: (
                    <InputAdornment position="start">
                      <AbcOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
                id="Nombre" // ID personalizado
                type="text"
                variant="outlined"
                fullWidth
                onChange={({ target }) =>
                  setMainData((adata) => ({ ...adata, name: target.value }))
                }
                value={mainData.name}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Apellidos"
                InputProps={{
                  sx: { borderRadius: 2 },
                  endAdornment: (
                    <InputAdornment position="start">
                      <AbcOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
                id="lastName" // ID personalizado
                type="text"
                variant="outlined"
                fullWidth
                onChange={({ target }) =>
                  setMainData((adata) => ({ ...adata, lastname: target.value }))
                }
                value={mainData.lastname}
                required
              />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Correo Electrónico"
                InputProps={{
                  sx: { borderRadius: 2 },
                  endAdornment: (
                    <InputAdornment position="start">
                      <MailOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
                id="correo" // ID personalizado
                type="email"
                variant="outlined"
                fullWidth
                onChange={({ target }) =>
                  setMainData((adata) => ({ ...adata, email: target.value }))
                }
                value={mainData.email}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Celular"
                fullWidth
                variant="outlined"
                type="text" // Set input type to "tel" for phone number format
                InputProps={{
                  sx: { borderRadius: 2 },
                  endAdornment: (
                    <InputAdornment position="start">
                      <PhoneIcon />
                    </InputAdornment>
                  ),
                  inputProps: {
                    inputMode: "numeric", // Enable numeric input mode for mobile devices
                  },
                }}
                onChange={({ target }) =>
                  setMainData((adata) => ({ ...adata, phone: target.value }))
                }
                value={mainData.phone}
                required
                onKeyDown={(event) => {
                  // Permitir sólo números, la tecla de retroceso, la tecla de suprimir, las teclas de flecha izquierda y derecha
                  if (
                    !/[0-9]/.test(event.key) &&
                    event.key !== "Backspace" &&
                    event.key !== "Delete" &&
                    event.key !== "ArrowLeft" &&
                    event.key !== "ArrowRight"
                  ) {
                    event.preventDefault();
                  }
                }}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                type="number"
                disabled={true}
                label="Documento"
                InputProps={{
                  sx: { borderRadius: 2 },
                  endAdornment: (
                    <InputAdornment position="start">
                      <BadgeOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
                id="document" // ID personalizado
                variant="outlined"
                fullWidth
                onChange={({ target }) =>
                  setMainData((adata) => ({
                    ...adata,
                    national_id: target.value,
                  }))
                }
                value={mainData.national_id}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={mainData?.occupation}
                fullWidth
                required
                onChange={({ target }) =>
                  setMainData((adata) => ({
                    ...adata,
                    occupation: target.value,
                  }))
                }            
                >
                <MenuItem value={"Medico"}>Medico</MenuItem>
                <MenuItem value={"Albañil"}>Albañil</MenuItem>
                <MenuItem value={"Cirujano"}>Cirujano</MenuItem>
                <MenuItem value={"Cosmetologo"}>Cosmetólogo</MenuItem>
                <MenuItem value={"Dentista"}>Dentista</MenuItem>
              </Select>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Fecha de nacimiento"
                InputProps={{
                  sx: { borderRadius: 2 },
                }}
                InputLabelProps={{ shrink: true }}
                type="date"
                variant="outlined"
                fullWidth
                onChange={({ target }) =>
                  setMainData((adata) => ({
                    ...adata,
                    birthday: target.value,
                  }))
                }
                value={dateToInput(mainData?.birthday)}
                required
              />
            </Grid>
          </Grid>
          <Stack direction="row" mt={2}>
            <LoadingButton
              loading={!!loadingApi.includes("post__profile")}
              type="submit"
              variant="contained"
              sx={{
                px: 5,
                textTransform: "none",
                width: { xs: "100%", sm: "100%", md: "auto" },
              }}
            >
              <SaveOutlinedIcon sx={{ marginInlineEnd: 1 }} /> Guardar
            </LoadingButton>
          </Stack>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} marginTop={2}>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <Typography variant="h5">Centros</Typography>
                <Button
                  variant="contained"
                  disableElevation
                  onClick={handleClickOpenModal}
                  sx={{
                    width: { xs: "100%", sm: "auto", md: "auto" },
                    bgcolor: "secondary.main",
                    color: "primary.contrastText",
                    boxShadow:
                      "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                    ":hover": {
                      bgcolor: "#ebebeb",
                      boxShadow:
                        "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                    },
                  }}
                >
                  <AddOutlinedIcon sx={{ marginInlineEnd: 1 }} />{" "}
                  <Typography
                    variant="p1"
                    sx={{ fontWeight: 600, color: "black" }}
                  >
                    Nuevo Centro
                  </Typography>
                </Button>
              </Stack>
              {registros?.length < 1 && (
                <Stack mt={2}>
                  <Alert severity="warning">No tiene centros creados.</Alert>
                </Stack>
              )}
              <TableContainer component={Paper} sx={{ borderRadius: 2, my: 2 }}>
                <Table size="small" aria-label="simple table">
                  <TableBody>
                    {registros &&
                      (registros || [])?.map((row, index) => (
                        <StyledTableRow key={row.id} height="70px">
                          <StyledTableCell align="left">
                            {row.name}
                          </StyledTableCell>

                          <StyledTableCell align="right">
                            <Stack
                              direction="row"
                              sx={{ justifyContent: "flex-end" }}
                              spacing={2}
                            >
                              <IconButton
                                aria-label="delete"
                                title="Eliminate"
                                size="large"
                                color="secondary"
                                onClick={() => {
                                  setOpenModaldelete(row.id);
                                }}
                                sx={{
                                  height: "50%",
                                  bgcolor: "#f5b7b1",
                                  ":hover": {
                                    bgcolor: "#DEBFBF",
                                    boxShadow:
                                      "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                                  },
                                }}
                              >
                                <DeleteIcon fontSize="inherit" />
                              </IconButton>

                              <IconButton
                                aria-label="update"
                                size="large"
                                title="Editar"
                                sx={{
                                  bgcolor: "#aed6f1",
                                  ":hover": {
                                    bgcolor: "#C1CEE8!important",
                                    boxShadow:
                                      "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                                  },
                                }}
                                color="secondary"
                                onClick={() => {
                                  editcenter(row.id);
                                }}
                              >
                                <CreateOutlinedIcon fontSize="inherit" />
                              </IconButton>
                              <FormControlLabel
                                control={
                                  <Android12Switch
                                    checked={row.status === "on"}
                                    onClick={() =>
                                      handleSwitchChange(row.id, row.status)
                                    }
                                    onChange={({ target }) =>
                                      setRegistros((adata) => {
                                        let newData = [...adata];
                                        newData[index].status = target.value;

                                        return newData;
                                      })
                                    }
                                  />
                                }
                              />
                            </Stack>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Centros
                showSnack={showSnack}
                setRegistros={setRegistros}
                setOpenbackd={setOpenbackd}
                openModal={openModal}
                handleCloseModal={handleCloseModal}
                setMainData2={setMainData2}
                mainData2={mainData2}
                errorName={errorName}
                Submitmodal={Submitmodal}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Deletecenter
        setOpenModaldelete={setOpenModaldelete}
        openModaldelete={openModaldelete}
        deletecenter={deletecenter}
      />
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />

    </>
  );
}
