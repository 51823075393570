const get_profession = (val) => {
  const values = ["Medico", "Cirujano", "Cosmetologo", "Dentista"];
  if (values.includes(val)) {
    return "Pacientes";
  }
  return "Clientes";
};


export function fetchOccupation(loadApi,showSnack,setOccupation){
    loadApi("profile/occupation", true, "get")
      .then((response) => {
        const profession = get_profession(response.data?.occupation);
        setOccupation(profession);
      })
      .catch((e) => {
        showSnack(e.message);
        setOccupation("Clientes");
    });
}

