import {
  Backdrop,
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
  Divider,
  Stack,
  Alert,
  Button,
} from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import GlobalHeader from "../../components/header";
import { useEffect, useState } from "react";
import useSnack from "../../hooks/useSnack";
import SnackAlert from "../../components/SnackAlert";
import useApi from "../../hooks/useApi";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import DateCusmton from "../../utils/DateCusmton";
import BackClients from "../info_client/back";
import { TapsHeaders } from "../../components/tapsHeaders";
import ModalCommunications from "../client/modalCommunications";
import { fetchClient } from "../diary/fetch";
import { fetchChats, fetchProfile } from "./fetch";
import { fetchCenter } from "../diary/fetch";

export default function ExternalChats() {
  const { clientid } = useParams();
  const { loadApi, loadingApi } = useApi();
  const { msgSB, openSB, showSnack, typeSB } = useSnack();
  const [openbackd, setOpenbackd] = useState(false);
  const [photo_profile, setPhoto_profile] = useState();
  const [namecomplete, setNamecomplete] = useState({});
  const [chats, setChats] = useState([]);
  const [openModalCom, setOpenModalCom] = useState(false);
  const [centersfiltred, setCentersfiltred] = useState([]);
  const [response, setResponse] = useState(null);
  const [option, setOption] = useState({
    email: "",
    phone: "",
  });


  const navigate = useNavigate();

  const [mainDataModalComError, setMainDataModalComError] = useState({
    channel: false,
    text: false,
  });
  const [mainDataModalCom, setMainDataModalCom] = useState({
    channel: "",
    text: "",
  });

  const handleCloseModalCom = () => {
    setOpenModalCom(false);
    setMainDataModalCom({
      channel: "",
      text: "",
    });
  };

  useEffect(() => {
    fetchClient(setNamecomplete, showSnack, setOpenbackd, loadApi, clientid);
    fetchChats(clientid, setChats, showSnack, setOpenbackd, loadApi);
    fetchProfile(setPhoto_profile, showSnack, setOpenbackd, loadApi);
    fetchCenter(setCentersfiltred, showSnack, setOpenbackd, loadApi);
  }, []);



  useEffect(() => {
    if (chats.length > 0) {
      const emailChats = chats.filter((chat) => chat.channel === "Email");
      const phoneChats = chats.filter((chat) => chat.channel !== "Email");

      setOption({
        email: emailChats.length > 0 ? emailChats[0].email : "",
        phone: phoneChats.length > 0 ? phoneChats[0].phone : "",
      });
    }
  }, [chats]);

  const SubmitModalCommunications = () => {
    if (!ValidateModalCom(mainDataModalCom, setMainDataModalComError)) return;
    handleCloseModalCom();
    if (mainDataModalCom.channel === "Email") {
      setOpenbackd(true);
      loadApi(
        `externalChats/sendEmail/${openModalCom.id}/${openModalCom.center_id}`,
        true,
        "post",
        {
          ...mainDataModalCom,
        }
      )
        .then((response) => {
          showSnack(response.data.message, "success", 2000);
          setMainDataModalComError({
            channel: false,
            text: false,
          });

          // Agregar el nuevo mensaje al array chats
          setChats((prevChats) => [
            ...prevChats,
            {
              channel: mainDataModalCom.channel,
              text: mainDataModalCom.text,
              date: new Date().toISOString(),
              email: option.email,
              phone: option.phone,
            },
          ]);
        })
        .catch((e) => {
          showSnack(e.message);
        })
        .finally(() => {
          setOpenbackd(false);
        });
    } else {
      showSnack("El servicio aún no está habilitado", "error");
    }
  };

  const menu = [
    { label: "Datos", path: `/client/${clientid}` },
    { label: "Historial", path: `/client/register/${clientid}` },
    { label: "Agenda", path: `/client/diary/${clientid}` },
    { label: "Cobros", path: `/client/bill/${clientid}` },
    { label: "Comunicaciones", path: `/client/chat/${clientid}` },
  ]

  return (
    <>
      <GlobalHeader LogoProfile={photo_profile} />
      <Container
        maxWidth="lg"
        sx={{
          alignItems: "center",
          background: "#FFFFFF",
          p: 2,
          minHeight: 600,
        }}
      >
        <BackClients />

        <TapsHeaders clientid={clientid} namecomplete={namecomplete?.name +" "+ namecomplete?.lastname } menu={menu} >
          <Button
            onClick={(event) => {
              event.stopPropagation();
              setOpenModalCom({
                id: clientid,
                hasEmail: namecomplete?.email,
                center_id: centersfiltred[0]?.id,
              });
            }}
            sx={{
              bgcolor: "secondary.main",
              color: "primary.contrastText",
              marginTop: "17%",
              boxShadow:
                "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              ":hover": {
                bgcolor: "#ebebeb",
                boxShadow:
                  "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              },
            }}
          >
            <AddOutlinedIcon sx={{ marginInlineEnd: 1 }} />{" "}
            <Typography variant="p1" sx={{ fontWeight: 600, color: "black" }}>
              Nueva comunicación
            </Typography>
          </Button>
        </TapsHeaders>

        <Divider sx={{ mb: 2 }} />
        <Box
          sx={{
            border: "1px solid #e0e0e0",
            padding: 1,
            borderRadius: 1,
            my: 1,
            display: { xs: "none", sm: "none", md: "inherit" },
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={12} md={2} lg={2} align="center">
              <Typography variant="body1">Canal</Typography>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
            <Grid item xs={12} sm={12} md={3} lg={3} align="center">
              <Typography variant="body1">Correo/Celular</Typography>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
            <Grid item xs={12} sm={12} md={2} lg={2} align="center">
              <Typography variant="body1">Fecha</Typography>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
            <Grid item xs={12} sm={12} md={4} lg={4.8} align="center">
              <Typography variant="body1">Mensaje</Typography>
            </Grid>
          </Grid>
        </Box>
        {chats?.length < 1 && (
          <Stack mt={2}>
            <Alert severity="warning">El paciente no tiene mensajes.</Alert>
          </Stack>
        )}
        {chats &&
          chats.map((row, index) => (
            <Box
              key={index}
              sx={{
                border: "1px solid #e0e0e0",
                padding: 2,
                borderRadius: 1,
                my: 1,
              }}
            >
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={12} md={2} lg={2} align="center">
                  <Typography variant="body1" sx={{ display: { md: "none" } }}>
                    Canal
                  </Typography>
                  <Typography variant="body1" fontWeight={400}>
                    {row?.channel}
                  </Typography>
                </Grid>
                <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
                <Grid item xs={12} sm={12} md={3} lg={3} align="center">
                  <Typography variant="body1" sx={{ display: { md: "none" } }}>
                    Correo/Celular
                  </Typography>
                  <Typography variant="body1" fontWeight={400}>
                    {row?.channel === "Email" ? option.email : option.phone}
                  </Typography>
                </Grid>
                <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
                <Grid item xs={12} sm={12} md={2} lg={2} align="center">
                  <Typography variant="body1" sx={{ display: { md: "none" } }}>
                    Fecha
                  </Typography>
                  <Typography variant="body1" fontWeight={400}>
                    {DateCusmton(row?.date)}
                  </Typography>
                </Grid>
                <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
                <Grid item xs={12} sm={12} md={4} lg={4.8} align="center">
                  <Typography variant="body1" sx={{ display: { md: "none" } }}>
                    Mensaje
                  </Typography>
                  <Typography variant="body1" fontWeight={400}>
                    {row?.text}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          ))}
      </Container>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openbackd}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <ModalCommunications
        openModalCom={openModalCom}
        setMainDataModalCom={setMainDataModalCom}
        mainDataModalCom={mainDataModalCom}
        handleCloseModalCom={handleCloseModalCom}
        SubmitModalCommunications={SubmitModalCommunications}
        mainDataModalComError={mainDataModalComError}
        setMainDataModalComError={setMainDataModalComError}
      />
    </>
  );
}

function ValidateModalCom(mainDataModalCom, setMainDataModalComError) {
  var final = false;
  const newAdata = {
    channel: !mainDataModalCom.channel,
    text: !mainDataModalCom.text,
  };

  setMainDataModalComError(newAdata);

  for (let key in newAdata) {
    if (!!newAdata[key]) {
      final = true;
      break;
    }
  }

  return !final;
}
