import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    Typography,
    Box,
    Chip
  } from "@mui/material";
  import { useState } from "react";
  import { BiShowAlt } from "react-icons/bi";
  
  export function ShowModulesUser({ modules, user }) {
    const [openModal, setOpenModal] = useState(false);
  
    const onClose = () => {
      setOpenModal(false);
    };
  
    const handleSave = () => {
      onClose();
      // Call API to save the changes
    };
  
    return (
      <>
        <Box>
          <BiShowAlt
            style={{ cursor: "pointer" }}
            title="Ver módulos"
            size={25}
            onClick={() => setOpenModal(true)}
          />
        </Box>
  
        <Dialog open={openModal} onClose={onClose} fullWidth maxWidth="sm">
          <DialogTitle textAlign={'center'}>
            Módulos del usuario {user}
          </DialogTitle>
          <DialogContent>
            <Box mt={2}  display={'flex'} justifyContent={'center'}>
              {modules?.length > 0 ? (
                modules?.map((modulo) => (
                  <Chip
                    key={modulo.id}
                    label={modulo.name}
                    sx={{ fontWeight: 'bold',width: 'fit',m: '5px' }}
                    />
                ))
              ) : (
                <Typography variant="body1" color="textSecondary">
                  No hay módulos disponibles para este usuario.
                </Typography>
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
  