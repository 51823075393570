export function createDiaryAvailability(
  convertedObject,
  availabilityData,
  setAvailabilityData,
  loadApi,
  showSnack,
  setProfesionalId,
  newEntry
) {
  loadApi("availability/", true, "post", convertedObject)
    .then((response) => {
      const id = response.data.newAvailability.id;
      showSnack(response.data.message, "success");
      newEntry.id = id;
      setProfesionalId(response.data?.newAvailability?.user_id);
      setAvailabilityData([...availabilityData, newEntry]);
    })
    .catch((e) => {
      showSnack(e.message);
    })
    .finally(() => {});
}

export function getDiaryAvailability(
  setAvailabilityData,
  loadApi,
  showSnack,
  convertToLocal,
  timeZone
) {
  loadApi(`availability/all`, true, "get")
    .then((response) => {
      const convertedArray = response.data.availability.map((item) =>
        convertToLocal(item, timeZone)
      );

      setAvailabilityData(convertedArray);
    })
    .catch((e) => {
      showSnack(e.message);
    })
    .finally((e) => {});
}

export function deleteDiaryAvailability(
  showSnack,
  loadApi,
  setAvailabilityData,
  availabilityData,
  idToDelete
) {
  loadApi("availability/delete/" + idToDelete, true, "delete")
    .then((response) => {
      setAvailabilityData(
        availabilityData.filter((item) => item.id !== idToDelete)
      );
      showSnack(response.data.message, "success");
    })
    .catch((e) => {
      showSnack(e.message);
    })
    .finally(() => {});
}

export function updateDiaryAvailability(
  updatedEntry,
  onSave,
  onClose,
  loadApi,
  showSnack
) {
  loadApi(`availability/update/${updatedEntry?.id}`, true, "PUT", updatedEntry)
    .then((response) => {
      onSave(updatedEntry);
      onClose(); // Cerrar el modal después de guardar
    })
    .catch((e) => {
      // showSnack(e.message);
    })
    .finally(() => {
      // setOpenbackd(false);
    });
}
