import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
  Divider,
  Stack,
  ButtonGroup,
} from "@mui/material";
import dayjs from "dayjs";
import "dayjs/locale/es";
import GlobalHeader from "../../components/header";
import { useEffect, useState } from "react";
import useSnack from "../../hooks/useSnack";
import SnackAlert from "../../components/SnackAlert";
import useApi from "../../hooks/useApi";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import {
  Link,
  useLocation,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import { Calendar, momentLocalizer, DateInput } from "react-big-calendar";
import dayjsLocalizer from "react-big-calendar/lib/localizers/dayjs";
import moment from "moment-timezone";
import capitalizarCadena from "../../utils/Cadena";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "moment/locale/es"; // Importar el idioma español}
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import dateToInput from "../../utils/DateToInput";
import ModalInfoAppo from "./modalInfoAppointments";
import ModalCreateAppo from "./modalcreateAppointments";
import { Toolbar } from "react-big-calendar";
import ModalClient from "../client/modalclient";
import DeleteAppointment from "./modaldelete";
import { TapsHeaders } from "../../components/tapsHeaders";
import { fetchCenter } from "../diary/fetch";
import { fetchClients } from "../incomeGeneral/fetch";
import { fetchProfile } from "../ExternalChats.jsx/fetch";
import { fetchCenterOn } from "../incomeGeneral/fetch";
import { format, parse } from "@formkit/tempo";
const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

function convertArrayDatesToLocal(array, timeZone) {
  return array.map((item) => {
    // Convertir el startAt de UTC a la zona horaria local

    const localStart = format({
      date: `${item.date}T${item.start}:00.0Z`,
      format: "YYYY-MM-DD HH:mm",
      tz: timeZone, // Convertir a la zona horaria local del usuario
    });

    const localEnd = format({
      date: `${item.date}T${item.end}:00.0Z`,
      format: "YYYY-MM-DD HH:mm",
      tz: timeZone, // Convertir a la zona horaria local del usuario
    });

    // Extraer la nueva fecha y horas locales
    const newDate = localStart.split(" ")[0];
    const newStartAt = localStart.split(" ")[1];
    const newEndAt = localEnd.split(" ")[1];

    // Retornar el objeto con las fechas y horas actualizadas
    return {
      ...item,
      date: newDate,
      start: newStartAt,
      end: newEndAt,
    };
  });
}

function convertLocalToUTC(appointment, timeZone) {
  // Combinar la fecha y la hora local para `startAt`
  const localStartAt = `${appointment.date} ${appointment.startAt}`;

  // Convertir el startAt de la zona local a UTC
  const localStart = parse({
    date: localStartAt,
    format: "YYYY-MM-DD HH:mm",
    tz: timeZone, // La zona horaria local del usuario
  });

  const utcStart = format({
    date: localStart,
    format: "YYYY-MM-DD HH:mm",
    tz: "UTC", // Convertir a UTC
  });

  // Combinar la fecha y la hora local para `endAt`
  const localEndAt = `${appointment.date} ${appointment.endAt}`;

  // Convertir el endAt de la zona local a UTC
  const localEnd = parse({
    date: localEndAt,
    format: "YYYY-MM-DD HH:mm",
    tz: timeZone, // La zona horaria local del usuario
  });

  const utcEnd = format({
    date: localEnd,
    format: "YYYY-MM-DD HH:mm",
    tz: "UTC", // Convertir a UTC
  });

  // Extraer la fecha de UTC para actualizar si es necesario
  const newDate = utcStart.split(" ")[0];
  const startHour = utcStart.split(" ")[1];
  const endHour = utcEnd.split(" ")[1];

  // Retornar el objeto actualizado con las horas en UTC
  return {
    ...appointment,
    date: newDate, // Actualiza la fecha en UTC si es necesario
    startAt: startHour,
    endAt: endHour,
  };
}
function convertUTCToLocal(appointment, timeZone) {
  // Combinar la fecha y la hora UTC para `startAt`
  const utcStartAt = `${appointment.date}T${appointment.start}:00.0Z`; // Formato UTC

  const localStart = format({
    date: utcStartAt,
    format: "YYYY-MM-DD HH:mm",
    tz: timeZone, // Convertir a la zona horaria local
  });

  // Combinar la fecha y la hora UTC para `endAt`
  const utcEndAt = `${appointment.date}T${appointment.end}:00.0Z`; // Formato UTC

  const localEnd = format({
    date: utcEndAt,
    format: "YYYY-MM-DD HH:mm",
    tz: timeZone, // Convertir a la zona horaria local
  });

  // Extraer la nueva fecha y horas locales
  const newDate = localStart.split(" ")[0];
  const startHour = localStart.split(" ")[1];
  const endHour = localEnd.split(" ")[1];

  // Retornar el objeto actualizado con horas locales
  return {
    ...appointment,
    date: newDate, // Actualiza la fecha si es necesario
    start: startHour,
    end: endHour,
  };
}
export default function CalendarAppointments() {
  const [photo_profile, setPhoto_profile] = useState();
  const [centersfiltred, setCentersfiltred] = useState();
  const navigate = useNavigate();
  const { loadApi, loadingApi } = useApi();
  const { msgSB, openSB, showSnack, typeSB } = useSnack();
  const [openbackd, setOpenbackd] = useState(false);
  const [eventsFromApi, setEventsFromApi] = useState([]);
  const [events, setEvents] = useState([]);
  dayjs.locale("es");
  const localizer = dayjsLocalizer(dayjs);
  const formats = {
    dayHeaderFormat: (date, culture, localizer) =>
      dayjs(date).format("dddd DD MMMM YYYY"), // Formato personalizado
    eventTimeRangeFormat: () => "", // Para ocultar las horas
  };

  localizer.formats = {
    ...localizer.formats,
    dayRangeHeaderFormat: ({ start, end }, culture, localizer) =>
      `${localizer.format(start, "DD MMM", culture)} - ${localizer.format(
        end,
        "DD MMM",
        culture
      )}`,
  };
  const [clients, setClients] = useState([]);
  const [openModalInfoAppo, setOpenModalInfoAppo] = useState(false);
  const [openModalCreateAppo, setOpenModalCreateAppo] = useState(false);
  const [openModaldelete, setOpenModaldelete] = useState(false);
  const [idAppointments, setIdAppointments] = useState();
  const [openModalclient, setOpenModalclient] = useState(false);
  const [mainDataClient, setMainDataClient] = useState({
    center_id: "",
    name: "",
    lastname: "",
    national_id: "",
    email: "",
    phone: "",
    address: "",
    note: "",
  });
  const [mainDataErrorclient, setMainDataErrorclient] = useState({
    center_id: false,
    name: false,
    lastname: false,
    national_id: false,
    email: false,
    phone: false,
    address: false,
    note: false,
  });
  const handleCloseModalClient = () => {
    setOpenModalclient(false);
  };
  const [mainData, setMainData] = useState({
    center_id: "",
    client_id: "",
    date: "",
    startAt: "",
    endAt: "",
    phone: "",
    note: "",
  });
  const [updateError, setUpdateError] = useState({
    center_id: false,
    client_id: false,
    date: false,
    startAt: false,
    endAt: false,
    phone: false,
    note: false,
  });
  //main data del POST
  const [mainDataCreate, setMainDataCreate] = useState({
    center_id: "",
    client_id: null,
    date: new Date().toISOString().split("T")[0], // Fecha actual por defecto
    startAt: "",
    endAt: "",
    phone: "",
    note: "",
  });
  const [createError, setCreateError] = useState({
    center_id: false,
    client_id: false,
    date: false,
    startAt: false,
    endAt: false,
    phone: false,
    note: false,
  });

  const messages = {
    allDay: "Todo el día",
    agenda: "Agenda",
    date: "Fecha",
    time: "Hora",
    event: "Evento",
  };
  const handleCloseModalInfoAppo = () => {
    setOpenModalInfoAppo(false);
  };

  useEffect(() => {
    fetchProfile(setPhoto_profile, showSnack, setOpenbackd, loadApi);
    fetchClients(loadApi, setClients, showSnack);
    fetchCenter(setCenters, showSnack, setOpenbackd, loadApi);
    fetchCenterOn(setCentersfiltred, showSnack, loadApi);
  }, []);

  useEffect(() => {
    setOpenbackd(true);
    loadApi("calendar/calendars", true, "get")
      .then((response) => {
        const rtaFormat = convertArrayDatesToLocal(response.data, timeZone);

        setEventsFromApi(rtaFormat);
        setOpenbackd(false);
      })
      .catch((e) => {
        showSnack(e.message);
        setOpenbackd(false);
      })
      .finally((e) => {
        setOpenbackd(false);
      });
  }, []);
  const [centers, setCenters] = useState();

  useEffect(() => {}, []);

  useEffect(() => {
    // Verificar si hay datos en eventsFromApi
    if (eventsFromApi.length > 0) {
      // Mapear sobre los eventos y convertir las cadenas de fecha en objetos Date
      const updatedEvents = eventsFromApi.map((event) => ({
        ...event,
        start: dateutc(`${event.date}T${event.start}:00`),
        end: dateutc(`${event.date}T${event.end}:00`),
      }));

      // Actualizar el estado de events con los eventos actualizados
      setEvents(updatedEvents);
    }
  }, [eventsFromApi]);

  const CustomEventMobile = ({ event }) => {
    return (
      <Stack spacing={0.2} sx={{ width: "100%", overflow: "hidden" }}>
        <Typography sx={{ color: "#fff!important", fontWeight: 400 }}>
          {event?.center_name}
        </Typography>
        <Typography sx={{ color: "#fff!important", fontWeight: 400 }}>
          {capitalizarCadena(
            !event.client_name && !event.client_lastname
              ? "sin paciente"
              : `${event.client_name} ${event.client_lastname}`
          )}
        </Typography>
      </Stack>
    );
  };

  const slotPropGetter = (date) => {
    return {
      style: {
        minheight: "100vh", // Ajusta este valor según sea necesario
      },
    };
  };

  const mostrar = (info) => {
    const dateTimeStart = dayjs(info.start);
    // Extrae y formatea solo la hora
    const timeStringStart = dateTimeStart.format("HH:mm");
    //------------------------------------
    const dateTimeEnd = dayjs(info.end);
    // Extrae y formatea solo la hora
    const timeStringEnd = dateTimeEnd.format("HH:mm");

    setIdAppointments(info.id);
    setMainData({
      center_id: info.center_id,
      client_id: info.client_id,
      date: info.date,
      startAt: timeStringStart,
      endAt: timeStringEnd,
      phone: info.client_phone,
      note: info.note,
    });

    setOpenModalInfoAppo(info);
  };

  const SubmitmodalUpdateAppo = (e) => {
    e.preventDefault();
    if (
      !ValidateModalUpdate(
        mainData,

        setUpdateError,
        showSnack
      )
    )
      return;
    if (!isAppointmentOverlapping(mainData, events, idAppointments)) {
      handleCloseModalInfoAppo();
      setOpenbackd(true);
      //antes de enviar convertir horas a utc

      const send = convertLocalToUTC(mainData, timeZone);

      loadApi(`calendar/update_calendar/${idAppointments}`, true, "put", {
        ...send,
      })
        .then((response) => {
          showSnack(response.data.message, "success", 2000);
          setEventsFromApi((adata) =>
            adata.filter((aitem) => aitem.id !== idAppointments)
          );
          const rta = convertUTCToLocal(
            response.data.transformedCalendar,
            timeZone
          );
          setEventsFromApi((adata) => {
            const newE = [...adata, rta];

            return newE;
          });
        })
        .catch((e) => {
          showSnack(e.message);
        })
        .finally((e) => {
          setOpenbackd(false);
        });
    } else {
      showSnack(
        "El nuevo ingreso se cruza con un ingreso existente",
        "error",
        5000
      );
    }
  };
  const SubmitmodalCreateAppo = (e) => {
    e.preventDefault();

    if (
      !ValidateModalCreate(
        mainDataCreate,

        setCreateError,
        showSnack
      )
    )
      return;

    if (!isAppointmentOverlapping(mainDataCreate, events, null)) {
      //antes de enviar convertir horas a utc

      const send = convertLocalToUTC(mainDataCreate, timeZone);

      setOpenModalCreateAppo(false);
      setOpenbackd(true);

      loadApi("calendar", true, "post", {
        ...send,
      })
        .then((response) => {
          const rta = convertUTCToLocal(
            response.data.transformedCalendar,
            timeZone
          );
          setEventsFromApi((adata) => {
            const newE = [...adata, rta];

            return newE;
          });

          showSnack(response.data.message, "success");
          setMainDataCreate({
            center_id: "",
            client_id: null,
            date: "",
            startAt: "",
            endAt: "",
            phone: "",
            note: "",
          });
        })
        .catch((e) => {
          showSnack(e.message);
        })
        .finally((e) => {
          setOpenbackd(false);
        });
    } else {
      showSnack(
        "El nuevo ingreso se cruza con un ingreso existente",
        "error",
        5000
      );
    }
  };

  const CustomToolbar = (props) => {
    const [selectedView, setSelectedView] = useState("week");
    const [selectedViewToday, setSelectedViewToday] = useState("");
    const goToBack = () => {
      props.onNavigate("PREV");
      setSelectedViewToday("");
    };

    const goToNext = () => {
      props.onNavigate("NEXT");
      setSelectedViewToday("");
    };

    const goToToday = () => {
      props.onNavigate("TODAY");
      setSelectedViewToday("TODAY");
    };

    const goToDayView = () => {
      props.onView("day");
      setSelectedView("day");
      setSelectedViewToday("");
    };

    const goToWeekView = () => {
      props.onView("week");
      setSelectedView("week");
      setSelectedViewToday("");
    };

    return (
      <Stack
        py={3}
        direction="row"
        className="custom-toolbar"
        alignItems="center"
        justifyContent="space-between"
        sx={{ position: "relative" }}
      >
        {" "}
        <ButtonGroup>
          <Button
            onClick={goToToday}
            sx={{
              bgcolor: selectedViewToday === "TODAY" ? "#004166" : "inherit",
              color: selectedViewToday === "TODAY" ? "#fff" : "inherit",
              boxShadow:
                "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              ":hover": {
                bgcolor: "#004166",
                boxShadow:
                  "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              },
            }}
          >
            Hoy
          </Button>
        </ButtonGroup>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          <Button
            onClick={goToBack}
            variant="text"
            sx={{
              p: 0,
              mr: -1,
            }}
          >
            <ArrowBackIosIcon sx={{ fontSize: 20 }} />
          </Button>
          <Typography variant="h6">{props.label}</Typography>
          <Button
            onClick={goToNext}
            variant="text"
            sx={{
              p: 0,
              mr: -1,
            }}
          >
            <ArrowForwardIosIcon sx={{ fontSize: 20 }} />
          </Button>
        </Stack>
        <ButtonGroup>
          <Button
            onClick={goToDayView}
            sx={{
              bgcolor: selectedView === "day" ? "primary.main" : "inherit",
              color: selectedView === "day" ? "#fff" : "inherit",
              boxShadow:
                "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              ":hover": {
                bgcolor: "#004166",
                boxShadow:
                  "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              },
            }}
          >
            Día
          </Button>
          <Button
            onClick={goToWeekView}
            sx={{
              bgcolor: selectedView === "week" ? "primary.main" : "inherit",
              color: selectedView === "week" ? "#fff" : "inherit",
              boxShadow:
                "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              ":hover": {
                bgcolor: "#004166",
                boxShadow:
                  "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              },
            }}
          >
            Semana
          </Button>
        </ButtonGroup>
      </Stack>
    );
  };
  const CustomToolbarMobile = (props) => {
    const [selectedView, setSelectedView] = useState("day");
    const [selectedViewToday, setSelectedViewToday] = useState("");
    const goToBack = () => {
      props.onNavigate("PREV");
      setSelectedViewToday("");
    };

    const goToNext = () => {
      props.onNavigate("NEXT");
      setSelectedViewToday("");
    };

    const goToToday = () => {
      props.onNavigate("TODAY");
      setSelectedViewToday("TODAY");
    };

    const goToDayView = () => {
      props.onView("day");
      setSelectedView("day");
      setSelectedViewToday("");
    };

    const goToWeekView = () => {
      props.onView("week");
      setSelectedView("week");
      setSelectedViewToday("");
    };

    return (
      <Stack
        py={3}
        spacing={3}
        className="custom-toolbar"
        alignItems="center"
        justifyContent="space-between"
      >
        <ButtonGroup>
          <Button
            onClick={goToToday}
            sx={{
              bgcolor: selectedViewToday === "TODAY" ? "#004166" : "inherit",
              color: selectedViewToday === "TODAY" ? "#fff" : "inherit",
              boxShadow:
                "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              ":hover": {
                bgcolor: "#004166",
                boxShadow:
                  "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              },
            }}
          >
            Hoy
          </Button>
          <Button
            onClick={goToDayView}
            sx={{
              bgcolor: selectedView === "day" ? "primary.main" : "inherit",
              color: selectedView === "day" ? "#fff" : "inherit",
              boxShadow:
                "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              ":hover": {
                bgcolor: "#004166",
                boxShadow:
                  "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              },
            }}
          >
            Día
          </Button>
          <Button
            onClick={goToWeekView}
            sx={{
              bgcolor: selectedView === "week" ? "primary.main" : "inherit",
              color: selectedView === "week" ? "#fff" : "inherit",
              boxShadow:
                "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              ":hover": {
                bgcolor: "#004166",
                boxShadow:
                  "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              },
            }}
          >
            Semana
          </Button>
        </ButtonGroup>
        <ButtonGroup>
          <Button onClick={goToBack} variant="text">
            <ArrowBackIosIcon sx={{ fontSize: 20 }} />
          </Button>
          <Typography variant="body1">{props?.label}</Typography>
          <Button onClick={goToNext} variant="text">
            <ArrowForwardIosIcon sx={{ fontSize: 20 }} />
          </Button>
        </ButtonGroup>
      </Stack>
    );
  };
  const Submit = async (e) => {
    e.preventDefault();

    if (!Validate(mainDataClient, setMainDataErrorclient)) return;
    handleCloseModalClient();
    setOpenbackd(true);
    loadApi("client", true, "post", {
      ...mainDataClient,
    })
      .then((response) => {
        showSnack(response.data.message, "success", 2000);
        setClients((adata) => [...adata, response.data.Client]);
        setMainDataClient({
          center_id: "",
          name: "",
          lastname: "",
          national_id: "",
          email: "",
          phone: "",
          address: "",
          note: "",
        });
      })
      .catch((e) => {
        showSnack(e.message);
      })
      .finally((e) => {
        setOpenbackd(false);
      });
  };
  const deleteAppoint = (id) => {
    setOpenbackd(true);
    loadApi(`calendar/delete/${id}`, true, "delete")
      .then((response) => {
        showSnack(response.data.message, "success", 1000);
        //eliminar por id del registro
        setEvents((adata) => adata.filter((aitem) => aitem.id !== id));
      })
      .catch((e) => {
        showSnack(e.message);
      })
      .finally((e) => {
        setOpenbackd(false);
      });
  };

  const getColorForId = (id) => {
    const palette = [
      "#fe3111",
      "#0aa5ed",
      "#0BAFAF",
      "#76448a",
      "#a873c2",
      "#d33e2f",
      "#108b6f",
      "#f1c40f",
      "#17a251",
      "#37526d",
    ]; // Ejemplo de una paleta de colores predefinida
    const index = id % palette.length; // Selecciona un índice en la paleta basado en el ID

    return palette[index]; // Devuelve el color correspondiente al índice seleccionado
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    const backgroundColor = getColorForId(event.center_id);

    return {
      style: {
        backgroundColor,
        borderRadius: "5px",
        opacity: 0.8,
        color: "white",
        border: "0px",
        display: "block",
      },
    };
  };

  const menu = [
    { label: "Disponibles", path: `/` },
    { label: "Agendadas", path: `/calendar/info` },
    { label: "Disponibilidad", path: `/calendar/availability` },
  ];

  return (
    <>
      <GlobalHeader LogoProfile={photo_profile} />
      <Container
        maxWidth="lg"
        sx={{
          alignItems: "center",
          background: "#FFFFFF",
          p: 2,
          minHeight: 600,
        }}
      >
        <TapsHeaders menu={menu} clientid={0} namecomplete={"Agenda"}>
          <Button
            variant="contained"
            disableElevation
            onClick={() => {
              setOpenModalCreateAppo(true);
            }}
            sx={{
              marginTop: "17%",
              bgcolor: "secondary.main",
              color: "primary.contrastText",
              boxShadow:
                "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              ":hover": {
                bgcolor: "#ebebeb",
                boxShadow:
                  "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              },
            }}
          >
            <AddOutlinedIcon sx={{ marginInlineEnd: 1 }} />{" "}
            <Typography variant="p1" sx={{ color: "black" }}>
              Nueva Agenda
            </Typography>
          </Button>
        </TapsHeaders>
        <Box
          sx={{ overflowX: "auto", width: "100%" }}
          display={{ xs: "none", sm: "inherit" }}
        >
          <Stack sx={{ minWidth: "800px" }}>
            <Calendar
              components={{
                toolbar: CustomToolbar,
                event: CustomEventMobile,
              }}
              startAccessor="start"
              endAccessor="end"
              messages={messages} // Utilizar los mensajes personalizados
              localizer={localizer}
              events={events}
              defaultView="week"
              views={["week", "day"]}
              formats={formats}
              slotPropGetter={slotPropGetter}
              onSelectEvent={mostrar}
              eventPropGetter={eventStyleGetter}
            />
          </Stack>
        </Box>
        <Box
          sx={{ overflowX: "auto", width: "100%" }}
          display={{ xs: "inherit", sm: "none" }}
        >
          <Stack sx={{ minWidth: "300px" }}>
            <Calendar
              components={{
                toolbar: CustomToolbarMobile,
                event: CustomEventMobile,
              }}
              startAccessor="start"
              endAccessor="end"
              localizer={localizer}
              events={events}
              defaultView="day"
              views={["week", "day"]}
              formats={formats}
              eventPropGetter={eventStyleGetter}
              onSelectEvent={mostrar}
            />
          </Stack>
        </Box>
      </Container>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openbackd}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ModalClient
        openModal={openModalclient}
        handleCloseModal={handleCloseModalClient}
        mainData={mainDataClient}
        setMainData={setMainDataClient}
        centers={centersfiltred}
        Submit={Submit}
        mainDataError={mainDataErrorclient}
      />
      <ModalInfoAppo
        openModalInfoAppo={openModalInfoAppo}
        handleCloseModalInfoAppo={handleCloseModalInfoAppo}
        setMainData={setMainData}
        mainData={mainData}
        centers={centers}
        SubmitmodalUpdateAppo={SubmitmodalUpdateAppo}
        setOpenModaldelete={setOpenModaldelete}
        idAppointments={idAppointments}
        updateError={updateError}
      />
      <ModalCreateAppo
        openModalCreateAppo={openModalCreateAppo}
        setOpenModalCreateAppo={setOpenModalCreateAppo}
        setMainDataCreate={setMainDataCreate}
        mainDataCreate={mainDataCreate}
        centers={centersfiltred}
        clients={clients}
        loadApi={loadApi}
        createError={createError}
        SubmitmodalCreateAppo={SubmitmodalCreateAppo}
        setCreateError={setCreateError}
        setOpenModalclient={setOpenModalclient}
        idAppointments={idAppointments}
      />
      <DeleteAppointment
        openModaldelete={openModaldelete}
        setOpenModaldelete={setOpenModaldelete}
        deleteAppoint={deleteAppoint}
        setOpenModalInfoAppo={setOpenModalInfoAppo}
      />
    </>
  );

  function dateutc(fecha_hora_str) {
    return new Date(fecha_hora_str.replace(" ", "T"));
  }

  function ValidateModalCreate(mainDataCreate, setCreateError, showSnack) {
    var final = false;
    const newAdata = {
      center_id: !mainDataCreate.center_id,
      date: !mainDataCreate.date,
      startAt: !mainDataCreate.startAt,
      endAt: !mainDataCreate.endAt,
    };

    // Add additional validation for startAt and endAt
    if (mainDataCreate.startAt && mainDataCreate.endAt) {
      const startAt = new Date(
        `${mainDataCreate.date}T${mainDataCreate.startAt}:00Z`
      );
      const endAt = new Date(
        `${mainDataCreate.date}T${mainDataCreate.endAt}:00Z`
      );
      if (startAt >= endAt) {
        newAdata.startAt = true;
        newAdata.endAt = true;
        showSnack(
          "La hora de inicio debe ser menor que la hora de fin.",
          "error",
          3000
        );
      }
    }

    setCreateError(newAdata);

    for (let key in newAdata) {
      if (!!newAdata[key]) {
        final = true;
        break;
      }
    }

    return !final;
  }
  function ValidateModalUpdate(
    mainData,

    setUpdateError,
    showSnack
  ) {
    var final = false;
    const newAdata = {
      center_id: !mainData.center_id,

      date: !mainData.date,
      startAt: !mainData.startAt,
      endAt: !mainData.endAt,
    };

    // Add additional validation for startAt and endAt
    if (mainData.startAt && mainData.endAt) {
      const startAt = new Date(`${mainData.date}T${mainData.startAt}:00Z`);
      const endAt = new Date(`${mainData.date}T${mainData.endAt}:00Z`);
      if (startAt >= endAt) {
        newAdata.startAt = true;
        newAdata.endAt = true;
        showSnack(
          "La hora de inicio debe ser menor que la hora de fin.",
          "error",
          3000
        );
      }
    }

    setUpdateError(newAdata);

    for (let key in newAdata) {
      if (!!newAdata[key]) {
        final = true;
        break;
      }
    }

    return !final;
  }
  function isAppointmentOverlapping(
    newAppointment,
    appointments,
    editingAppointmentId
  ) {
    const newStart = new Date(
      `${newAppointment.date} ${newAppointment.startAt}:00`
    );

    const newEnd = new Date(
      `${newAppointment.date} ${newAppointment.endAt}:00`
    );

    return appointments.some((appointment) => {
      if (appointment.id === editingAppointmentId) {
        return false;
      }
      if (appointment.date !== newAppointment.date) {
        return false;
      }
      // Convertir las fechas existentes en objetos Date (ya están en UTC)
      const existingStart = new Date(appointment.start); // Ya está en UTC
      const existingEnd = new Date(appointment.end); // Ya está en UTC

      return newStart < existingEnd && newEnd > existingStart;
    });
  }
}
function Validate(mainDataClient, setMainDataErrorclient) {
  var final = false;
  const newAdata = {
    center_id: !mainDataClient.center_id,
    national_id: !mainDataClient.national_id,
    name: !mainDataClient.name,
    lastname: !mainDataClient.lastname,
  };

  setMainDataErrorclient(newAdata);

  for (let key in newAdata) {
    if (!!newAdata[key]) {
      final = true;
      break;
    }
  }

  return !final;
}
