export function ValidateModalNewRate(mainDataNewRate, setMainDataNewRateError) {
  var final = false;
  const newAdata = {
    name: !mainDataNewRate.name,
    concurrence: !mainDataNewRate.concurrence,
    price: !mainDataNewRate.price,
  };

  setMainDataNewRateError(newAdata);

  for (let key in newAdata) {
    if (!!newAdata[key]) {
      final = true;
      break;
    }
  }

  return !final;
}

export function ValidateModalUpdateBill(mainDataUpdateBill, setUpdateBillError) {
  var final = false;
  const newAdata = {
    center_id: !mainDataUpdateBill.center_id,
    name_rate: !mainDataUpdateBill.name_rate,
    amount: !mainDataUpdateBill.amount,
    date: !mainDataUpdateBill.date,
    status: !mainDataUpdateBill.status,
  };

  setUpdateBillError(newAdata);

  for (let key in newAdata) {
    if (!!newAdata[key]) {
      final = true;
      break;
    }
  }

  return !final;
}

export function ValidateInfo(info, setInfoError) {
  var final = false;
  const newAdata = {
    lastname: !info.lastname,
    name: !info.name,
    national_id: !info.national_id,
  };

  setInfoError(newAdata);

  for (let key in newAdata) {
    if (!!newAdata[key]) {
      final = true;
      break;
    }
  }

  return !final;
}


export function ValidateModalNewBill(mainDataNewBill, setNewBillError) {
  var final = false;
  const newAdata = {
    center_id: !mainDataNewBill.center_id,
    name_rate: !mainDataNewBill.name_rate,
    amount: !mainDataNewBill.amount,
    date: !mainDataNewBill.date,
    status: !mainDataNewBill.status,
  };

  setNewBillError(newAdata);

  for (let key in newAdata) {
    if (!!newAdata[key]) {
      final = true;
      break;
    }
  }

  return !final;
}



export function ValidateModalAndBill(mainDataNewBill, setNewBillError) {
  var final = false;
  const newAdata = {
    center_id: !mainDataNewBill.center_id,
    client_id: !mainDataNewBill.client_id,
    name_rate: !mainDataNewBill.name_rate,
    amount: !mainDataNewBill.amount,
    date: !mainDataNewBill.date,
    status: !mainDataNewBill.status,
  };

  setNewBillError(newAdata);

  for (let key in newAdata) {
    if (!!newAdata[key]) {
      final = true;
      break;
    }
  }

  return !final;
}


export function ValidateModal(mainData, setMainDataError) {
  var final = false;
  const newAdata = {
    center_id: !mainData.center_id,
    date: !mainData.date,
  };

  setMainDataError(newAdata);

  for (let key in newAdata) {
    if (!!newAdata[key]) {
      final = true;
      break;
    }
  }

  return !final;
}
