import {
  Box,
  Chip,
  Menu,
  MenuItem,
  Button,
} from "@mui/material";
import { useState } from "react";
import { getStatusStyles } from "../../utils/getStyles";
import useApi from "../../hooks/useApi";
import { fetchUpdateStatus } from "./fetch";

export function ModalStatus({ row, showSnack, filteredData2, setFilteredData2 }) {
  const { loadApi } = useApi();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUpdate = (status) => {
    fetchUpdateStatus(
      loadApi,
      handleClose, // Cierra el menú después de seleccionar
      setFilteredData2,
      showSnack,
      filteredData2,
      status,
      row
    );
  };

  return (
    <>
      <Chip
        label={row?.status}
        sx={getStatusStyles(row?.status)}
        onClick={handleClick}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleUpdate("Pendiente")}>
          <Button
            variant="outlined"
            sx={getStatusStyles("Pendiente")}
            fullWidth
          >
            Pendiente
          </Button>
        </MenuItem>
        <MenuItem onClick={() => handleUpdate("Cobrado")}>
          <Button
            variant="outlined"
            sx={getStatusStyles("Cobrado")}
            fullWidth
          >
            Cobrado
          </Button>
        </MenuItem>
        <MenuItem onClick={() => handleUpdate("Atrasado")}>
          <Button
            variant="outlined"
            sx={getStatusStyles("Atrasado")}
            fullWidth
          >
            Atrasado
          </Button>
        </MenuItem>
      </Menu>
    </>
  );
}
