export function fetchUpdatePictureProfile(loadApi,showSnack,setOpenbackd,photo_profile){
    loadApi("profile/photo_update",true,"put",photo_profile,true)
    .then((response) => {
      showSnack(response.data.message, "success");
    })
    .catch((e) => {
      showSnack(e.message);
      setOpenbackd(false);
    })
    .finally((e) => {
      setOpenbackd(false);
    });
}