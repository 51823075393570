import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
  } from "@mui/material";
  
  export  function ModalDelete({
    openModalDelete,
    setOpenModalDelete,
    title,
    setDeleteBool,
  }) {

    return (
      <>
        <Dialog
          open={openModalDelete}
          keepMounted
          onClose={() => {
            setOpenModalDelete(false);
          }}
          sx={{ zIndex: 50000 }}
        >
          <DialogTitle>{"Eliminar Registro"}</DialogTitle>
          <DialogContent>
            <Box sx={{ minWidth: 500 }}>
              <DialogContentText id="deletecenter">
                {title}
              </DialogContentText>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenModalDelete(false);
              }}
            >
              Cancelar
            </Button>
            <Button
              color="error"
              onClick={() => setDeleteBool(true)}
              variant="contained"
            >
              Eliminar
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
  