import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Link,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  generateHoursOptions,
  calculateEndTime,
} from "../../utils/DateCusmton";
import { updateDiary } from "./fetch";
import { format, parse } from "@formkit/tempo";
const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

function convertLocalToUTC(appointment, timeZone) {
  // Combinar la fecha y la hora local para `startAt`
  const localStartAt = `${appointment.date} ${appointment.startAt}`;

  // Convertir el startAt de la zona local a UTC
  const localStart = parse({
    date: localStartAt,
    format: "YYYY-MM-DD HH:mm",
    tz: timeZone, // La zona horaria local del usuario
  });

  const utcStart = format({
    date: localStart,
    format: "YYYY-MM-DD HH:mm",
    tz: "UTC", // Convertir a UTC
  });

  // Combinar la fecha y la hora local para `endAt`
  const localEndAt = `${appointment.date} ${appointment.endAt}`;

  // Convertir el endAt de la zona local a UTC
  const localEnd = parse({
    date: localEndAt,
    format: "YYYY-MM-DD HH:mm",
    tz: timeZone, // La zona horaria local del usuario
  });

  const utcEnd = format({
    date: localEnd,
    format: "YYYY-MM-DD HH:mm",
    tz: "UTC", // Convertir a UTC
  });

  // Extraer la fecha de UTC para actualizar si es necesario
  const newDate = utcStart.split(" ")[0];
  const startHour = utcStart.split(" ")[1];
  const endHour = utcEnd.split(" ")[1];

  // Retornar el objeto actualizado con las horas en UTC
  return {
    ...appointment,
    date: newDate, // Actualiza la fecha en UTC si es necesario
    startAt: startHour,
    endAt: endHour,
  };
}
export default function EditModalDiary({
  openModalEditAppo,
  setOpenModalEditAppo,
  setMainDataEdit,
  mainDataEdit,
  centers,
  loadApi,
  client_id,
  showSnack,
  editError,
  setEditError,
  registros,
  setRegistros,
  setOpenbackd,
}) {
  const [mappedOptions, setMappedOptions] = useState([]);
  const hoursOptions = generateHoursOptions();

  const handleStartTimeChange = (event) => {
    const startTime = event.target.value;
    const endTime = calculateEndTime(startTime);
    setMainDataEdit((adata) => ({
      ...adata,
      startAt: startTime,
      endAt: endTime,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formattMainDataEdit = convertLocalToUTC(mainDataEdit, timeZone);
    updateDiary(
      formattMainDataEdit,
      showSnack,
      setOpenbackd,
      loadApi,
      setOpenModalEditAppo,
      setRegistros,
      registros
    );
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setMainDataEdit({
      id: null,
      date: "",
      note: "",
      center_id: "",
      center_name: "",
      client_id: client_id,
      client_name: "",
      client_lastname: "",
      client_national_id: "",
      client_phone: "",
      startAt: "",
      endAt: "",
    });
    setOpenModalEditAppo(false);
  };

  useEffect(() => {
    setMappedOptions(
      registros.map((reg) => ({
        label: `${reg.client_name} ${reg.client_lastname}`,
        value: reg.client_id,
      }))
    );
  }, [registros]);

  return (
    <>
      <Dialog
        open={!!openModalEditAppo}
        onClose={() => {
          setOpenModalEditAppo(false);
        }}
        PaperProps={{
          component: "form",
        }}
        disableScrollLock
        sx={{ overflow: "hidden", zIndex: "500 !important" }}
      >
        <DialogTitle>Editar Agenda</DialogTitle>
        <DialogContent>
          <Box sx={{ minWidth: { xs: "auto", md: 500 } }}>
            {centers?.length < 1 && (
              <Stack mb={1}>
                <Alert severity="warning">
                  Aún no tienes centros, crea un nuevo centro{" "}
                  <Link className="link" href="/profile" color="inherit">
                    aquí
                  </Link>
                </Alert>
              </Stack>
            )}
            <Grid
              container
              direction="row"
              spacing={1}
              marginBottom={2}
              mt={0.2}
            >
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-label">Centro</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    sx={{ borderRadius: 2 }}
                    value={mainDataEdit?.center_id}
                    onChange={({ target }) =>
                      setMainDataEdit((adata) => ({
                        ...adata,
                        center_id: target.value,
                      }))
                    }
                    label="Centro"
                    required
                    error={editError?.center_id}
                  >
                    <MenuItem value="" disabled>
                      Seleccione una opción...
                    </MenuItem>
                    {centers &&
                      centers.map((row, index) => (
                        <MenuItem key={row.id} value={row.id}>
                          {row.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Stack direction="row" spacing={1}>
                  <Autocomplete
                    label="Paciente"
                    disabled={true}
                    fullWidth
                    options={mappedOptions}
                    getOptionLabel={(option) => option?.label}
                    value={
                      mappedOptions.find(
                        (option) => option?.value === mainDataEdit?.client_id
                      ) || null
                    }
                    onChange={(event, newValue) => {
                      setMainDataEdit((adata) => ({
                        ...adata,
                        client_id: newValue ? newValue.value : null,
                      }));
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        label="Paciente"
                        {...params}
                        variant="outlined"
                        error={editError?.client_id}
                      />
                    )}
                  />
                </Stack>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1} marginBottom={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  label="Fecha"
                  InputProps={{
                    sx: { borderRadius: 2 },
                  }}
                  InputLabelProps={{ shrink: true }}
                  type="date"
                  variant="outlined"
                  fullWidth
                  onChange={({ target }) =>
                    setMainDataEdit((adata) => ({
                      ...adata,
                      date: target.value,
                    }))
                  }
                  value={mainDataEdit?.date}
                  required
                  error={editError?.date}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  label="Celular"
                  InputProps={{
                    sx: { borderRadius: 2 },
                  }}
                  id="Name"
                  type="text"
                  variant="outlined"
                  fullWidth
                  value={mainDataEdit?.client_phone}
                  InputLabelProps={{ shrink: true }}
                  onChange={({ target }) =>
                    setMainDataEdit((adata) => ({
                      ...adata,
                      client_phone: target.value,
                    }))
                  }
                  error={editError?.client_phone}
                  onKeyDown={(event) => {
                    if (
                      !/[0-9]/.test(event.key) &&
                      event.key !== "Backspace" &&
                      event.key !== "Delete" &&
                      event.key !== "ArrowLeft" &&
                      event.key !== "ArrowRight"
                    ) {
                      event.preventDefault();
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1} marginBottom={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormControl fullWidth>
                  <InputLabel id="start-time-label">Hora Inicio</InputLabel>
                  <Select
                    labelId="start-time-label"
                    value={mainDataEdit?.startAt}
                    onChange={handleStartTimeChange}
                    label="Hora Inicio"
                    error={editError?.startAt}
                  >
                    {hoursOptions.map((option) => (
                      <MenuItem key={option?.value} value={option?.value}>
                        {option?.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormControl fullWidth required>
                  <InputLabel id="end-time-label">Hora Fin</InputLabel>
                  <Select
                    labelId="end-time-label"
                    value={mainDataEdit?.endAt}
                    onChange={({ target }) =>
                      setMainDataEdit((adata) => ({
                        ...adata,
                        endAt: target.value,
                      }))
                    }
                    label="Hora Fin"
                    error={editError?.endAt}
                  >
                    {hoursOptions.map((option) => (
                      <MenuItem key={option?.value} value={option?.value}>
                        {option?.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Stack>
              <TextField
                id="outlined-textarea"
                label="Nota"
                placeholder="Nota"
                multiline
                fullWidth
                rows={2}
                InputProps={{
                  sx: { borderRadius: 2 },
                }}
                InputLabelProps={{ shrink: true }}
                value={mainDataEdit?.note}
                onChange={({ target }) =>
                  setMainDataEdit((adata) => ({
                    ...adata,
                    note: target.value,
                  }))
                }
                error={editError?.note}
              />
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions sx={{ mb: 1 }}>
          <Button
            onClick={(e) => handleCancel(e)}
            variant="contained"
            sx={{
              px: 5,
              bgcolor: "#fff",
              color: "primary.contrastText",
              boxShadow:
                "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              ":hover": {
                bgcolor: "#ebebeb",
                boxShadow:
                  "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              },
              textTransform: "none",
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={(e) => handleSubmit(e)}
            variant="contained"
            sx={{
              px: 5,
              textTransform: "none",
            }}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
