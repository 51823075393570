import { Box, Button, Grid, Stack, Typography, Skeleton } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import capitalizarCadena from "../utils/Cadena";

export function TapsHeaders({ namecomplete, clientid, children, menu }) {
  const navigate = useNavigate();
  const location = useLocation();

  // Helper function to determine if the tab is active
  const isActive = (path) => location.pathname === path;

  return (
    <Box
      sx={{
        width: '100%',
        padding: { xs: 1, sm: 1, md: 1 },
      }}
    >
      <Grid
        container
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={12} sm={6} md={8} lg={8}>
          <Typography
            variant="h6"
            mb={2}
            mt={2}
            sx={{
              fontSize: { xs: '1.2rem', sm: '1.5rem', md: '1.7rem' },
            }}
          >
            {capitalizarCadena(namecomplete || "Cliente")}
          </Typography>
          <Stack
            direction="row"
            spacing={1}
            flexWrap="wrap"
            gap={1}
          >
            {menu.map((tab) => (
               tab?.path === "/income" ? (
                <a href={tab?.path} key={tab.label}>
                    <Button
                  key={tab.label}
                  variant="contained"
                  sx={{
                    borderRadius: 2,
                    backgroundColor: isActive(tab.path) ? "#004080" : "#3D85C6",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: isActive(tab.path) ? "#003060" : "#1565c0",
                    },
                    textTransform: "none",
                    fontSize: { xs: '0.8rem', sm: '1rem', md: '1.1rem' },
                  }}
                >
                  {tab.label}
                </Button>
                </a>
              ) : (
                <Button
                  key={tab.label}
                  variant="contained"
                  sx={{
                    borderRadius: 2,
                    backgroundColor: isActive(tab.path) ? "#004080" : "#3D85C6",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: isActive(tab.path) ? "#003060" : "#1565c0",
                    },
                    textTransform: "none",
                    fontSize: { xs: '0.8rem', sm: '1rem', md: '1.1rem' },
                  }}
                  onClick={() => navigate(tab.path)}
                >
                  {tab.label}
                </Button>
              )
            ))}
        </Stack>

        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={4}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          pr={2}
          sx={{ mt: { xs: 2, sm: 0 } }}
        >
          {children}
        </Grid>
      </Grid>
    </Box>
  );
}
