import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import FormatearNumeroConSeparador from "../../utils/formatNumber";
import dateToInput from "../../utils/DateToInput";
import { useEffect, useState } from "react";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

export default function ModalNewBillGeneral({
  openModalNewBill,
  handleCloseModal,
  centers,
  mainDataNewBill,
  setMainDataNewBill,
  SubmitmodalBill,
  loadApi,
  newBillError,
  showAdditionalFields,
  setShowAdditionalFields,
  mainDataNewRate,
  setMainDataNewRate,
  mainDataNewRateError,
  clients,
}) {
  const [mappedOptions, setMappedOptions] = useState([]);
  const [rates, setRates] = useState([]);
  const [rate, setRate] = useState();

  useEffect(() => {
    if (mainDataNewBill.center_id) {
      loadApi(`rate/rates/${mainDataNewBill.center_id}`, true, "get")
        .then((response) => {
          setRates(response.data);
        })
        .catch(() => {});
    }
  }, [mainDataNewBill.center_id]);

  useEffect(() => {
    setMainDataNewBill((adata) => ({
      ...adata,
      amount: rate?.price,
      term: rate?.term,
    }));
  }, [rate, setMainDataNewBill]);

  useEffect(() => {
    if (showAdditionalFields) {
      setMainDataNewBill((adata) => ({
        ...adata,
        amount: "",
      }));
    }
  }, [showAdditionalFields, setMainDataNewBill]);

  useEffect(() => {
    setMainDataNewBill((adata) => ({
      ...adata,
      name_rate: mainDataNewRate.name,
      amount: mainDataNewRate.price,
      term: mainDataNewRate.term,
    }));
  }, [mainDataNewRate, setMainDataNewBill]);

  useEffect(() => {
    if (clients) {
      const options = clients.map((item) => ({
        label: `${item.name} ${item.lastname}`,
        value: item.id,
      }));
      setMappedOptions(options);
    }
  }, [clients]);

  const handleRateChange = (rate) => {
    setRate(rate);
  };

  const handleClientChange = (newValue) => {
    setMainDataNewBill((adata) => ({
      ...adata,
      client_id: newValue ? newValue.value : null,
    }));
  };

  const handleCenterChange = (event) => {
    const { value } = event.target;
    setMainDataNewBill((adata) => ({
      ...adata,
      center_id: value,
    }));
    setRates([]); // Reset rates when the center changes
    setRate(""); // Reset the rate
  };

  const handleDateChange = (event) => {
    const { value } = event.target;
    setMainDataNewBill((adata) => ({
      ...adata,
      date: value,
    }));
  };

  const handleNameRateChange = (event) => {
    const { value } = event.target;
    setMainDataNewBill((adata) => ({
      ...adata,
      name_rate: value,
    }));
    const selectedRate = rates.find((rate) => rate.name === value);
    setRate(selectedRate ? selectedRate : "");
  };

  const handleAmountChange = (event) => {
    const { value } = event.target;
    setMainDataNewBill((adata) => ({
      ...adata,
      amount: value,
    }));
  };

  const handleStatusChange = (event) => {
    const { value } = event.target;
    setMainDataNewBill((adata) => ({
      ...adata,
      status: value,
    }));
  };

  const handleCommentChange = (event) => {
    const { value } = event.target;
    setMainDataNewBill((adata) => ({
      ...adata,
      method: value,
    }));
  };

  const handleNewRateNameChange = (event) => {
    const { value } = event.target;
    setMainDataNewRate((adata) => ({
      ...adata,
      name: value,
    }));
  };

  const handleNewRatePriceChange = (event) => {
    const { value } = event.target;
    let formattedValue = FormatearNumeroConSeparador(value);
    setMainDataNewRate((adata) => ({
      ...adata,
      price: formattedValue,
    }));
  };

  const handleNewRateConcurrenceChange = (event) => {
    const { value } = event.target;
    setMainDataNewRate((adata) => ({
      ...adata,
      concurrence: value,
    }));
  };

  const handleClose = () => {
    setRates([]);
    setRate("");
    setMainDataNewBill({
      client_id: null,
      center_id: "",
      date: new Date().toISOString().split("T")[0], // Fecha actual por defecto
      name_rate: "",
      amount: "",
      status: "Pendiente",
      method: "",
      term: "",
    });
    handleCloseModal();
  };

  return (
    <Dialog
      open={openModalNewBill}
      onClose={handleClose}
      PaperProps={{
        component: "form",
      }}
      disableScrollLock
      sx={{ overflow: "hidden" }}
    >
      <DialogTitle>Agregar nuevo ingreso</DialogTitle>
      <DialogContent>
        <Box sx={{ width: { md: 500 } }}>
          <Grid
            container
            direction="row"
            spacing={2}
            marginTop={1}
            sx={{ mb: 2 }}
          >
            <Grid item xs={12} sm={12}>
              <Autocomplete
                label="Paciente"
                fullWidth
                options={mappedOptions}
                getOptionLabel={(option) => option.label}
                value={
                  mappedOptions.find(
                    (option) => option.value === mainDataNewBill?.client_id
                  ) || null
                }
                onChange={(event, newValue) => handleClientChange(newValue)}
                renderInput={(params) => (
                  <TextField
                    label="Paciente"
                    {...params}
                    variant="outlined"
                    error={newBillError?.client_id}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormControl fullWidth required>
                <InputLabel id="center-select-label">Centro</InputLabel>
                <Select
                  labelId="center-select-label"
                  label="Centro"
                  id="center-select"
                  sx={{ borderRadius: 2 }}
                  value={mainDataNewBill.center_id}
                  onChange={handleCenterChange}
                  error={newBillError?.center_id}
                >
                  <MenuItem value="" disabled>
                    Seleccione una opción...
                  </MenuItem>
                  {centers &&
                    centers.map((row, index) => (
                      <MenuItem key={index} value={row.id}>
                        {row.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Fecha"
                InputProps={{ sx: { borderRadius: 2 } }}
                InputLabelProps={{ shrink: true }}
                type="date"
                variant="outlined"
                fullWidth
                value={dateToInput(mainDataNewBill?.date)}
                onChange={handleDateChange}
                error={newBillError?.date}
                required
              />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth required disabled={showAdditionalFields}>
                <InputLabel id="rate-select-label">
                  Nombre de la tarifa
                </InputLabel>
                <Select
                  labelId="rate-select-label"
                  label="Nombre de la tarifa"
                  id="rate-select"
                  sx={{ borderRadius: 2 }}
                  value={mainDataNewBill?.name_rate}
                  onChange={handleNameRateChange}
                  error={newBillError?.name_rate}
                >
                  <MenuItem value="" disabled>
                    Seleccione una opción...
                  </MenuItem>
                  {rates?.length < 1 && (
                    <MenuItem value="">
                      <Alert severity="warning" sx={{ width: "100%" }}>
                        El centro que seleccionaste no tiene tarifas.
                      </Alert>
                    </MenuItem>
                  )}
                  {rates &&
                    rates.map((row, index) => (
                      <MenuItem key={index} value={row.name}>
                        {row.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Plazo"
                type="text"
                InputProps={{ sx: { borderRadius: 2 } }}
                fullWidth
                value={mainDataNewBill?.term}
                onChange={(event) => {
                  const { value } = event.target;
                  setMainDataNewBill((adata) => ({
                    ...adata,
                    term: value ? parseInt(value) : "",
                  }));
                }}
                error={false}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Valor"
                variant="outlined"
                type="text"
                id="amount"
                InputProps={{ sx: { borderRadius: 2 } }}
                fullWidth
                value={FormatearNumeroConSeparador(mainDataNewBill?.amount)}
                onChange={handleAmountChange}
                error={false}
                onKeyDown={(event) => {
                  // Permitir sólo números y teclas de control
                  if (
                    !/[0-9]/.test(event.key) &&
                    event.key !== "Backspace" &&
                    event.key !== "Delete" &&
                    event.key !== "ArrowLeft" &&
                    event.key !== "ArrowRight"
                  ) {
                    event.preventDefault();
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormControl fullWidth>
                <InputLabel id="status-select-label">Estado</InputLabel>
                <Select
                  labelId="status-select-label"
                  label="Estado"
                  id="status-select"
                  sx={{ borderRadius: 2 }}
                  value={mainDataNewBill?.status || "Pendiente"}
                  onChange={handleStatusChange}
                  error={newBillError?.status}
                >
                  <MenuItem value="Pendiente">Pendiente</MenuItem>
                  <MenuItem value="Cobrado">Cobrado</MenuItem>
                  <MenuItem value="Atrasado">Atrasado</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={12}>
              <TextField
                id="comment"
                label="Comentario"
                multiline
                InputProps={{ sx: { borderRadius: 2 } }}
                fullWidth
                maxRows={8}
                rows={2}
                value={mainDataNewBill?.method}
                onChange={handleCommentChange}
                error={newBillError?.method}
              />
            </Grid>
            {rates?.length < 1 && rates !== null && (
              <Grid item xs={12}>
                <Alert severity="warning">
                  El centro que seleccionaste no tiene tarifas, por favor crea
                  una tarifa nueva. Ten en cuenta que tu ingreso se guardará con
                  esta nueva tarifa.
                </Alert>
              </Grid>
            )}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions sx={{ mb: 1 }}>
        <Button
          onClick={handleClose}
          variant="contained"
          sx={{
            px: 5,
            bgcolor: "#fff",
            color: "primary.contrastText",
            boxShadow:
              "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
            ":hover": {
              bgcolor: "#ebebeb",
              boxShadow:
                "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
            },
            textTransform: "none",
          }}
        >
          Cancelar
        </Button>
        <Button
          onClick={SubmitmodalBill}
          variant="contained"
          sx={{
            px: 5,
            textTransform: "none",
          }}
        >
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/*

<Grid item xs={12}>
              <Button
                variant="contained"
                fullWidth
                disableElevation
                onClick={() => setShowAdditionalFields(true)}
                sx={{
                  bgcolor: "secondary.main",
                  color: "primary.contrastText",
                  boxShadow:
                    "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                  ":hover": {
                    bgcolor: "#ebebeb",
                    boxShadow:
                      "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                  },
                }}
              >
                <AddOutlinedIcon sx={{ marginInlineEnd: 1 }} />
                <Typography
                  variant="body1"
                  sx={{ fontWeight: 600, color: "black" }}
                >
                  Nueva Tarifa
                </Typography>
              </Button>
            </Grid>
{showAdditionalFields && (
            <Grid container direction="row" spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  required
                  label="Nombre de la tarifa"
                  variant="outlined"
                  fullWidth
                  InputProps={{ sx: { borderRadius: 2 } }}
                  onChange={handleNewRateNameChange}
                  value={mainDataNewRate.name}
                  error={mainDataNewRateError.name}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  required
                  label="Valor"
                  variant="outlined"
                  fullWidth
                  InputProps={{ sx: { borderRadius: 2 } }}
                  onChange={handleNewRatePriceChange}
                  value={mainDataNewRate.price}
                  error={mainDataNewRateError.price}
                  onKeyDown={(event) => {
                    // Permitir sólo números y teclas de control
                    if (
                      !/[0-9]/.test(event.key) &&
                      event.key !== "Backspace" &&
                      event.key !== "Delete" &&
                      event.key !== "ArrowLeft" &&
                      event.key !== "ArrowRight"
                    ) {
                      event.preventDefault();
                    }
                  }}
                />
              </Grid>
     
           <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Plazo"
                type="number"
                InputProps={{ sx: { borderRadius: 2 } }}
                fullWidth
                value={mainDataNewBill?.term || ""}
                onChange={(event) => {
                  const { value } = event.target;
                  setMainDataNewRate((adata) => ({
                    ...adata,
                    term: value ? parseInt(value) : "",
                  }));
                }}
                error={false}
              />
            </Grid>


              <Grid item xs={12} sm={12} md={4} lg={6}>
                <FormControl fullWidth required>
                  <InputLabel id="concurrence-select-label">
                    Cadencia
                  </InputLabel>
                  <Select
                    labelId="concurrence-select-label"
                    sx={{ borderRadius: 2, mb: 1 }}
                    label="Cadencia"
                    value={mainDataNewRate.concurrence}
                    onChange={handleNewRateConcurrenceChange}
                    error={mainDataNewRateError.concurrence}
                  >
                    <MenuItem value={"Mensual"}>Mensual</MenuItem>
                    <MenuItem value={"Quincenal"}>Quincenal</MenuItem>
                    <MenuItem value={"Por dia"}>Por día</MenuItem>
                    <MenuItem value={"Por hora"}>Por hora</MenuItem>
                    <MenuItem value={"Por media hora"}>Por media hora</MenuItem>
                    <MenuItem value={"Por paciente"}>Por paciente</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          )}
          */
