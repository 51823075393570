import { useState, useEffect } from "react";
import { fetchCenter } from "../diary/fetch";
import useSnack from "../../hooks/useSnack";
import { useParams } from "react-router-dom";
import useApi from "../../hooks/useApi";
import {
  getProfesionalAvailability,
  postProfesionalAvailability,
} from "./fetch";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Typography,
  Avatar,
  TextField,
  MenuItem,
  Select,
  CircularProgress,
  Backdrop,
  InputLabel,
  FormControl,
} from "@mui/material";
import SnackAlert from "../../components/SnackAlert";
import { parse, format } from "@formkit/tempo";

function convertToUTCAndAdjustDate(object, timeZone) {
  const { startAt, endAt, date } = object;

  // Convertir startAt a UTC
  const localStart = parse({
    date: startAt,
    format: "YYYY-MM-DD HH:mm",
    tz: timeZone, // La zona horaria local del usuario
  });

  const utcStart = format({
    date: localStart,
    format: "YYYY-MM-DD HH:mm",
    tz: "UTC", // Convertir a UTC
  });

  // Convertir endAt a UTC
  const localEnd = parse({
    date: endAt,
    format: "YYYY-MM-DD HH:mm",
    tz: timeZone, // La zona horaria local del usuario
  });

  const utcEnd = format({
    date: localEnd,
    format: "YYYY-MM-DD HH:mm",
    tz: "UTC", // Convertir a UTC
  });

  // Verificar si el día en startAt ha cambiado
  const newStartDate = new Date(utcStart);
  const originalDate = new Date(date);
  let adjustedDate = date;

  // Si la fecha UTC en startAt es diferente al día original
  if (newStartDate.getUTCDate() !== originalDate.getUTCDate()) {
    adjustedDate = utcStart.split(" ")[0]; // Ajustar la fecha si ha cambiado
  }

  // Verificar si la conversión cruzó al día siguiente
  if (newStartDate.getUTCDate() > originalDate.getUTCDate()) {
    adjustedDate = new Date(originalDate);
    adjustedDate.setUTCDate(originalDate.getUTCDate() + 1);
    adjustedDate = adjustedDate.toISOString().split("T")[0]; // Cambiar al día siguiente
  }

  // Retornar el objeto modificado
  const startHour = utcStart.split(" ")[1];
  const EndHour = utcEnd.split(" ")[1];

  return {
    ...object,
    startAt: startHour,
    endAt: EndHour,
    date: adjustedDate, // Cambiar la fecha si el día cambió
  };
}
function ValidateModalCreate(
  formData,

  setFormDataError
) {
  var final = false;
  const newAdata = {
    name: !formData.name,
    lastname: !formData.lastname,
    national_id: !formData.national_id,
    email: !formData.email,
    phone: !formData.phone,
    address: !formData.address,
  };

  setFormDataError(newAdata);

  for (let key in newAdata) {
    if (!!newAdata[key]) {
      final = true;
      break;
    }
  }

  return !final;
}

export function ScheduleAppointment() {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const { msgSB, openSB, showSnack, typeSB } = useSnack();
  const { loadApi } = useApi();
  const [centers, setCenters] = useState([]);
  const [openbackd, setOpenbackd] = useState(false);
  const [availability, setAvailability] = useState([]);
  const [selectedCenter, setSelectedCenter] = useState(""); // Store center_id here
  const [selectedDate, setSelectedDate] = useState("");
  const [availableDates, setAvailableDates] = useState([]);
  const [availableTimes, setAvailableTimes] = useState([]);
  const [selectedTime, setSelectedTime] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    lastname: "",
    national_id: "",
    email: "",
    phone: "",
    address: "",
    note: "",
  });
  const [formDataError, setFormDataError] = useState({
    name: false,
    lastname: false,
    national_id: false,
    email: false,
    phone: false,
    address: false,
  });
  const { id } = useParams();

  useEffect(() => {
    setOpenbackd(true);
    getProfesionalAvailability(
      id,
      setAvailability,
      showSnack,
      loadApi,
      setOpenbackd
    );
  }, [id]);

  useEffect(() => {
    // Extract unique centers with their IDs from availability
    if (availability?.availability) {
      setOpenbackd(false);
      const centersWithId = Array.from(
        new Map(
          availability.availability.map((a) => [a.center_id, a.center_name])
        ).entries()
      );
      setCenters(centersWithId);
    }
  }, [availability]);

  useEffect(() => {
    // Filter available dates based on selected center
    if (availability?.availability && selectedCenter) {
      const dates = Array.from(
        new Set(
          availability.availability
            .filter((slot) => slot.center_id === selectedCenter)
            .map((slot) => slot.date)
        )
      );
      setAvailableDates(dates);
    }
  }, [selectedCenter, availability]);

  useEffect(() => {
    // Filter available times based on selected date
    if (availability?.availability && selectedCenter && selectedDate) {
      const times = availability.availability.filter(
        (slot) =>
          slot.center_id === selectedCenter && slot.date === selectedDate
      );
      setAvailableTimes(times);
    }
  }, [selectedDate, selectedCenter, availability]);

  const handleCenterChange = (event) => {
    setSelectedCenter(event.target.value);
    setSelectedDate("");
    setAvailableDates([]);
    setAvailableTimes([]);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
  };

  const handleChange = (event) => {
    const { id, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleSubmit = async () => {
    if (!selectedCenter || !selectedDate || !selectedTime) {
      showSnack("Por favor, completa todos los campos requeridos.", "error");
      return;
    }

    if (!ValidateModalCreate(formData, setFormDataError)) {
      showSnack("Por favor, completa todos los campos requeridos.", "error");
      return;
    }

    // Construct appointment data
    const appointmentData = {
      center_id: selectedCenter,
      date: selectedDate,
      time: selectedTime,
      startAt: selectedTime.split(" - ")[0],
      endAt: selectedTime.split(" - ")[1],
      ...formData,
    };

    const appointmentDataConverd = convertToUTCAndAdjustDate(
      appointmentData,
      timeZone
    );

    postProfesionalAvailability(
      appointmentDataConverd,
      loadApi,
      showSnack,
      id,
      setFormData,
      setOpenbackd
    );
  };

  return (
    <Box maxWidth="md" mx="auto" p={2} sx={{ spacing: 2 }}>
      {/* Doctor Information */}
      <Card sx={{ mb: 4 }}>
        <Typography
          sx={{ textAlign: "center", fontWeight: "bold", paddingTop: "20px" }}
          variant="h5"
          mb={2}
        >
          Agenda tú cita
        </Typography>

        <CardContent
          display="flex"
          align="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Avatar
            sx={{ width: 56, height: 56 }}
            src={availability?.professionalInfo?.profileImage?.url}
          ></Avatar>

          <Typography variant="h6">
            {availability?.professionalInfo?.fullName}
          </Typography>

          <Typography variant="h6" mb={2}>
            {availability?.professionalInfo?.occupation}
          </Typography>
        </CardContent>
      </Card>

      {/* Select Clinic */}
      <Card sx={{ mb: 4 }}>
        <CardHeader
          title="Selecciona el centro de atención"
          subheader="Selecciona el centro de atención de tu preferencia."
        />
        <CardContent>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="clinic-label">
              Selecciona el centro de atención
            </InputLabel>
            <Select
              labelId="clinic-label"
              id="clinic"
              value={selectedCenter}
              onChange={handleCenterChange}
              label="Selecciona el centro de atención"
            >
              {centers.map(([center_id, center_name]) => (
                <MenuItem key={center_id} value={center_id}>
                  {center_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </CardContent>
      </Card>

      {/* Select Date */}
      {selectedCenter && (
        <Card sx={{ mb: 4 }}>
          <CardHeader
            title="Selecciona una fecha"
            subheader="Selecciona el día para el cual deseas ver los horarios disponibles."
          />
          <CardContent>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="date-label">Selecciona una fecha</InputLabel>
              <Select
                labelId="date-label"
                id="date"
                value={selectedDate}
                onChange={handleDateChange}
                label="Selecciona una fecha"
              >
                {availableDates.map((date) => (
                  <MenuItem key={date} value={date}>
                    {date}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </CardContent>
        </Card>
      )}

      {/* Select Time */}
      {selectedDate && (
        <Card sx={{ mb: 4 }}>
          <CardHeader
            title="Selecciona un horario"
            subheader="Selecciona el horario disponible en la fecha seleccionada."
          />
          <CardContent>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="time-label">Selecciona un horario</InputLabel>
              <Select
                labelId="time-label"
                id="time"
                value={selectedTime}
                onChange={handleTimeChange}
                label="Selecciona un horario"
              >
                {availableTimes.map((slot) => (
                  <MenuItem
                    key={`${slot.start}-${slot.end}`}
                    value={`${slot.start} - ${slot.end}`}
                  >
                    {`${slot.start} - ${slot.end}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </CardContent>
        </Card>
      )}

      {/* Schedule Appointment Form */}
      <Card sx={{ mb: 4 }}>
        <CardHeader
          title="Agendar cita médica"
          subheader="Completa el siguiente formulario para apartar tu cita."
        />
        <CardContent>
          <Box
            display="grid"
            gridTemplateColumns={{ xs: "1fr", sm: "1fr 1fr" }} // Responsive grid
            gap={2}
            mb={2}
          >
            <TextField
              label="Nombre"
              id="name"
              placeholder="Ingresa tu nombre"
              variant="outlined"
              value={formData.name}
              onChange={handleChange}
              fullWidth
              error={formDataError.name}
            />
            <TextField
              label="Apellido"
              id="lastname"
              placeholder="Ingresa tu apellido"
              variant="outlined"
              value={formData.lastname}
              onChange={handleChange}
              fullWidth
              error={formDataError.lastname}
            />
          </Box>
          <Box
            display="grid"
            gridTemplateColumns={{ xs: "1fr", sm: "1fr 1fr" }} // Responsive grid
            gap={2}
            mb={2}
          >
            <TextField
              label="Documento"
              id="national_id"
              placeholder="Ingresa tu documento"
              variant="outlined"
              value={formData.national_id}
              onChange={handleChange}
              fullWidth
              error={formDataError.national_id}
            />
            <TextField
              label="Correo electrónico"
              id="email"
              type="email"
              placeholder="Ingresa tu correo"
              variant="outlined"
              value={formData.email}
              onChange={handleChange}
              fullWidth
              error={formDataError.email}
            />
          </Box>
          <Box
            display="grid"
            gridTemplateColumns={{ xs: "1fr", sm: "1fr 1fr" }} // Responsive grid
            gap={2}
            mb={2}
          >
            <TextField
              label="Teléfono"
              id="phone"
              placeholder="Ingresa tu teléfono"
              variant="outlined"
              value={formData.phone}
              onChange={handleChange}
              fullWidth
              error={formDataError.phone}
            />

            <TextField
              label="Dirección"
              id="address"
              placeholder="Ingresa tu dirección"
              variant="outlined"
              value={formData.address}
              onChange={handleChange}
              fullWidth
              error={formDataError.address}
            />

            <TextField
              label="Motivo de la cita"
              id="note"
              placeholder="Describe el motivo de tu cita"
              variant="outlined"
              multiline
              rows={4}
              value={formData.note}
              onChange={handleChange}
              fullWidth
            />
          </Box>
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit}
          >
            Agendar cita
          </Button>
        </CardActions>
      </Card>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openbackd}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}
