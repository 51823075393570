export const getStatusStyles = (status) => {
  switch (status) {
    case "Pendiente":
      return { backgroundColor: "#ffe0b2" }; // Amarillo de fondo
    case "Cobrado":
      return { backgroundColor: "#c8e6c9" }; // Verde de fondo
    case "Atrasado":
      return { backgroundColor: "#ffcdd2" }; // Rojo claro de fondo
    default:
      return { backgroundColor: "#e0e0e0" };
  }
};

// Datos para el gráfico Doughnut
export const colors = {
  Pendiente: "#fd9e3a",
  Cobrado: "#4d9a51",
  Atrasado: "#f83b58",
};
