import GroupsIcon from '@mui/icons-material/Groups';
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import LogoutIcon from '@mui/icons-material/Logout';
import BarChartIcon from '@mui/icons-material/BarChart';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SearchIcon from '@mui/icons-material/Search';
const globalOptionsAdmin = [

];

const headerOptions = {
    logged: [

        {
            title: "Inicio",
            icon: HolidayVillageIcon,
            route: "/professionals",
            roles: [ "admin", "subUser"]
        },
        {
            title: "Consultas",
            icon: SearchIcon,
            route: "/professionals/query",
            roles: [ "admin","subUser" ]
        },
        {
            title: "Cerrar Sesión",
            route: "/logout",
            icon: LogoutIcon
        },
    ],
    default: [
        ...globalOptionsAdmin,
    ]
}

export default headerOptions;
