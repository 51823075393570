export default function capitalizarCadena(cadena) {
    // Dividir la cadena en palabras
    let palabras = cadena.toLowerCase().split(" ");

    // Capitalizar la primera letra de cada palabra
    let palabrasCapitalizadas = (palabras|| [])?.map((palabra) => {
      return palabra.charAt(0).toUpperCase() + palabra.slice(1);
    });

    // Unir las palabras capitalizadas en una sola cadena
    let cadenaCapitalizada = palabrasCapitalizadas.join(" ");

    // Retornar la cadena capitalizada
    return cadenaCapitalizada;
  }

  