import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Divider,
  Stack,
  IconButton,
  Alert,
  TextField,
  Chip,
  Autocomplete,
} from "@mui/material";
import GlobalHeader from "../../components/header";
import { useEffect, useState } from "react";
import useSnack from "../../hooks/useSnack";
import SnackAlert from "../../components/SnackAlert";
import useApi from "../../hooks/useApi";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import ModalUpdateBill from "./modalUpdateBill";
import { Await, Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import ModalNewBill from "./modalNewBill";
import ModalDeleteBill from "./modalDelete";
import { write, utils } from "xlsx";
import DateCusmton from "../../utils/DateCusmton";
import BackClients from "../info_client/back";
import { TapsHeaders } from "../../components/tapsHeaders";
import {
  ValidateModalNewRate,
  ValidateModalUpdateBill,
  ValidateModalNewBill,
} from "../../utils/validateModal";
import { dateToInputCustom } from "../../utils/DateCusmton";
import dateToInput from "../../utils/DateToInput";
import { fetchCenterOn } from "../incomeGeneral/fetch";
import { fetchInvoicesById } from "../incomeGeneral/fetch";
import { fetchProfile } from "../ExternalChats.jsx/fetch";
import { fetchCenter } from "../diary/fetch";
import FormatearNumeroConSeparador from "../../utils/formatNumber";

export default function Billclient() {
  const { clientid } = useParams();
  const navigate = useNavigate();
  const { loadApi, loadingApi } = useApi();
  const { msgSB, openSB, showSnack, typeSB } = useSnack();
  const [openbackd, setOpenbackd] = useState(false);
  const [photo_profile, setPhoto_profile] = useState();
  const [namecomplete, setNamecomplete] = useState("");
  const [invoices, setInvoices] = useState([]);
  const [openModaldelete, setOpenModaldelete] = useState(false);
  const [openModalUpdateBill, setOpenModalUpdateBill] = useState(false); // abrir modal de actualizar factura
  const [showAdditionalFields, setShowAdditionalFields] = useState(false); // permite nuevos campos para tarifa nueva (post)
  const [centers, setCenters] = useState();
  const [idupdate, setIdupdate] = useState();
  const [centersfiltred, setCentersfiltred] = useState();

  useEffect(() => {
    fetchCenterOn(setCentersfiltred, showSnack, loadApi);
    fetchInvoicesById(
      setOpenbackd,
      loadApi,
      setNamecomplete,
      showSnack,
      setInvoices,
      clientid
    );
    fetchProfile(setPhoto_profile, showSnack, setOpenbackd, loadApi);
    fetchCenter(setCenters, showSnack, setOpenbackd, loadApi);
  }, [clientid]);

  const [mainDataQuery, setMainDataQuery] = useState({
    center_id: "",
    date: "",
    note: "",
    file: "",
  });
  const [mainDataFilters, setMainDataFilters] = useState({
    name_center: "",
    month: "",
    year: "",
  });
  const [mainDataNewBill, setMainDataNewBill] = useState({
    center_id: "",
    client_id: clientid,
    name_rate: "",
    amount: "",
    date: new Date().toISOString().split("T")[0],
    status: "Pendiente",
    method: "",
  });
  const [mainDataUpdateBill, setMainDataUpdateBill] = useState({
    center_id: "",
    client_id: clientid,
    name_rate: "",
    amount: "",
    date: new Date().toISOString().split("T")[0],
    status: "Pendiente",
    method: "",
    term: null,
  });
  const [UpdateBillError, setUpdateBillError] = useState({
    client_id: false,
    name_rate: false,
    amount: false,
    date: false,
    status: false,
    method: false,
  });
  const [mainDataNewRate, setMainDataNewRate] = useState({
    name: "",
    concurrence: "",
    price: "",
  });
  const [mainDataNewRateError, setMainDataNewRateError] = useState({
    name: false,
    concurrence: false,
    price: false,
  });
  const [newBillError, setNewBillError] = useState({
    center_id: false,
    client_id: false,
    name_rate: false,
    amount: false,
    date: false,
    status: false,
    method: false,
  });
  const [openModalNewBill, setOpenModalNewBill] = useState(false);
  const handleCloseModal = () => {
    setOpenModalNewBill(false);
    setMainDataNewBill({
      center_id: "",
      client_id: clientid,
      name_rate: "",
      amount: "",
      date: new Date().toISOString().split("T")[0],
      status: "",
      method: "",
      term: null,
    });
    setMainDataNewRate({
      name: "",
      concurrence: "",
      price: "",
      term: null,
    });
    setShowAdditionalFields(false);
    setMainDataQuery({
      center_id: "",
      date: "",
      note: "",
      file: "",
    });
    setNewBillError({
      center_id: false,
      client_id: false,
      name_rate: false,
      amount: false,
      date: false,
      status: false,
      method: false,
      term: 0,
    });
  };
  const handleCloseModalUpdate = async () => {
    setOpenModalUpdateBill(false);
    setMainDataUpdateBill({
      center_id: "",
      client_id: clientid,
      amount: "",
      date: "",
      status: "",
      method: "",
      term: null,
    });
    setMainDataNewRate({
      name: "",
      concurrence: "",
      price: "",
    });
    setShowAdditionalFields(false);
  };
  // consulta la foto del profesional

  const [filteredData2, setFilteredData2] = useState([]);

  // Función para extraer años únicos de las fechas de invoices
  const getUniqueYears = (invoices) => {
    const years = invoices.map((invoice) =>
      new Date(invoice.date).getUTCFullYear()
    );
    return Array.from(new Set(years)); // Eliminar duplicados
  };

  const uniqueYears = getUniqueYears(invoices);

  const filteredData = invoices.filter((item) => {
    const formattedDate = dateToInput(item?.date);
    const itemDate = new Date(formattedDate);

    const itemMonth = itemDate.getUTCMonth() + 1; // Sumar 1 porque los meses van de 0 a 11
    const itemYear = itemDate.getUTCFullYear();

    const selectedMonth = Number(mainDataFilters.month);
    const selectedYear = Number(mainDataFilters.year);

    const monthMatches =
      mainDataFilters.month === "" || itemMonth === selectedMonth;
    const yearMatches =
      mainDataFilters.year === "" || itemYear === selectedYear;
    const centerMatches =
      mainDataFilters.name_center === "" ||
      item.center_name === mainDataFilters.name_center;

    return monthMatches && yearMatches && centerMatches;
  });
  useEffect(() => {
    setFilteredData2(filteredData);
  }, [invoices, mainDataFilters]);

  // para cambiar color de chip
  const getStatusStyles = (status) => {
    switch (status) {
      case "Pendiente":
        return { backgroundColor: "#ffe0b2" }; // Amarillo de fondo
      case "Cobrado":
        return { backgroundColor: "#c8e6c9" }; // Verde de fondo
      case "Atrasado":
        return { backgroundColor: "#ffcdd2" }; // Rojo claro de fondo
      default:
        return { backgroundColor: "#e0e0e0" };
    }
  };

  function capitalizarCadena(cadena) {
    // Dividir la cadena en palabras
    let palabras = cadena.toLowerCase().split(" ");

    // Capitalizar la primera letra de cada palabra
    let palabrasCapitalizadas = (palabras || []).map((palabra) => {
      return palabra.charAt(0).toUpperCase() + palabra.slice(1);
    });

    // Unir las palabras capitalizadas en una sola cadena
    let cadenaCapitalizada = palabrasCapitalizadas.join(" ");

    // Retornar la cadena capitalizada
    return cadenaCapitalizada;
  }

  const clearFilter = () => {
    setMainDataFilters({
      name_center: "",
      month: "",
      year: "",
    });
  };
  const SubmitmodalBill = async (e) => {
    e.preventDefault();

    if (showAdditionalFields === false) {
      if (!ValidateModalNewBill(mainDataNewBill, setNewBillError)) return;
      setOpenModalNewBill(false);
      setOpenbackd(true);
      //--- crea factura
      loadApi("invoice", true, "post", {
        ...mainDataNewBill,
      })
        .then((response) => {
          showSnack(response.data.message, "success", 2000);

          setInvoices((adata) => {
            const newInvoices = [...adata, response.data.response.invoices];
            // Ordenar el array por fecha
            newInvoices.sort((a, b) => new Date(b.date) - new Date(a.date));
            return newInvoices;
          });
        })
        .catch((e) => {
          showSnack(e.message);
          setOpenbackd(false);
        })
        .finally((e) => {
          setOpenbackd(false);
        });
      //---- se crea registro
      loadApi(
        `querie/${clientid}`,
        true,
        "post",
        {
          ...mainDataQuery,
        },
        true
      )
        .then((response) => {
          showSnack(response.data.message, "success");
        })
        .catch((e) => {
          showSnack(e.message);
          setOpenbackd(false);
        })
        .finally((e) => {
          setOpenbackd(false);
        });
      handleCloseModal();
    } else {
      if (!ValidateModalNewRate(mainDataNewRate, setMainDataNewRateError))
        return;
      if (!ValidateModalNewBill(mainDataNewBill, setNewBillError)) return;
      setOpenModalNewBill(false);
      setOpenbackd(true);
      //---- se crea factura
      loadApi("invoice", true, "post", {
        ...mainDataNewBill,
      })
        .then((response) => {
          showSnack(response.data.message, "success", 2000);

          setInvoices((adata) => {
            const newInvoices = [...adata, response.data.response.invoices];
            // Ordenar el array por fecha
            newInvoices.sort((a, b) => new Date(b.date) - new Date(a.date));
            return newInvoices;
          });
        })
        .catch((e) => {
          showSnack(e.message);
        })
        .finally((e) => {});

      loadApi(`rate/${mainDataNewBill.center_id}`, true, "post", {
        ...mainDataNewRate,
      })
        .then((response) => {
          showSnack(response.data.message, "success", 2000);
        })
        .catch((e) => {
          showSnack(e.message);
          setOpenbackd(false);
        })
        .finally((e) => {
          setOpenbackd(false);
        });
      //---- se crea registro
      loadApi(
        `querie/${clientid}`,
        true,
        "post",
        {
          ...mainDataQuery,
        },
        true
      )
        .then((response) => {
          showSnack(response.data.message, "success");
        })
        .catch((e) => {
          showSnack(e.message);
          setOpenbackd(false);
        })
        .finally((e) => {
          setOpenbackd(false);
        });
      handleCloseModal();
    }
  };
  const SubmitmodalBillUpdate = async (e) => {
    e.preventDefault();
    if (showAdditionalFields === false) {
      if (!ValidateModalUpdateBill(mainDataUpdateBill, setUpdateBillError))
        return;
      setOpenModalUpdateBill(false);
      setOpenbackd(true);
      //falta endpoint de actualizar ingreso
      loadApi(`invoice/update_invoice/${idupdate}`, true, "put", {
        ...mainDataUpdateBill,
      })
        .then((response) => {
          showSnack(response.data.message, "success", 2000);

          setInvoices((adata) =>
            adata.filter((aitem) => aitem.id !== idupdate)
          );
          setInvoices((adata) => {
            const newInvoices = [...adata, response.data.Invoice];
            // Ordenar el array por fecha
            newInvoices.sort((a, b) => new Date(b.date) - new Date(a.date));
            return newInvoices;
          });
          handleCloseModalUpdate();
        })
        .catch((e) => {
          showSnack(e.message);
          setOpenbackd(false);
        })
        .finally((e) => {
          setOpenbackd(false);
        });
    } else {
      if (!ValidateModalNewRate(mainDataNewRate, setMainDataNewRateError))
        return;
      if (!ValidateModalUpdateBill(mainDataUpdateBill, setUpdateBillError))
        return;
      setOpenModalUpdateBill(false);
      setOpenbackd(true);

      loadApi(`invoice/update_invoice/${idupdate}`, true, "put", {
        ...mainDataUpdateBill,
      })
        .then((response) => {
          showSnack(response.data.message, "success", 2000);
          setInvoices((adata) =>
            adata.filter((aitem) => aitem.id !== idupdate)
          );
          setInvoices((adata) => {
            const newInvoices = [...adata, response.data.Invoice];
            // Ordenar el array por fecha
            newInvoices.sort((a, b) => new Date(b.date) - new Date(a.date));
            return newInvoices;
          });
          handleCloseModalUpdate();
        })
        .catch((e) => {
          showSnack(e.message);
        })
        .finally((e) => {});

      loadApi(`rate/${mainDataUpdateBill.center_id}`, true, "post", {
        ...mainDataNewRate,
      })
        .then((response) => {
          showSnack(response.data.message, "success", 2000);
        })
        .catch((e) => {
          showSnack(e.message);
          setOpenbackd(false);
        })
        .finally((e) => {
          setOpenbackd(false);
        });
    }
  };
  const deleteBill = (id) => {
    setOpenbackd(true);
    loadApi(`invoice/delete/${id}`, true, "delete")
      .then((response) => {
        showSnack(response.data.message, "success", 3000);
        //eliminar por id del
        setInvoices((adata) => adata.filter((aitem) => aitem.id !== id));
      })
      .catch((e) => {
        showSnack(e.message);
        setOpenbackd(false);
      })
      .finally((e) => {
        setOpenbackd(false);
      });
  };

  const menu = [
    { label: "Datos", path: `/client/${clientid}` },
    { label: "Historial", path: `/client/register/${clientid}` },
    { label: "Agenda", path: `/client/diary/${clientid}` },
    { label: "Cobros", path: `/client/bill/${clientid}` },
    { label: "Comunicaciones", path: `/client/chat/${clientid}` },
  ];

  return (
    <>
      <GlobalHeader LogoProfile={photo_profile} />
      <Container
        maxWidth="lg"
        sx={{
          alignItems: "center",
          background: "#FFFFFF",
          p: 2,
          minHeight: 600,
        }}
      >
        <BackClients />
        <TapsHeaders
          clientid={clientid}
          namecomplete={namecomplete}
          menu={menu}
        >
          <Button
            variant="contained"
            disableElevation
            onClick={() => {
              setOpenModalNewBill(true);
            }}
            sx={{
              marginTop: "17%",
              bgcolor: "secondary.main",
              color: "primary.contrastText",
              boxShadow:
                "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              ":hover": {
                bgcolor: "#ebebeb",
                boxShadow:
                  "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              },
            }}
          >
            <AddOutlinedIcon sx={{ marginInlineEnd: 1 }} />{" "}
            <Typography variant="p1" sx={{ color: "black" }}>
              Nuevo cobro
            </Typography>
          </Button>
        </TapsHeaders>
        <Grid container direction="row" spacing={1}>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Stack direction="row" justifyContent="space-between">
              <Typography sx={{ m: 1 }}>Centro:</Typography>
              <FormControl
                sx={{ width: { xs: "75%", sm: "85%", lg: "100%" } }}
                required
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  sx={{ borderRadius: 2 }}
                  value={mainDataFilters?.name_center}
                  onChange={({ target }) =>
                    setMainDataFilters((adata) => ({
                      ...adata,
                      name_center: target.value,
                    }))
                  }
                  required
                  size="small"
                  displayEmpty
                >
                  <MenuItem value="" disabled selected>
                    Todos los centros...
                  </MenuItem>
                  {centers &&
                    (centers || []).map((row, index) => (
                      // recorrer centros row.id sera value y se mostrara row.name
                      <MenuItem key={index} value={row.name}>
                        {row.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Stack direction="row" justifyContent="space-between">
              <Typography sx={{ m: 1, minWidth: { xs: 55, sm: "auto" } }}>
                Mes:
              </Typography>
              <FormControl
                sx={{ width: { xs: "75%", sm: "85%", lg: "100%" } }}
                required
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  sx={{ borderRadius: 2 }}
                  value={mainDataFilters.month}
                  onChange={({ target }) =>
                    setMainDataFilters((adata) => ({
                      ...adata,
                      month: target.value,
                    }))
                  }
                  required
                  size="small"
                  displayEmpty
                >
                  <MenuItem value="" disabled selected>
                    Todos los meses...
                  </MenuItem>
                  <MenuItem value="01">Enero</MenuItem>
                  <MenuItem value="02">Febrero</MenuItem>
                  <MenuItem value="03">Marzo</MenuItem>
                  <MenuItem value="04">Abril</MenuItem>
                  <MenuItem value="05">Mayo</MenuItem>
                  <MenuItem value="06">Junio</MenuItem>
                  <MenuItem value="07">Julio</MenuItem>
                  <MenuItem value="08">Agosto</MenuItem>
                  <MenuItem value="09">Septiembre</MenuItem>
                  <MenuItem value="10">Octubre</MenuItem>
                  <MenuItem value="11">Noviembre</MenuItem>
                  <MenuItem value="12">Diciembre</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Stack direction="row" justifyContent="space-between">
              <Typography sx={{ m: 1, minWidth: { xs: 55, sm: "auto" } }}>
                Año:
              </Typography>
              <FormControl sx={{ width: { xs: "75%", sm: "85%", lg: "100%" } }}>
                <Autocomplete
                  size="small"
                  value={mainDataFilters.year}
                  onChange={(event, newValue) =>
                    setMainDataFilters({ ...mainDataFilters, year: newValue })
                  }
                  options={["", ...uniqueYears]} // Incluye opción para "Todos los años"
                  getOptionLabel={(option) =>
                    option === "" ? "Todos los años" : option.toString()
                  }
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                />
              </FormControl>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Button
                  fullWidth
                  sx={{
                    bgcolor: "secondary.main",
                    color: "primary.contrastText",
                    boxShadow:
                      "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                    ":hover": {
                      bgcolor: "#ebebeb",
                      boxShadow:
                        "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                    },
                  }}
                  onClick={clearFilter}
                >
                  Limpiar Filtros
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider sx={{ m: 2 }} />
        <Box
          sx={{
            border: "1px solid #e0e0e0",
            padding: 1,
            borderRadius: 1,
            my: 1,
            display: { xs: "none", sm: "none", md: "inherit" },
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              align="center"
              sx={{ px: "0!important" }}
            >
              <Typography variant="body1">Centro</Typography>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              align="center"
              sx={{ px: "0!important" }}
            >
              <Typography variant="body1">Paciente</Typography>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
            <Grid
              item
              xs={12}
              sm={12}
              md={1.4}
              lg={1.4}
              align="center"
              sx={{ px: "0!important" }}
            >
              <Box>
                <Typography variant="body1">Fecha</Typography>
              </Box>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
            <Grid
              item
              xs={12}
              sm={12}
              md={1.4}
              lg={1.4}
              align="center"
              sx={{ px: "0!important" }}
            >
              <Box sx={{ maxWidth: "85%" }}>
                <Typography variant="body1">Valor</Typography>
              </Box>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
            <Grid
              item
              xs={12}
              sm={12}
              md={2.2}
              lg={2.2}
              align="center"
              sx={{ px: "0!important" }}
            >
              <Box sx={{ maxWidth: "90%" }}>
                <Typography variant="body1" noWrap>
                  Comentario
                </Typography>
              </Box>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
            <Grid
              item
              xs={12}
              sm={12}
              md={1.4}
              lg={1.4}
              align="center"
              sx={{ px: "0!important" }}
            >
              <Box sx={{ maxWidth: "90%" }}>
                <Typography variant="body1">Estado</Typography>
              </Box>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
            <Grid
              item
              xs={12}
              sm={12}
              md={1.5}
              lg={1.5}
              align="center"
              sx={{ px: "0!important" }}
            >
              <Typography variant="body1">Acciones</Typography>
            </Grid>
          </Grid>
        </Box>
        {filteredData2?.length < 1 && (
          <Stack mt={2}>
            <Alert severity="warning">El paciente no tiene facturas.</Alert>
          </Stack>
        )}
        {filteredData2 &&
          (filteredData2 || [])?.map((row, index) => {
            const statusStyles = getStatusStyles(row?.status);

            return (
              <Box
                sx={{
                  border: "1px solid #e0e0e0",
                  padding: 2,
                  borderRadius: 1,
                  my: 1,
                }}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={2}
                    lg={2}
                    align="center"
                    sx={{ px: "0!important" }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ display: { md: "none" } }}
                    >
                      Centro
                    </Typography>
                    <Typography variant="body1" fontWeight={400}>
                      {capitalizarCadena(
                        row?.center_name !== null
                          ? row?.center_name
                          : "centro eliminado"
                      )}
                    </Typography>
                  </Grid>
                  <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={2}
                    lg={2}
                    align="center"
                    sx={{ px: "0!important" }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ display: { md: "none" } }}
                    >
                      Paciente
                    </Typography>
                    <Typography variant="body1" fontWeight={400}>
                      {capitalizarCadena(namecomplete)}
                    </Typography>
                  </Grid>
                  <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={1.4}
                    lg={1.4}
                    align="center"
                    sx={{ px: "0!important" }}
                  >
                    <Box>
                      <Typography
                        variant="body1"
                        sx={{ display: { md: "none" } }}
                      >
                        Fecha
                      </Typography>
                      <Typography variant="body1" fontWeight={400}>
                        {dateToInputCustom(row?.date)}
                      </Typography>
                    </Box>
                  </Grid>
                  <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={1.4}
                    lg={1.4}
                    align="center"
                    sx={{ px: "0!important" }}
                  >
                    <Box sx={{ maxWidth: "85%" }}>
                      <Typography
                        variant="body1"
                        sx={{ display: { md: "none" } }}
                      >
                        Valor
                      </Typography>
                      <Typography variant="body1" fontWeight={400}>
                        $ {FormatearNumeroConSeparador(row?.amount)}
                      </Typography>
                    </Box>
                  </Grid>
                  <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={2.2}
                    lg={2.2}
                    align="center"
                    sx={{ px: "0!important" }}
                  >
                    <Box sx={{ maxWidth: "90%" }}>
                      <Typography variant="body1" noWrap fontWeight={400}>
                        <Typography
                          variant="body1"
                          sx={{ display: { md: "none" } }}
                        >
                          Comentario
                        </Typography>
                        {row?.method ? row.method : "sin comentarios"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={1.4}
                    lg={1.4}
                    align="center"
                    sx={{ px: "0!important" }}
                  >
                    <Box sx={{ maxWidth: "90%" }}>
                      <Typography
                        variant="body1"
                        sx={{ display: { md: "none" } }}
                      >
                        Estado
                      </Typography>
                      {/*  <Typography variant="body1" fontWeight={400}>
                        {row?.status}
                      </Typography> */}
                      <Chip label={row?.status} style={statusStyles} />
                    </Box>
                  </Grid>
                  <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={1.5}
                    lg={1.5}
                    align="center"
                    sx={{ px: "0!important" }}
                  >
                    <IconButton
                      aria-label="edit"
                      size="large"
                      title="Editar"
                      color="secondary"
                      sx={{
                        mx: 1,
                        bgcolor: "#aed6f1",
                        ":hover": {
                          bgcolor: "#C1CEE8!important",
                          boxShadow:
                            "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                        },
                      }}
                      onClick={() => {
                        setOpenModalUpdateBill({
                          center_id: row?.center_id,
                          id: row?.id,
                          center_name: row?.center_name,
                          name_rate: row?.name_rate,
                          amount: row?.amount,
                          status: row?.status,
                          date: row?.date,
                          method: row?.method,
                          term: row?.term,
                        });
                      }}
                    >
                      <CreateOutlinedIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      title="Eliminar"
                      size="large"
                      color="secondary"
                      sx={{
                        bgcolor: "#f5b7b1",
                        ":hover": {
                          bgcolor: "#DEBFBF",
                          boxShadow:
                            "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                        },
                      }}
                      onClick={() => {
                        setOpenModaldelete(row.id);
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>
            );
          })}
      </Container>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openbackd}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ModalNewBill
        openModalNewBill={openModalNewBill}
        handleCloseModal={handleCloseModal}
        centers={centersfiltred}
        mainDataNewBill={mainDataNewBill}
        setMainDataNewBill={setMainDataNewBill}
        SubmitmodalBill={SubmitmodalBill}
        loadApi={loadApi}
        newBillError={newBillError}
        showAdditionalFields={showAdditionalFields}
        setShowAdditionalFields={setShowAdditionalFields}
        mainDataNewRate={mainDataNewRate}
        setMainDataNewRate={setMainDataNewRate}
        mainDataNewRateError={mainDataNewRateError}
        mainDataQuery={mainDataQuery}
        setMainDataQuery={setMainDataQuery}
      />
      <ModalUpdateBill
        openModalUpdateBill={openModalUpdateBill}
        handleCloseModalUpdate={handleCloseModalUpdate}
        centers={centers}
        mainDataUpdateBill={mainDataUpdateBill}
        setMainDataUpdateBill={setMainDataUpdateBill}
        SubmitmodalBillUpdate={SubmitmodalBillUpdate}
        loadApi={loadApi}
        UpdateBillError={UpdateBillError}
        showAdditionalFields={showAdditionalFields}
        setShowAdditionalFields={setShowAdditionalFields}
        mainDataNewRate={mainDataNewRate}
        setMainDataNewRate={setMainDataNewRate}
        mainDataNewRateError={mainDataNewRateError}
        clientid={clientid}
        setIdupdate={setIdupdate}
      />
      <ModalDeleteBill
        setOpenModaldelete={setOpenModaldelete}
        openModaldelete={openModaldelete}
        deleteBill={deleteBill}
      />
    </>
  );
}
