// Pages
import Login from "./pages/login/index.jsx";
import ResetPwd from "./pages/recovery-password/index.jsx";
import Register from "./pages/register/index.jsx";
import Logout from "./pages/login/logout.js";
import Profile from "./pages/profile/index.jsx";
import Center from "./pages/info_center/index.jsx";
import Client from "./pages/client/index.jsx";
import InfoClient from "./pages/info_client/index.jsx"
import Billclient from "./pages/billClient/index.jsx"
import Chat from "./pages/ExternalChats.jsx/index.jsx"
import Income from "./pages/incomeGeneral/index.jsx"
import IncomeInfo from "./pages/incomeInfo/index.jsx"
import Calendar from "./pages/calendar/index.jsx";
import RegisterData from "./pages/registerData/register.jsx";
import DetailsAppointments from "./pages/infoAppointments/index.jsx"
import Reports from "./pages/reports/index.jsx"
import ProfessionalOverview from "./pages/ViewAdmin_Professionals/index.jsx"
import ProfessionalQuery from "./pages/ViewAdmin_Query/index.jsx";
import PrevLogin from "./pages/prev_login/index.jsx"
import { Diary } from "./pages/diary/diary.jsx";
import { Collections } from "./pages/incomeGeneral/collections.jsx";
import { RegisterProfesional } from "./pages/registerProfesional/registerProfesional.jsx";
import { DiaryAvailable } from "./pages/diaryAvailability/diaryAvailability.jsx";
import { ScheduleAppointment } from "./pages/scheduleAppointment/scheduleAppointment.jsx";

const routes = [
  {
    route: "/register",
    role: "auth",
    component: <Register />,
  },
  {
    route: "/prev_login",
    role: "auth",
    component: <PrevLogin />,
  },
  {
    route: "/login",
    role: "auth",
    component: <Login />,
  },
  {
    route: "/recuperar-contrasena",
    role: "auth",
    component: <ResetPwd />,
  },
  {
    route: "/logout",
    component: <Logout />,
  },
  //--------------professional routes----------------
  {
    route: "/profile",
    role:["superadmin", "admin", "user","subUser"],
    component: <Profile/>,
  },
  {
    route: "/center/:centerid",
    role:["superadmin", "admin", "user","subUser"],
    component: <Center/>,
  },
  {
    route: "/clients",
    role:["superadmin", "admin", "user","subUser"],
    component: <Client/>,
  },
  {
    route: "/client/register/:clientid",
    role:["superadmin", "admin", "user","subUser"],
    component: <RegisterData/>,
  },
  {
    route: "/client/:clientid",
    role:["superadmin", "admin", "user","subUser"],
    component: <InfoClient/>,
  },
  {
    route: "/client/bill/:clientid",
    role:["superadmin", "admin", "user","subUser"],
    component: <Billclient/>,
  },
  {
    route: "/client/diary/:clientid",
    role:["superadmin", "admin", "user","subUser"],
    component: <Diary/>,
  },
  {
    route: "/client/chat/:clientid",
    role:["superadmin", "admin", "user","subUser"],
    component: <Chat/>,
  },
  {
    route: "/income",
    role:["superadmin", "admin", "user","subUser"],
    component: <Income/>,
  },
  {
    route: "/income/info",
    role:["superadmin", "admin", "user","subUser"],
    component: <IncomeInfo/>,
  },
  {
    route: "/collections",
    role:["superadmin", "admin", "user","subUser"],
    component: <Collections/>,
  },
  {
    route: "/",
    role:["superadmin", "admin", "user","subUser"],
    component:  <Calendar/>,
  },
  {
    route: "/calendar/info",
    role:["superadmin", "admin", "user","subUser"],
    component:  <DetailsAppointments/>,
  },
  {
    route: "/calendar/availability",
    role:["superadmin", "admin", "user","subUser"],
    component:  <DiaryAvailable/>,
  },
  {
    route: "/reports",
    role:["superadmin", "admin", "user","subUser"],
    component:  <Reports/>,
  },
  //--------------admin routes----------------
  {
    route: "/professionals",
    role: ["superadmin", "admin", "user","subUser"],
    component:  <ProfessionalOverview/>,
  },
  {
    route: "/professionals/query",
    role: ["superadmin", "admin", "user","subUser"],
    component:  <ProfessionalQuery/>,
  },
  {
    route: "/register/professional", 
    role:["superadmin", "admin", "user","subUser"],
    component:  <RegisterProfesional/>,
  },

  // ----------------public routes----------------
  {
    route: "/schedule/appointment/:id",
    role:"",
    component:  <ScheduleAppointment/>,
  },
];

export default routes;
