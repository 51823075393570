import { useState, useEffect } from "react";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Typography,
  Stack,
  MenuItem,
  TextField,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
import GlobalHeader from "../../components/header";
import { TapsHeaders } from "../../components/tapsHeaders";
import useSnack from "../../hooks/useSnack";
import useApi from "../../hooks/useApi";
import { fetchProfile } from "../ExternalChats.jsx/fetch";
import { fetchCenter } from "../diary/fetch";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { ModalAvailability } from "./diaryEdit";
import SnackAlert from "../../components/SnackAlert";
import { ModalDelete } from "../../components/modalDelete";
import {
  createDiaryAvailability,
  getDiaryAvailability,
  deleteDiaryAvailability,
} from "./fetch";
import { FaCopy } from "react-icons/fa";
import { parse, format } from "@formkit/tempo";
import moment from "moment-timezone";
const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

//moment.tz.setDefault(`${timeZone}`);

// Definir los días de la semana
const daysOfWeek = [
  "Domingo",
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sábado",
];

// Función para convertir horas locales a UTC y ajustar el día si cambia
function convertToUTCWithDayAdjustment(object, localTimeZone) {
  const { day, startHour, endHour } = object;

  // Obtener el índice del día en base al nombre del día
  const dayIndex = daysOfWeek.indexOf(day);

  // Fecha de referencia para el día original (no debería cambiar al usar esta referencia fija)
  let referenceDate = new Date();
  referenceDate.setUTCDate(
    referenceDate.getUTCDate() - referenceDate.getUTCDay() + dayIndex
  ); // Ajustar al día correcto

  // Formatear la fecha de referencia en formato YYYY-MM-DD
  const referenceDateString = referenceDate.toISOString().split("T")[0];

  // Combinar el día y hora de inicio con la zona horaria local
  const localStart = parse({
    date: `${referenceDateString}T${startHour}:00`,
    format: "YYYY-MM-DD HH:mm",
    tz: localTimeZone,
  });

  // Convertir la hora de inicio a UTC
  const utcStart = format({
    date: localStart,
    format: "YYYY-MM-DD HH:mm",
    tz: "UTC",
  });

  // Convertir la hora de finalización a UTC de manera similar
  const localEnd = parse({
    date: `${referenceDateString}T${endHour}:00`,
    format: "YYYY-MM-DD HH:mm",
    tz: localTimeZone,
  });

  const utcEnd = format({
    date: localEnd,
    format: "YYYY-MM-DD HH:mm",
    tz: "UTC",
  });

  // Revisar si el día ha cambiado al convertir la hora de inicio a UTC
  const utcStartDate = new Date(utcStart);
  const startDayIndex = utcStartDate.getUTCDay(); // Obtener el nuevo día en UTC

  // Ajustar el nombre del día si el día cambia
  let adjustedDay = day;
  if (startDayIndex !== dayIndex) {
    adjustedDay = daysOfWeek[startDayIndex];
  }

  // Devolver el objeto actualizado con las horas convertidas a UTC y el día ajustado
  return {
    ...object,
    startHour: utcStart.split(" ")[1], // Tomamos solo la hora en UTC
    endHour: utcEnd.split(" ")[1], // Tomamos solo la hora en UTC
    day: adjustedDay, // Actualizamos el nombre del día si es necesario
  };
}

function convertUTCToLocal(object, targetTimeZone) {
  const { day, startHour, endHour } = object;

  // Obtener el índice del día en base al nombre del día
  const dayIndex = daysOfWeek.indexOf(day);

  // Usamos una fecha fija arbitraria para asociarla con el día y la hora en UTC
  const referenceDate = new Date();
  referenceDate.setUTCDate(
    referenceDate.getUTCDate() - referenceDate.getUTCDay() + dayIndex
  );

  // Formatear la fecha de referencia en formato YYYY-MM-DD
  const referenceDateString = referenceDate.toISOString().split("T")[0];

  // Convertir la hora de inicio a la zona horaria local
  const localStart = format({
    date: `${referenceDateString}T${startHour}:00Z`,
    format: "YYYY-MM-DD HH:mm",
    tz: targetTimeZone, // Convertir a la zona horaria local
  });

  const localEnd = format({
    date: `${referenceDateString}T${endHour}:00Z`,
    format: "YYYY-MM-DD HH:mm",
    tz: targetTimeZone,
  });

  // Revisar si el día ha cambiado al convertir la hora de inicio a la zona horaria local
  const localStartDate = new Date(localStart);
  const localStartDayIndex = localStartDate.getDay(); // Obtener el nuevo día en la zona horaria local

  // Ajustar el nombre del día si el día cambia
  let adjustedDay = day;
  if (localStartDayIndex !== dayIndex) {
    adjustedDay = daysOfWeek[localStartDayIndex]; // Ajustar al día correspondiente en la zona horaria local
  }

  // Devolver el objeto actualizado con las horas convertidas a la hora local y el día ajustado
  return {
    ...object,
    startHour: localStart.split(" ")[1], // Tomamos solo la hora en la zona horaria local
    endHour: localEnd.split(" ")[1], // Tomamos solo la hora en la zona horaria local
    day: adjustedDay, // Actualizamos el nombre del día si es necesario
  };
}

export function DiaryAvailable() {
  const [photoProfile, setPhotoProfile] = useState(null);
  const [centers, setCenters] = useState([]);
  const [selectedCenter, setSelectedCenter] = useState({
    center: "",
    center_id: null,
  });
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedStartHour, setSelectedStartHour] = useState("");
  const [selectedEndHour, setSelectedEndHour] = useState("");
  const [availabilityData, setAvailabilityData] = useState([]);
  const [editingItem, setEditingItem] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);
  const [profesionalId, setProfesionalId] = useState(null);

  const { loadApi } = useApi();
  const { showSnack, msgSB, typeSB, openSB } = useSnack();
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const menu = [
    { label: "Disponibles", path: "/" },
    { label: "Agendadas", path: "/calendar/info" },
    { label: "Disponibilidad", path: "/calendar/availability" },
  ];

  const hoursOptions = Array.from({ length: 24 * 2 }, (_, index) => {
    const hour = Math.floor(index / 2)
      .toString()
      .padStart(2, "0");
    const minute = index % 2 === 0 ? "00" : "30";
    return { label: `${hour}:${minute}`, value: `${hour}:${minute}` };
  });
  const UserId = (setProfesionalId, showSnack, setOpenbackd, loadApi) => {
    setOpenbackd(true);
    loadApi("userinfo", true, "get")
      .then((response) => {
        setProfesionalId(response.data.data.id);
      })
      .catch((e) => {
        showSnack(e.message);
        setOpenbackd(false);
      })
      .finally((e) => {
        setOpenbackd(false);
      });
  };

  useEffect(() => {
    UserId(setProfesionalId, showSnack, setOpenBackdrop, loadApi);
    fetchProfile(setPhotoProfile, showSnack, setOpenBackdrop, loadApi);
    fetchCenter(setCenters, showSnack, setOpenBackdrop, loadApi);
    getDiaryAvailability(
      setAvailabilityData,
      loadApi,
      showSnack,
      convertUTCToLocal,
      timeZone
    );
  }, []);

  const handleRegister = () => {
    if (
      !selectedCenter.center ||
      !selectedDay ||
      !selectedStartHour ||
      !selectedEndHour
    ) {
      showSnack(
        "Por favor completa todos los campos antes de registrar.",
        "error"
      );
      return;
    }

    if (selectedStartHour >= selectedEndHour) {
      showSnack(
        "La hora de fin debe ser posterior a la hora de inicio.",
        "error"
      );
      return;
    }

    const hasConflict = availabilityData.some((item) => {
      return (
        item.day === selectedDay &&
        selectedStartHour < item.endHour &&
        selectedEndHour > item.startHour
      );
    });

    if (hasConflict) {
      showSnack(
        "Ya hay disponibilidad registrada para este día en un horario que se cruza. Por favor, selecciona otro horario.",
        "error"
      );
      return;
    }

    const newEntry = {
      id: Date.now(),
      center_id: selectedCenter.center_id,
      Center: {
        name: selectedCenter.center,
      },
      day: selectedDay,
      startHour: selectedStartHour,
      endHour: selectedEndHour,
    };

    const convertedObject = convertToUTCWithDayAdjustment(newEntry, timeZone);

    createDiaryAvailability(
      convertedObject,
      availabilityData,
      setAvailabilityData,
      loadApi,
      showSnack,
      setProfesionalId,
      newEntry
    );
    resetForm();
  };

  const resetForm = () => {
    setSelectedCenter({ center: "", center_id: 0 });
    setSelectedDay("");
    setSelectedStartHour("");
    setSelectedEndHour("");
  };

  const handleEdit = (item) => {
    setEditingItem(item);
    setModalOpen(true);
  };

  const handleSave = (updatedItem) => {
    setAvailabilityData(
      availabilityData.map((item) =>
        item.id === updatedItem.id ? updatedItem : item
      )
    );
    setModalOpen(false);
    showSnack("Disponibilidad actualizada con éxito.", "success");
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setEditingItem(null);
  };

  const handleDelete = (itemId) => {
    setIdToDelete(itemId);
    setOpenModalDelete(true);
  };

  const confirmDelete = () => {
    setOpenModalDelete(false);
    deleteDiaryAvailability(
      showSnack,
      loadApi,
      setAvailabilityData,
      availabilityData,
      idToDelete
    );
  };

  const handleCopyPath = () => {
    const doc = document.URL.split("/")[2];
    navigator.clipboard.writeText(
      doc + "/schedule/appointment/" + profesionalId
    );
    showSnack("Link copiado en portapapeles.", "success");
  };

  return (
    <>
      <GlobalHeader LogoProfile={photoProfile} />
      <Container
        maxWidth="lg"
        sx={{
          alignItems: "center",
          background: "#FFFFFF",
          p: 2,
          minHeight: 600,
        }}
      >
        <TapsHeaders
          menu={menu}
          clientid={0}
          namecomplete={"Agendar disponibilidad"}
        >
          <Box sx={{ marginTop: "22%" }}>
            <Button
              onClick={handleRegister}
              sx={{
                marginRight: "5px",
                bgcolor: "secondary.main",
                color: "primary.contrastText",
                boxShadow:
                  "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                ":hover": {
                  bgcolor: "#ebebeb",
                  boxShadow:
                    "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                },
              }}
            >
              <AddOutlinedIcon sx={{ marginInlineEnd: 1 }} />
              <Typography variant="body1" sx={{ fontWeight: 600 }}>
                Registrar
              </Typography>
            </Button>
            {availabilityData.length > 0 && (
              <Button
                onClick={handleCopyPath}
                sx={{
                  bgcolor: "secondary.main",
                  color: "primary.contrastText",
                  boxShadow:
                    "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                  ":hover": {
                    bgcolor: "#ebebeb",
                    boxShadow:
                      "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                  },
                }}
              >
                <FaCopy sx={{ marginInlineEnd: 1 }} />
                <Typography variant="body1" sx={{ fontWeight: 600 }}>
                  Copiar link
                </Typography>
              </Button>
            )}
          </Box>
        </TapsHeaders>

        <Stack spacing={2} direction="row" my={2}>
          <TextField
            select
            label="Centro"
            value={selectedCenter.center_id}
            onChange={(e) => {
              const center = centers.find(
                (center) => center.id === e.target.value
              );
              setSelectedCenter({
                center: center.name,
                center_id: center.id,
              });
            }}
            fullWidth
          >
            {centers.map((center) => (
              <MenuItem key={center.id} value={center.id}>
                {center.name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            label="Día"
            value={selectedDay}
            onChange={(e) => setSelectedDay(e.target.value)}
            fullWidth
          >
            {["Lunes", "Martes", "Miércoles", "Jueves", "Viernes"].map(
              (day) => (
                <MenuItem key={day} value={day}>
                  {day}
                </MenuItem>
              )
            )}
          </TextField>

          <TextField
            select
            label="Hora de Inicio"
            value={selectedStartHour}
            onChange={(e) => setSelectedStartHour(e.target.value)}
            fullWidth
          >
            {hoursOptions.map((hour) => (
              <MenuItem key={hour.value} value={hour.value}>
                {hour.label}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            label="Hora de Fin"
            value={selectedEndHour}
            onChange={(e) => setSelectedEndHour(e.target.value)}
            fullWidth
          >
            {hoursOptions.map((hour) => (
              <MenuItem key={hour.value} value={hour.value}>
                {hour.label}
              </MenuItem>
            ))}
          </TextField>
        </Stack>

        <Box marginTop={2}>
          {availabilityData.length > 0 ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Centro</TableCell>
                    <TableCell>Día</TableCell>
                    <TableCell>Hora de Inicio</TableCell>
                    <TableCell>Hora de Fin</TableCell>
                    <TableCell>Acciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {availabilityData.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell>{item?.Center?.name}</TableCell>
                      <TableCell>{item.day}</TableCell>
                      <TableCell>{item.startHour}</TableCell>
                      <TableCell>{item.endHour}</TableCell>
                      <TableCell>
                        <IconButton
                          aria-label="edit"
                          size="large"
                          title="Editar"
                          color="secondary"
                          sx={{
                            mx: 1,
                            bgcolor: "#aed6f1",
                            ":hover": {
                              bgcolor: "#C1CEE8!important",
                              boxShadow:
                                "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                            },
                          }}
                          onClick={() => handleEdit(item)}
                        >
                          <EditIcon />
                        </IconButton>

                        <IconButton
                          aria-label="delete"
                          title="Eliminar"
                          size="large"
                          color="secondary"
                          sx={{
                            bgcolor: "#f5b7b1",
                            ":hover": {
                              bgcolor: "#DEBFBF",
                              boxShadow:
                                "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                            },
                          }}
                          onClick={() => handleDelete(item.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>No hay disponibilidad registrada.</Typography>
          )}
        </Box>
      </Container>

      {modalOpen && (
        <ModalAvailability
          open={modalOpen}
          onClose={handleCloseModal}
          onSave={handleSave}
          initialData={editingItem}
          centers={centers}
          loadApi={loadApi}
          showSnack={showSnack}
        />
      )}

      <Backdrop
        open={openBackdrop}
        sx={{ zIndex: (theme) => theme.zIndex.modal }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <ModalDelete
        openModalDelete={openModalDelete}
        setDeleteBool={confirmDelete}
        title={"¿Está seguro de eliminar este registro?"}
        setOpenModalDelete={setOpenModalDelete}
      />

      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
    </>
  );
}
