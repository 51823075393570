import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import { updateSubUser, getModules } from "./fetch";
import useApi from "../../hooks/useApi";
import useSnack from "../../hooks/useSnack";

export function ModalEdit({
  loadApi,
  openModal,
  user,
  users,
  setUsers,
  showSnack,
  setOpenModal,
}) {
  const [formData, setFormData] = useState({
    name: "",
    lastname: "",
    username: "",
    document: "",
    status: "",
    modules: [],
  });

  const [availableModules, setAvailableModules] = useState([]);

  useEffect(() => {
    if (user) {
      setFormData({
        name: user.name,
        lastname: user.lastname,
        username: user.username,
        document: user.document,
        status: user.status || "",
        modules: user.modules.map((module) => ({
          module_id: module.id,
          can_read: module.can_read,
          can_write: module.can_write,
        })),
      });
    }
    getModules(loadApi, showSnack, setAvailableModules);
  }, [user]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));
  };

  const handleToggleModule = (moduleId, permission) => {
    setFormData((prev) => {
      const existingModule = prev.modules.find(
        (module) => module.module_id === moduleId
      );

      if (existingModule) {
        const updatedModules = prev.modules
          .map((module) =>
            module.module_id === moduleId
              ? {
                  ...module,
                  can_read:
                    permission === "read" ? !module.can_read : module.can_read,
                  can_write:
                    permission === "write"
                      ? !module.can_write
                      : module.can_write,
                }
              : module
          )
          .filter((module) => module.can_read || module.can_write); // Filtrar solo módulos con permisos seleccionados
        return { ...prev, modules: updatedModules };
      } else {
        return {
          ...prev,
          modules: [
            ...prev.modules,
            {
              module_id: moduleId,
              can_read: permission === "read",
              can_write: permission === "write",
            },
          ],
        };
      }
    });
  };

  const handleSave = () => {
    // Filtrar solo los módulos seleccionados con permisos
    const selectedModules = formData.modules.filter(
      (module) => module.can_read || module.can_write
    );

    const updatedUser = { ...user, ...formData, modules: selectedModules };
    updateSubUser(loadApi, showSnack, setUsers, updatedUser, users);
    handleClose();
  };

  const handleClose = () => {
    setOpenModal({
      state: false,
    });
  };

  const changeStatus = (e) => {
    setFormData((prev) => ({ ...prev, status: e.target.value }));
  };

  return (
    <Dialog
      open={openModal?.state}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle textAlign="center">
        Editar información de {user?.name} {user?.lastname}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2} mt={2}>
          <TextField
            id="name"
            label="Nombre"
            variant="outlined"
            fullWidth
            value={formData.name}
            onChange={handleChange}
          />
          <TextField
            id="lastname"
            label="Apellidos"
            variant="outlined"
            fullWidth
            value={formData.lastname}
            onChange={handleChange}
          />
          <TextField
            id="username"
            label="Correo Electrónico"
            variant="outlined"
            fullWidth
            value={formData.username}
            onChange={handleChange}
          />
          <TextField
            id="document"
            label="Número de Cédula"
            variant="outlined"
            fullWidth
            value={formData.document}
            onChange={handleChange}
          />
          <FormControl fullWidth>
            <InputLabel id="status-label">Estado</InputLabel>
            <Select
              labelId="status-label"
              id="status"
              value={formData.status || ""}
              onChange={changeStatus}
              label="Estado"
            >
              <MenuItem value="active">Activo</MenuItem>
              <MenuItem value="disable">Inactivo</MenuItem>
            </Select>
          </FormControl>

          <Typography
            variant="body1"
            sx={{ fontWeight: "medium", color: "black", mb: 1 }}
          >
            Seleccionar Módulos y Permisos
          </Typography>
          <Grid container spacing={2}>
            {availableModules.map((module) => (
              <Grid item key={module.id} xs={12} sm={6} md={4}>
                <Typography variant="subtitle2">{module.name}</Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        formData.modules.find((m) => m.module_id === module.id)
                          ?.can_read || false
                      }
                      onChange={() => handleToggleModule(module.id, "read")}
                    />
                  }
                  label="Lectura"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        formData.modules.find((m) => m.module_id === module.id)
                          ?.can_write || false
                      }
                      onChange={() => handleToggleModule(module.id, "write")}
                    />
                  }
                  label="Escritura"
                />
              </Grid>
            ))}
          </Grid>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cerrar
        </Button>
        <Button onClick={handleSave} color="primary">
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
