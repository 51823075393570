import { format, parse } from "@formkit/tempo";
const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
// consulta la foto del profesional
export function fetchProfile(
  setPhoto_profile,
  showSnack,
  setOpenbackd,
  loadApi
) {
  setOpenbackd(true);
  loadApi("profile", true, "get")
    .then((response) => {
      setPhoto_profile(response?.data?.photo_profile?.url);
    })
    .catch((e) => {
      showSnack(e.message);
      setOpenbackd(false);
    })
    .finally((e) => {
      setOpenbackd(false);
    });
}

export function fetchDiary(
  clientid,
  setRegistros,
  showSnack,
  setOpenbackd,
  loadApi
) {
  setOpenbackd(true);
  loadApi(`calendar/calendars_client/${clientid}`, true, "get")
    .then((response) => {
      const data = convertArrayDatesToLocal(response.data, timeZone);

      setRegistros(data);
    })
    .catch((e) => {
      showSnack(e.message);
      setOpenbackd(false);
    })
    .finally((e) => {
      setOpenbackd(false);
    });
}

export function DeleteDiary(
  id,
  showSnack,
  setOpenbackd,
  loadApi,
  setFilteredAppointments,
  filteredAppointments,
  setOpenModalCreateAppo,
  registros,
  setRegistros
) {
  setOpenbackd(true);
  loadApi(`calendar/delete/${id}`, true, "DELETE")
    .then((response) => {
      showSnack(response.data.message, "success");
      setFilteredAppointments(
        filteredAppointments.filter((item) => item.id !== id)
      );
      setRegistros(registros.filter((item) => item.id !== id));
      setOpenModalCreateAppo(false);
    })
    .catch((e) => {
      showSnack(e.message);
      setOpenbackd(false);
    })
    .finally((e) => {
      setOpenbackd(false);
    });
}

export function updateDiary(
  mainDataEdit,
  showSnack,
  setOpenbackd,
  loadApi,
  setOpenModalEditAppo,
  setRegistros,
  registros
) {
  setOpenbackd(true);
  loadApi(
    `calendar/update_calendar/${mainDataEdit?.id}`,
    true,
    "PUT",
    mainDataEdit
  )
    .then((response) => {
      const rta = convertUTCToLocal(mainDataEdit, timeZone);

      showSnack(response.data.message, "success");
      const updatedRegistros = registros.map((reg) =>
        reg.id === mainDataEdit.id ? rta : reg
      );

      setRegistros(updatedRegistros);
      setOpenModalEditAppo(false);
    })
    .catch((e) => {
      showSnack(e.message);
      setOpenModalEditAppo(false);
      setOpenbackd(false);
    })
    .finally(() => {
      setOpenbackd(false);
    });
}
function convertUTCToLocal(appointment, timeZone) {
  // Combinar la fecha y la hora UTC para `startAt`
  const utcStartAt = `${appointment.date}T${appointment.startAt}:00.0Z`; // Formato UTC

  const localStart = format({
    date: utcStartAt,
    format: "YYYY-MM-DD HH:mm",
    tz: timeZone, // Convertir a la zona horaria local
  });

  // Combinar la fecha y la hora UTC para `endAt`
  const utcEndAt = `${appointment.date}T${appointment.endAt}:00.0Z`; // Formato UTC

  const localEnd = format({
    date: utcEndAt,
    format: "YYYY-MM-DD HH:mm",
    tz: timeZone, // Convertir a la zona horaria local
  });

  // Extraer la nueva fecha y horas locales
  const newDate = localStart.split(" ")[0];
  const startHour = localStart.split(" ")[1];
  const endHour = localEnd.split(" ")[1];

  // Retornar el objeto actualizado con horas locales
  return {
    ...appointment,
    date: newDate, // Actualiza la fecha si es necesario
    startAt: startHour,
    endAt: endHour,
  };
}
export function CreatDiary(
  mainDataCreate,
  showSnack,
  setOpenbackd,
  loadApi,
  setOpenModalCreateAppo,
  setRegistros,
  registros,
  centers
) {
  setOpenbackd(true);
  loadApi("calendar", true, "post", mainDataCreate)
    .then((response) => {
      showSnack(response.data.message, "success");
      const newRegister = {
        id: response?.data?.transformedCalendar?.id,
        date: mainDataCreate.date,
        note: mainDataCreate.note,
        center_id: mainDataCreate.center_id,
        center_name: centers.find(
          (center) => center.id === mainDataCreate.center_id
        )?.name,
        client_id: response?.data?.transformedCalendar?.client_id,
        client_name: response?.data?.transformedCalendar?.client_name,
        client_lastname: response?.data?.transformedCalendar?.client_lastname,
        client_national_id: mainDataCreate.client_national_id,
        client_phone: mainDataCreate.client_phone,
        startAt: mainDataCreate.startAt,
        endAt: mainDataCreate.endAt,
      };

      const formattNewR = convertUTCToLocal(newRegister, timeZone);
      setRegistros([...registros, formattNewR]);
      setOpenModalCreateAppo(false);
    })
    .catch((e) => {
      showSnack(e.message);
      setOpenbackd(false);
      setOpenModalCreateAppo(false);
    })
    .finally(() => {
      setOpenbackd(false);
    });
}

export function fetchCenter(setCenters, showSnack, setOpenbackd, loadApi) {
  loadApi("center/centers", true, "get")
    .then((response) => {
      setCenters(response.data);
      setOpenbackd(false);
    })
    .catch((e) => {
      showSnack(e.message);
      setOpenbackd(false);
    });
}

export function fetchClient(
  setNamecomplete,
  showSnack,
  setOpenbackd,
  loadApi,
  clientid
) {
  setOpenbackd(true);
  loadApi(`client/client/${clientid}`, true, "get")
    .then((response) => {
      setNamecomplete(response.data);
    })

    .catch((e) => {
      showSnack(e.message);
      if (e.message) {
        setTimeout(() => {
          window.location.href = "/clients";
        }, 800);
      }
      setOpenbackd(true);
    })
    .finally((e) => {
      setOpenbackd(false);
    });
}

function convertArrayDatesToLocal(array, timeZone) {
  return array.map((item) => {
    // Convertir el startAt de UTC a la zona horaria local

    const localStart = format({
      date: `${item.date}T${item.startAt}:00.0Z`,
      format: "YYYY-MM-DD HH:mm",
      tz: timeZone, // Convertir a la zona horaria local del usuario
    });

    const localEnd = format({
      date: `${item.date}T${item.endAt}:00.0Z`,
      format: "YYYY-MM-DD HH:mm",
      tz: timeZone, // Convertir a la zona horaria local del usuario
    });

    // Extraer la nueva fecha y horas locales
    const newDate = localStart.split(" ")[0];
    const newStartAt = localStart.split(" ")[1];
    const newEndAt = localEnd.split(" ")[1];

    // Retornar el objeto con las fechas y horas actualizadas
    return {
      ...item,
      date: newDate,
      startAt: newStartAt,
      endAt: newEndAt,
    };
  });
}
