import { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  List,
  ListItem,
  Box,
  Avatar,
  ListItemAvatar,
  Stack,
  Container,
  Tooltip,
  Divider,
  MenuItem,
  Menu,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import headerOptions from "../utils/headerOptions";
import { useAuth } from "../hooks/useAuth";
import Logo from "../images/Logo.svg";
import profile from "../images/perfil.svg";
import useApi from "../hooks/useApi";
import useSnack from "../hooks/useSnack";
import { fetchOccupation } from "../utils/profesion";

function GlobalHeader({ LogoProfile, previewImage }) {
  const { loadApi } = useApi();
  const { msgSB, openSB, showSnack, typeSB } = useSnack();
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [occupation, setOccupation] = useState("");
  const [paths, setPaths] = useState([]); // Estado para las rutas obtenidas
  const { loggedAuth } = useAuth();

  function fetchPath() {
    loadApi("modules/mymodules", true, "get")
      .then((response) => {
        setPaths(response.data); // Actualiza el estado con las rutas obtenidas
      })
      .catch((e) => {
        showSnack(e.message);
        setOccupation("Clientes");
      });
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const [options, setOptions] = useState(
    loggedAuth ? headerOptions.logged : headerOptions.default
  );

  const slicedOptions = options.slice(0, options.length - 1);

  useEffect(() => {
    setOptions(!!loggedAuth ? headerOptions.logged : headerOptions.default);
    if (loggedAuth) {
      fetchOccupation(loadApi, showSnack, setOccupation);
      fetchPath();
    }
  }, [loggedAuth]);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const displayedRoutes = paths.length > 0 ? paths : options;

  return (
    <>
      <AppBar id="GlobalHeader" position="static">
        <Container
          sx={{
            py: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: { md: "space-between", xs: "start" },
            maxWidth: "100%",
          }}
        >
          <Stack direction="row" spacing={2}>
            {!!loggedAuth && (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{
                  color: "#fff",
                  display: {
                    xs: "inherit",
                    lg: "none",
                  },
                }}
                onClick={toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Box component={Link} to="/">
              <img src={Logo} width={200} height="100%" />
            </Box>
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            width="100%"
          >
            {paths.map((item, i) => (
              <Link key={i} to={item.path}>
                <ListItem>
                  <Box
                    spacing={1}
                    sx={{
                      display: {
                        xs: "none",
                        md: "none",
                        lg: "inherit",
                        xl: "inherit",
                      },
                      borderBottom: "3px solid",
                      alignItems: "center",
                      borderColor:
                        window.location.pathname === item.path
                          ? "#fff"
                          : "secondary.main",
                      width: "100%",
                      pb: 1,
                      svg: {
                        color: "#fff!important",
                      },
                    }}
                  >
                    <Typography color="white">
                      {item.name === "Pacientes" ? occupation : item.name}
                    </Typography>
                  </Box>
                </ListItem>
              </Link>
            ))}

            {paths.length === 0 && (
              <>
                {slicedOptions.map((item, i) => (
                  <Link key={i} to={item.route}>
                    <ListItem>
                      <Box
                        spacing={1}
                        sx={{
                          display: {
                            xs: "none",
                            md: "none",
                            lg: "inherit",
                            xl: "inherit",
                          },
                          borderBottom: "3px solid",
                          alignItems: "center",
                          borderColor:
                            window.location.pathname === item.route
                              ? "#fff"
                              : "secondary.main",
                          width: "100%",
                          pb: 1,
                          svg: {
                            color: "#fff!important",
                          },
                        }}
                      >
                        <Typography color="white">
                          {item.title === "Pacientes" ? occupation : item.title}
                        </Typography>
                      </Box>
                    </ListItem>
                  </Link>
                ))}
              </>
            )}
          </Stack>

          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            sx={{
              display: {
                xs: "none",
                lg: "flex",
              },
            }}
          >
            <Tooltip>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar src={previewImage || LogoProfile || profile} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ overflow: "hidden", mt: "45px", position: "absolute" }}
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={handleCloseUserMenu}>
                <Box component={Link} to="/profile">
                  <Typography>Mi perfil</Typography>
                </Box>
              </MenuItem>

              <Divider />

              {paths.length === 0 && (
                <>
                  <MenuItem>
                    <Box component={Link} to="/register/professional">
                      <Typography>Administrar usuarios</Typography>
                    </Box>
                  </MenuItem>
                  <Divider />
                </>
              )}

              <MenuItem onClick={handleCloseUserMenu}>
                {options.map((row, key) => {
                  if (row.route !== "/logout") return undefined;

                  return (
                    <Box
                      key={key}
                      component={Link}
                      to={row.route}
                      display="flex"
                      gap="3px"
                      sx={{
                        cursor:
                          window.location.pathname === row.route
                            ? "default"
                            : "pointer",
                      }}
                    >
                      <row.icon />
                      <Typography>{row.title}</Typography>
                    </Box>
                  );
                })}
              </MenuItem>
            </Menu>
          </Stack>
        </Container>
      </AppBar>



      <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <div
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <Box bgcolor="black" px={2} height="100vh" overflow="hidden">
            <List sx={{ height: "100%", p: 0, pt: 2 }}>
              <Stack justifyContent="space-between" sx={{ height: "100%" }}>
                <Box>
                  <ListItem sx={{ my: 1 }}>
                    <ListItemAvatar>
                      <Avatar
                        src={Logo}
                        sx={{
                          width: "180px",
                          height: "auto",
                          borderRadius: 0,
                        }}
                      />
                    </ListItemAvatar>
                  </ListItem>
                  {displayedRoutes.map((item, i) => (
                    <Link
                      key={i}
                      to={item.path || item.route}
                      style={{ textDecoration: "none" }}
                    >
                      <ListItem button>
                        <Stack
                          spacing={1}
                          direction="row"
                          sx={{
                            borderBottom: "3px solid",
                            borderColor:
                              window.location.pathname ===
                              (item.path || item.route)
                                ? "#fff"
                                : "secondary.main",
                            width: "100%",
                            py: 1,
                            alignItems: "center",
                            color: "white",
                            backgroundColor:
                              window.location.pathname ===
                              (item.path || item.route)
                                ? "rgba(255, 255, 255, 0.1)"
                                : "transparent",
                            "&:hover": {
                              backgroundColor: "rgba(255, 255, 255, 0.2)",
                            },
                          }}
                        >
                          {item.icon && <item.icon sx={{ color: "white" }} />}
                          <Typography color="white">
                            {item?.title}
                            {item?.name}
                          </Typography>
                        </Stack>
                      </ListItem>
                    </Link>
                  ))}
                </Box>
              </Stack>
            </List>
          </Box>
        </div>
      </Drawer>

    </>
  );
}

export default GlobalHeader;
