import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
  Divider,
  Stack,
  MenuItem,
  FormControl,
  Select,
  TextField,
  Autocomplete,
  Alert,
  IconButton,
} from "@mui/material";
import dayjs from "dayjs";
import DeleteIcon from "@mui/icons-material/Delete";
import GlobalHeader from "../../components/header";
import { useEffect, useState } from "react";
import useSnack from "../../hooks/useSnack";
import SnackAlert from "../../components/SnackAlert";
import useApi from "../../hooks/useApi";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { Link, useNavigate } from "react-router-dom";
import capitalizarCadena from "../../utils/Cadena";
import DeleteAppointment from "../calendar/modaldelete";
import DateCusmton from "../../utils/DateCusmton";
import ModalupdateAppo from "./modalUpdateAppo";
import { getToday } from "../../utils/DateCusmton";
import { TapsHeaders } from "../../components/tapsHeaders";
import { fetchOccupation } from "../../utils/profesion";
import { format, parse } from "@formkit/tempo";
const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

function convertArrayDatesToLocal(array, timeZone) {
  return array.map((item) => {
    // Convertir el startAt de UTC a la zona horaria local

    const localStart = format({
      date: `${item.date}T${item.start}:00.0Z`,
      format: "YYYY-MM-DD HH:mm",
      tz: timeZone, // Convertir a la zona horaria local del usuario
    });

    const localEnd = format({
      date: `${item.date}T${item.end}:00.0Z`,
      format: "YYYY-MM-DD HH:mm",
      tz: timeZone, // Convertir a la zona horaria local del usuario
    });

    // Extraer la nueva fecha y horas locales
    const newDate = localStart.split(" ")[0];
    const newStartAt = localStart.split(" ")[1];
    const newEndAt = localEnd.split(" ")[1];

    // Retornar el objeto con las fechas y horas actualizadas
    return {
      ...item,
      date: newDate,
      start: newStartAt,
      end: newEndAt,
    };
  });
}
function convertLocalToUTC(appointment, timeZone) {
  // Combinar la fecha y la hora local para `startAt`
  const localStartAt = `${appointment.date} ${appointment.startAt}`;

  // Convertir el startAt de la zona local a UTC
  const localStart = parse({
    date: localStartAt,
    format: "YYYY-MM-DD HH:mm",
    tz: timeZone, // La zona horaria local del usuario
  });

  const utcStart = format({
    date: localStart,
    format: "YYYY-MM-DD HH:mm",
    tz: "UTC", // Convertir a UTC
  });

  // Combinar la fecha y la hora local para `endAt`
  const localEndAt = `${appointment.date} ${appointment.endAt}`;

  // Convertir el endAt de la zona local a UTC
  const localEnd = parse({
    date: localEndAt,
    format: "YYYY-MM-DD HH:mm",
    tz: timeZone, // La zona horaria local del usuario
  });

  const utcEnd = format({
    date: localEnd,
    format: "YYYY-MM-DD HH:mm",
    tz: "UTC", // Convertir a UTC
  });

  // Extraer la fecha de UTC para actualizar si es necesario
  const newDate = utcStart.split(" ")[0];
  const startHour = utcStart.split(" ")[1];
  const endHour = utcEnd.split(" ")[1];

  // Retornar el objeto actualizado con las horas en UTC
  return {
    ...appointment,
    date: newDate, // Actualiza la fecha en UTC si es necesario
    startAt: startHour,
    endAt: endHour,
  };
}
function convertUTCToLocal(appointment, timeZone) {
  // Combinar la fecha y la hora UTC para `startAt`
  const utcStartAt = `${appointment.date}T${appointment.start}:00.0Z`; // Formato UTC

  const localStart = format({
    date: utcStartAt,
    format: "YYYY-MM-DD HH:mm",
    tz: timeZone, // Convertir a la zona horaria local
  });

  // Combinar la fecha y la hora UTC para `endAt`
  const utcEndAt = `${appointment.date}T${appointment.end}:00.0Z`; // Formato UTC

  const localEnd = format({
    date: utcEndAt,
    format: "YYYY-MM-DD HH:mm",
    tz: timeZone, // Convertir a la zona horaria local
  });

  // Extraer la nueva fecha y horas locales
  const newDate = localStart.split(" ")[0];
  const startHour = localStart.split(" ")[1];
  const endHour = localEnd.split(" ")[1];

  // Retornar el objeto actualizado con horas locales
  return {
    ...appointment,
    date: newDate, // Actualiza la fecha si es necesario
    start: startHour,
    end: endHour,
  };
}
export default function DetailsAppointments() {
  const [mappedOptions, setMappedOptions] = useState([]);
  const [eventsFromApi, setEventsFromApi] = useState([]);
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [openModalInfoAppo, setOpenModalInfoAppo] = useState(false); // modal update
  const [openModaldelete, setOpenModaldelete] = useState(false); // eliminar
  const [occupation, setOccupation] = useState(""); //
  const [idAppointments, setIdAppointments] = useState();
  const [photo_profile, setPhoto_profile] = useState();
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();
  const { loadApi, loadingApi } = useApi();
  const { msgSB, openSB, showSnack, typeSB } = useSnack();
  const [openbackd, setOpenbackd] = useState(false);

  const [filters, setFilters] = useState({
    centerId: "",
    clientId: "",
    month: "",
  });
  useEffect(() => {
    setOpenbackd(true);
    loadApi("profile", true, "get")
      .then((response) => {
        setPhoto_profile(response.data.photo_profile.url);
      })
      .catch((e) => {
        showSnack(e.message);
        setOpenbackd(false);
      })
      .finally((e) => {
        setOpenbackd(false);
      });
    fetchOccupation(loadApi, showSnack, setOccupation);
  }, []);
  const [centers, setCenters] = useState();
  const [clients, setClients] = useState([]);
  useEffect(() => {
    loadApi("center/centers", true, "get")
      .then((response) => {
        setCenters(response.data);
      })
      .catch((e) => {
        showSnack(e.message);
        setOpenbackd(false);
      });
  }, []);
  useEffect(() => {
    setOpenbackd(true);
    loadApi("calendar/calendars", true, "get")
      .then((response) => {
        const rtaFormat = convertArrayDatesToLocal(response.data, timeZone);

        setEventsFromApi(rtaFormat);
      })
      .catch((e) => {
        showSnack(e.message);
        setOpenbackd(false);
      })
      .finally((e) => {
        setOpenbackd(false);
      });
  }, []);
  useEffect(() => {
    loadApi("client/clients", true, "get")
      .then((response) => {
        setClients(response.data);
      })
      .catch((e) => {
        showSnack(e.message);
        setOpenbackd(false);
      });
  }, []);

  useEffect(() => {
    const options = (clients || []).map((item) => ({
      label: `${item.name} ${item.lastname}`,
      value: item.id,
    }));
    options.unshift({ label: `Todos los ${occupation}`, value: "" });
    setMappedOptions(options);
  }, [clients]);

  useEffect(() => {
    const filteredAppointments = eventsFromApi.filter((appointment) => {
      // Filtrar por center_id
      if (
        filters.centerId &&
        appointment.center_id !== parseInt(filters.centerId)
      ) {
        return false;
      }

      // Filtrar por client_id
      if (
        filters.clientId &&
        appointment.client_id !== parseInt(filters.clientId)
      ) {
        return false;
      }

      // Filtrar por mes de la fecha
      if (filters.month) {
        const month = parseInt(filters.month);
        const appointmentDate = new Date(appointment.date);
        if (appointmentDate.getMonth() + 1 !== month) {
          return false;
        }
      }

      // Si pasa todos los filtros, retornar verdadero
      return true;
    });

    // Actualizar los datos filtrados en el estado
    setFilteredAppointments(filteredAppointments);
  }, [eventsFromApi, filters]);

  useEffect(() => {
    // Verificar si hay datos en eventsFromApi
    if (eventsFromApi.length > 0) {
      // Mapear sobre los eventos y convertir las cadenas de fecha en objetos Date
      const updatedEvents = (eventsFromApi || []).map((event) => ({
        ...event,
        start: dateutc(event.date + "T" + event.start + ":00.000Z"),
        end: dateutc(event.date + "T" + event.end + ":00.000Z"),
      }));

      // Actualizar el estado de events con los eventos actualizados

      setEvents(updatedEvents);
    }
  }, [eventsFromApi]);

  const today = getToday();
  const filteredData = filteredAppointments.filter(
    (item) => item.date >= today
  );
  const handleCloseModalInfoAppo = () => {
    setOpenModalInfoAppo(false);
    setError({
      center_id: false,
      client_id: false,
      date: false,
      startAt: false,
      endAt: false,
      phone: false,
      note: false,
    });
  };
  const [mainData, setMainData] = useState({
    center_id: "",
    client_id: "",
    date: "",
    startAt: "",
    endAt: "",
    phone: "",
    note: "",
  });
  const [Error, setError] = useState({
    center_id: false,
    client_id: false,
    date: false,
    startAt: false,
    endAt: false,
    phone: false,
    note: false,
  });
  const deleteAppoint = (id) => {
    setOpenbackd(true);
    loadApi(`calendar/delete/${id}`, true, "delete")
      .then((response) => {
        showSnack(response.data.message, "success", 3000);
        //eliminar por id del registro
        setEventsFromApi((adata) => adata.filter((aitem) => aitem.id !== id));
      })
      .catch((e) => {
        showSnack(e.message);
        setOpenbackd(false);
      })
      .finally((e) => {
        setOpenbackd(false);
      });
  };
  const SubmitmodalUpdateAppo = (e) => {
    e.preventDefault();

    if (!Validate(mainData, setError, showSnack)) return;

    if (!isAppointmentOverlapping(mainData, events, idAppointments)) {
      handleCloseModalInfoAppo();
      setOpenbackd(true);

      const send = convertLocalToUTC(mainData, timeZone);
      loadApi(`calendar/update_calendar/${idAppointments}`, true, "put", {
        ...send,
      })
        .then((response) => {
          showSnack(response.data.message, "success", 2000);
          setEventsFromApi((adata) =>
            adata.filter((aitem) => aitem.id !== idAppointments)
          );
          const rta = convertUTCToLocal(
            response.data.transformedCalendar,
            timeZone
          );
          setEventsFromApi((adata) => {
            const newE = [...adata, rta];

            return newE;
          });
        })
        .catch((e) => {
          showSnack(e.message);
          setOpenbackd(false);
        })
        .finally((e) => {
          setOpenbackd(false);
        });
    } else {
      showSnack(
        "El nuevo ingreso se cruza con un ingreso existente",
        "error",
        3500
      );
    }
  };

  const menu = [
    { label: "Disponibles", path: `/` },
    { label: "Agendadas", path: `/calendar/info` },
    { label: "Disponibilidad", path: `/calendar/availability` },
  ];

  return (
    <>
      <GlobalHeader LogoProfile={photo_profile} />
      <Container
        maxWidth="lg"
        sx={{
          alignItems: "center",
          background: "#FFFFFF",
          p: 2,
          minHeight: 600,
        }}
      >
        <TapsHeaders namecomplete="Citas Agendadas" menu={menu}></TapsHeaders>

        <Divider sx={{ my: 1 }} />
        <Grid container direction="row" marginTop={2} marginBottom={5}>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Stack direction="row" justifyContent="space-between">
              <Typography sx={{ m: 1 }}>Centro:</Typography>
              <FormControl
                sx={{ width: { xs: "70%", sm: "85%", lg: "100%" } }}
                required
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  sx={{ borderRadius: 2 }}
                  value={filters.centerId}
                  onChange={({ target }) =>
                    setFilters((adata) => ({
                      ...adata,
                      centerId: target.value,
                    }))
                  }
                  required
                  size="small"
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    Seleccione una centro...
                  </MenuItem>
                  {centers &&
                    (centers || []).map((row, index) => (
                      // recorrer centros row.id sera value y se mostrara row.name
                      <MenuItem value={row.id}>{row.name}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            sx={{ mt: { xs: 2, md: 0 } }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography sx={{ m: 1 }}>{occupation}:</Typography>
              <Autocomplete
                size="small"
                sx={{ width: { xs: "70%", sm: "85%", lg: "100%" } }}
                options={mappedOptions}
                getOptionLabel={(option) => option.label}
                value={
                  mappedOptions.find(
                    (option) => option.value === filters.clientId
                  ) || null
                }
                onChange={(event, newValue) => {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    clientId: newValue ? newValue.value : "",
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{ borderRadius: 100 }}
                    {...params}
                    variant="outlined"
                  />
                )}
              />
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={2.5}
            lg={2.5}
            sx={{ mt: { xs: 2, md: 0 } }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography sx={{ m: 1 }}>Mes:</Typography>
              <FormControl
                sx={{ width: { xs: "70%", sm: "85%", lg: "100%" } }}
                required
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  sx={{ borderRadius: 2 }}
                  value={filters?.month}
                  onChange={({ target }) =>
                    setFilters((adata) => ({
                      ...adata,
                      month: target.value,
                    }))
                  }
                  required
                  size="small"
                  displayEmpty
                >
                  <MenuItem value="">Todos los meses</MenuItem>
                  <MenuItem value="01">Enero</MenuItem>
                  <MenuItem value="02">Febrero</MenuItem>
                  <MenuItem value="03">Marzo</MenuItem>
                  <MenuItem value="04">Abril</MenuItem>
                  <MenuItem value="05">Mayo</MenuItem>
                  <MenuItem value="06">Junio</MenuItem>
                  <MenuItem value="07">Julio</MenuItem>
                  <MenuItem value="08">Agosto</MenuItem>
                  <MenuItem value="09">Septiembre</MenuItem>
                  <MenuItem value="10">Octubre</MenuItem>
                  <MenuItem value="11">Noviembre</MenuItem>
                  <MenuItem value="12">Diciembre</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            lg={2}
            sx={{ mt: { xs: 2, md: 0 } }}
          >
            <Button
              fullWidth
              sx={{
                bgcolor: "secondary.main",
                color: "primary.contrastText",
                marginLeft: { sm: 0, md: 2, lg: 2 },
                boxShadow:
                  "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                ":hover": {
                  bgcolor: "#ebebeb",
                  boxShadow:
                    "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                },
              }}
              onClick={() => {
                setFilters({
                  centerId: "",
                  clientId: "",
                  month: "",
                });
              }}
            >
              Limpiar Filtros
            </Button>
          </Grid>
        </Grid>
        <Box
          sx={{
            border: "1px solid #e0e0e0",
            padding: 1,
            borderRadius: 1,
            my: 1,
            display: { xs: "none", sm: "none", md: "inherit" },
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              align="center"
              sx={{ px: "0!important" }}
            >
              <Typography variant="body1">Centro</Typography>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              align="center"
              sx={{ px: "0!important" }}
            >
              <Typography variant="body1">Fecha</Typography>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
            <Grid
              item
              xs={12}
              sm={12}
              md={1.5}
              lg={1.5}
              align="center"
              sx={{ px: "0!important" }}
            >
              <Box sx={{ maxWidth: "85%" }}>
                <Typography variant="body1">Hora Inicio- Hora Fin</Typography>
              </Box>
            </Grid>

            <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              align="center"
              sx={{ px: "0!important" }}
            >
              <Box sx={{ maxWidth: "90%" }}>
                <Typography variant="body1" noWrap>
                  Nota
                </Typography>
              </Box>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
            <Grid
              item
              xs={12}
              sm={12}
              md={2.2}
              lg={2.2}
              align="center"
              sx={{ px: "0!important" }}
            >
              <Typography variant="body1" noWrap>
                {occupation}
              </Typography>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
            <Grid
              item
              xs={12}
              sm={12}
              md={1.9}
              lg={1.9}
              align="center"
              sx={{ px: "0!important" }}
            >
              <Typography variant="body1" noWrap>
                Acciones
              </Typography>
            </Grid>
          </Grid>
        </Box>
        {filteredData?.length < 1 && (
          <Stack mt={2}>
            <Alert severity="warning">No hay citas agendadas.</Alert>
          </Stack>
        )}
        {filteredData &&
          (filteredData || [])?.map((row, index) => {
            return (
              <Box
                sx={{
                  border: "1px solid #e0e0e0",
                  padding: 2,
                  borderRadius: 1,
                  my: 1,
                }}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={2}
                    lg={2}
                    align="center"
                    sx={{ px: "0!important" }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ display: { md: "none" } }}
                    >
                      Centro
                    </Typography>
                    <Typography variant="body1" fontWeight={400}>
                      {capitalizarCadena(filteredData[index].center_name)}
                    </Typography>
                  </Grid>
                  <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={2}
                    lg={2}
                    align="center"
                    sx={{ px: "0!important" }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ display: { md: "none" } }}
                    >
                      Fecha
                    </Typography>
                    <Typography variant="body1" fontWeight={400}>
                      {DateCusmton(filteredData[index].date + "T00:00:00.000Z")}
                    </Typography>
                  </Grid>
                  <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={1.5}
                    lg={1.5}
                    align="center"
                    sx={{ px: "0!important" }}
                  >
                    <Box sx={{ maxWidth: "85%" }}>
                      <Typography
                        variant="body1"
                        sx={{ display: { md: "none" } }}
                      >
                        Hora Inicio - Hora Fin
                      </Typography>
                      <Typography variant="body1" fontWeight={400}>
                        {filteredData[index].start} {" - "}
                        {filteredData[index].end}
                      </Typography>
                    </Box>
                  </Grid>
                  <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={2}
                    lg={2}
                    align="center"
                    sx={{ px: "0!important" }}
                  >
                    <Box sx={{ maxWidth: "90%" }}>
                      <Typography
                        variant="body1"
                        sx={{ display: { md: "none" } }}
                      >
                        Nota
                      </Typography>
                      <Typography variant="body1" fontWeight={400} noWrap>
                        {filteredData[index].note || "sin notas"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
                  <Grid item xs={12} sm={12} md={2.2} lg={2.2} align="center">
                    <Typography
                      variant="body1"
                      sx={{ display: { md: "none" } }}
                    >
                      {occupation}
                    </Typography>
                    <Typography variant="body1" noWrap fontWeight={400}>
                      {capitalizarCadena(
                        !filteredData[index].client_name &&
                          !filteredData[index].client_lastname
                          ? `sin ${occupation}`
                          : `${filteredData[index].client_name} ${filteredData[index].client_lastname}`
                      )}
                    </Typography>
                  </Grid>
                  <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />

                  <Grid item xs={12} sm={12} md={1.9} lg={1.9} align="center">
                    <IconButton
                      aria-label="edit"
                      size="large"
                      title="Editar"
                      color="secondary"
                      sx={{
                        mx: 1,
                        bgcolor: "#aed6f1",
                        ":hover": {
                          bgcolor: "#C1CEE8!important",
                          boxShadow:
                            "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                        },
                      }}
                      onClick={() => {
                        const st = dateutc(
                          row.date + "T" + row.start + ":00.000Z"
                        );
                        const dateTimeStart = dayjs(st);
                        const ed = dateutc(
                          row.date + "T" + row.end + ":00.000Z"
                        );

                        // Extrae y formatea solo la hora
                        const timeStringStart = dateTimeStart.format("HH:mm");

                        //------------------------------------
                        const dateTimeEnd = dayjs(ed);
                        // Extrae y formatea solo la hora
                        const timeStringEnd = dateTimeEnd.format("HH:mm");
                        setIdAppointments(row.id);
                        setOpenModalInfoAppo({
                          fullname:
                            !row.client_name && !row.client_lastname
                              ? `Sin ${occupation ? occupation : "Cliente"}`
                              : `${row.client_name} ${row.client_lastname}`,
                          center_id: row.center_id,
                          client_id: row.client_id,
                          date: row.date,
                          start: timeStringStart,
                          end: timeStringEnd,
                          phone: row.client_phone,
                          note: row.note,
                        });
                      }}
                    >
                      <CreateOutlinedIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      title="Eliminar"
                      size="large"
                      color="secondary"
                      sx={{
                        bgcolor: "#f5b7b1",
                        ":hover": {
                          bgcolor: "#DEBFBF",
                          boxShadow:
                            "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                        },
                      }}
                      onClick={() => {
                        setOpenModaldelete(row.id);
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>
            );
          })}
      </Container>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal + 1 }}
        open={openbackd}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <ModalupdateAppo
        openModalInfoAppo={openModalInfoAppo}
        handleCloseModalInfoAppo={handleCloseModalInfoAppo}
        setMainData={setMainData}
        mainData={mainData}
        centers={centers}
        SubmitmodalUpdateAppo={SubmitmodalUpdateAppo}
        idAppointments={idAppointments}
        Error={Error}
      />
      <DeleteAppointment
        openModaldelete={openModaldelete}
        setOpenModaldelete={setOpenModaldelete}
        deleteAppoint={deleteAppoint}
        setOpenModalInfoAppo={setOpenModalInfoAppo}
      />
    </>
  );
}
/* function isAppointmentOverlapping(
  newAppointment,
  appointments,
  editingAppointmentId
) {
  const newStart = dateutc(
    newAppointment.date + "T" + newAppointment.startAt + ":00.000Z"
  );

  const newEnd = dateutc(
    newAppointment.date + "T" + newAppointment.endAt + ":00.000Z"
  );

  return appointments.some((appointment) => {
    if (appointment.id === editingAppointmentId) {
      return false;
    }
    if (appointment.date !== newAppointment.date) {
      return false;
    }
    const existingStart = appointment.start;
    const existingEnd = appointment.end;

    return newStart < existingEnd && newEnd > existingStart;
  });
} */

function isAppointmentOverlapping(
  newAppointment,
  appointments,
  editingAppointmentId
) {
  const newStart = new Date(
    `${newAppointment.date} ${newAppointment.startAt}:00`
  );

  const newEnd = new Date(`${newAppointment.date} ${newAppointment.endAt}:00`);

  return appointments.some((appointment) => {
    if (appointment.id === editingAppointmentId) {
      return false;
    }
    if (appointment.date !== newAppointment.date) {
      return false;
    }
    // Convertir las fechas existentes en objetos Date (ya están en UTC)
    const existingStart = new Date(appointment.start); // Ya está en UTC
    const existingEnd = new Date(appointment.end); // Ya está en UTC

    return newStart < existingEnd && newEnd > existingStart;
  });
}
function dateutc(fecha_hora_str) {
  // Extraemos los componentes de fecha y hora de la cadena
  const year = parseInt(fecha_hora_str.substring(0, 4)); // Año
  const month = parseInt(fecha_hora_str.substring(5, 7)) - 1; // Mes (restamos 1 porque los meses en JavaScript van de 0 a 11)
  const day = parseInt(fecha_hora_str.substring(8, 10)); // Día
  const hour = parseInt(fecha_hora_str.substring(11, 13)); // Hora
  const minute = parseInt(fecha_hora_str.substring(14, 16)); // Minuto
  const second = parseInt(fecha_hora_str.substring(17, 19)); // Segundo

  // Creamos un objeto Date utilizando los componentes de fecha y hora en UTC
  const fecha_hora_utc = new Date(
    Date.UTC(year, month, day, hour, minute, second)
  );

  // Establecemos la zona horaria a UTC (GMT)
  fecha_hora_utc.setHours(hour, minute, second);

  return fecha_hora_utc;
}
function Validate(mainData, setError, showSnack) {
  var final = false;

  // Create an initial error object with boolean values
  const newAdata = {
    center_id: !mainData.center_id,

    date: !mainData.date,
    startAt: !mainData.startAt,
    endAt: !mainData.endAt,
  };

  // Add additional validation for startAt and endAt
  if (mainData.startAt && mainData.endAt) {
    const startAt = new Date(`${mainData.date}T${mainData.startAt}:00Z`);
    const endAt = new Date(`${mainData.date}T${mainData.endAt}:00Z`);
    if (startAt >= endAt) {
      newAdata.startAt = true;
      newAdata.endAt = true;
      showSnack(
        "La hora de inicio debe ser menor que la hora de fin.",
        "error",
        3000
      );
    }
  }

  setError(newAdata);

  for (let key in newAdata) {
    if (!!newAdata[key]) {
      final = true;
      break;
    }
  }

  return !final;
}
