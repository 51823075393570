export function calculateStatusTotal  (setStatusTotal,status)  {
    const newStatusTotal = {
      Pendiente: { cantidad: 0, valor: 0 },
      Cobrado: { cantidad: 0, valor: 0 },
      Atrasado: { cantidad: 0, valor: 0 },
    };

    Object.values(status).forEach((monthStatus) => {
      Object.keys(monthStatus).forEach((key) => {
        newStatusTotal[key].cantidad += monthStatus[key].cantidad;
        newStatusTotal[key].valor += monthStatus[key].valor;
      });
    });

    setStatusTotal({ total: newStatusTotal });
  };