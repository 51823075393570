import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Divider,
  Stack,
  Alert,
  IconButton,
} from "@mui/material";
import GlobalHeader from "../../components/header";
import { useEffect, useState } from "react";
import useSnack from "../../hooks/useSnack";
import useApi from "../../hooks/useApi";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import BackClients from "../info_client/back";
import { TapsHeaders } from "../../components/tapsHeaders";
import { useNavigate } from "react-router-dom";
import capitalizarCadena from "../../utils/Cadena";
import DateCusmton from "../../utils/DateCusmton";
import { fetchDiary, fetchCenter, DeleteDiary,fetchProfile,fetchClient} from "./fetch";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import ModalDiary from "./modalCreate";
import EditModalDiary from "./editDiary";
import SnackAlert from "../../components/SnackAlert";
import { ModalDelete } from "../../components/modalDelete";

export function Diary() {
  const { clientid } = useParams();
  const navigate = useNavigate();
  const { loadApi } = useApi();
  const { showSnack, msgSB, openSB, typeSB } = useSnack();
  const [openbackd, setOpenbackd] = useState(false);
  const [registros, setRegistros] = useState([]);
  const [openModalCreateAppo, setOpenModalCreateAppo] = useState(false);
  const [centers, setCenters] = useState([]);
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [openModalEditAppo, setOpenModalEditAppo] = useState(false);
  const [namecomplete, setNamecomplete] = useState({});
  const [openModalDelete, setOpenModalDelete] = useState({
    id: null,
    open: false,
  });
  const [deleteBool, setDeleteBool] = useState(false);

  const [mainDataEdit, setMainDataEdit] = useState({ 
    id:null,
    date: new Date().toISOString().split("T")[0], // Fecha actual por defecto
    note: "",
    center_id: "",
    center_name: "",
    client_id: clientid,
    client_name: "",
    client_lastname: "",
    client_national_id: "",
    client_phone: "",
    startAt: "",
    endAt: "",
  });

  const [mainDataCreate, setMainDataCreate] = useState({
      id:null,
      date: new Date().toISOString().split("T")[0], // Fecha actual por defecto
      note: "",
      center_id: "",
      center_name: "",
      client_id: clientid,
      client_name: "",
      client_lastname: "",
      client_national_id: "",
      client_phone: "",
      startAt: "",
      endAt: "",
  });

  // Añadido año a los filtros
  const [filters, setFilters] = useState({
    centerId: "",
    clientId: "",
    month: "",
    year: "",
  });

  useEffect(() => {
    fetchDiary(clientid, setRegistros, showSnack, setOpenbackd, loadApi);
    fetchCenter(setCenters, showSnack, setOpenbackd, loadApi);
    fetchClient(setNamecomplete, showSnack, setOpenbackd, loadApi,clientid);
  }, []);

  useEffect(() => {
    const filteredData = registros.filter((item) => {
      const itemDate = new Date(item.date);
      const currentMonth = itemDate.getMonth() + 1;
      const currentYear = itemDate.getFullYear();
      return (
        (!filters.centerId || item.center_id === parseInt(filters.centerId)) &&
        (!filters.month || currentMonth === parseInt(filters.month)) &&
        (!filters.year || currentYear === parseInt(filters.year))
      );
    });
    setFilteredAppointments(filteredData);
  }, [filters, registros, mainDataCreate]);

  const uniqueYears = [
    ...new Set(registros.map((item) => new Date(item.date).getFullYear())),
  ];

  const handleEdit = (id) => {
    const appointment = registros.find((item) => item.id === id);
    setMainDataEdit(appointment);
    setOpenModalEditAppo(true);
  }

  useEffect(() => {
    if (deleteBool){
      DeleteDiary(
        openModalDelete?.id,
        showSnack,
        setOpenbackd,
        loadApi,
        setFilteredAppointments,
        filteredAppointments,
        setOpenModalCreateAppo,
        registros,
        setRegistros
      );    
    }
    setDeleteBool(false);
    setOpenModalDelete({
      id: null,
      open: false,
    });
  }, [deleteBool]);

  const menu = [
    { label: "Datos", path: `/client/${clientid}` },
    { label: "Historial", path: `/client/register/${clientid}` },
    { label: "Agenda", path: `/client/diary/${clientid}` },
    { label: "Cobros", path: `/client/bill/${clientid}` },
    { label: "Comunicaciones", path: `/client/chat/${clientid}` },
  ]

  return (
    <>
      <GlobalHeader />
      <Container
        maxWidth="lg"
        sx={{
          alignItems: "center",
          background: "#FFFFFF",
          p: 2,
          minHeight: 600,
        }}
      >
        <BackClients />
        <TapsHeaders clientid={clientid} namecomplete={namecomplete?.name +" "+ namecomplete?.lastname} menu={menu}>
          <Button
              onClick={(e) => setOpenModalCreateAppo(true)}
              sx={{
                bgcolor: "secondary.main",
                color: "primary.contrastText",
                marginTop: "17%",
                boxShadow:
                  "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                ":hover": {
                  bgcolor: "#ebebeb",
                  boxShadow:
                    "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                },
              }}
            >
              <AddOutlinedIcon sx={{ marginInlineEnd: 1 }} />{" "}
              <Typography variant="p1" sx={{ fontWeight: 600, color: "black" }}>
              Nueva agenda
              </Typography>
            </Button>
        </TapsHeaders>

       
        <Divider sx={{ my: 1 }} />
        <Grid container direction="row" marginTop={2} marginBottom={5}>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Stack direction="row" justifyContent="space-between">
              <Typography sx={{ m: 1 }}>Centro:</Typography>
              <FormControl
                sx={{ width: { xs: "70%", sm: "85%", lg: "100%" } }}
                required
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  sx={{ borderRadius: 2 }}
                  value={filters.centerId}
                  onChange={({ target }) =>
                    setFilters((adata) => ({
                      ...adata,
                      centerId: target.value,
                    }))
                  }
                  required
                  size="small"
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    Seleccione un centro...
                  </MenuItem>
                  {centers &&
                    centers.map((row, index) => (
                      <MenuItem key={index} value={row.id}>
                        {row.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={2.5}
            lg={2.5}
            sx={{ mt: { xs: 2, md: 0 } }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography sx={{ m: 1 }}>Mes:</Typography>
              <FormControl
                sx={{ width: { xs: "70%", sm: "85%", lg: "100%" } }}
                required
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  sx={{ borderRadius: 2 }}
                  value={filters.month}
                  onChange={({ target }) =>
                    setFilters((adata) => ({
                      ...adata,
                      month: target.value,
                    }))
                  }
                  required
                  size="small"
                  displayEmpty
                >
                  <MenuItem value="">Todos los meses</MenuItem>
                  <MenuItem value="01">Enero</MenuItem>
                  <MenuItem value="02">Febrero</MenuItem>
                  <MenuItem value="03">Marzo</MenuItem>
                  <MenuItem value="04">Abril</MenuItem>
                  <MenuItem value="05">Mayo</MenuItem>
                  <MenuItem value="06">Junio</MenuItem>
                  <MenuItem value="07">Julio</MenuItem>
                  <MenuItem value="08">Agosto</MenuItem>
                  <MenuItem value="09">Septiembre</MenuItem>
                  <MenuItem value="10">Octubre</MenuItem>
                  <MenuItem value="11">Noviembre</MenuItem>
                  <MenuItem value="12">Diciembre</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={2.5}
            lg={2.5}
            sx={{ mt: { xs: 2, md: 0 } }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography sx={{ m: 1 }}>Año:</Typography>
              <FormControl
                sx={{ width: { xs: "70%", sm: "85%", lg: "100%" } }}
                required
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  sx={{ borderRadius: 2 }}
                  value={filters.year}
                  onChange={({ target }) =>
                    setFilters((adata) => ({
                      ...adata,
                      year: target.value,
                    }))
                  }
                  required
                  size="small"
                  displayEmpty
                >
                  <MenuItem value="">Todos los años</MenuItem>
                  {uniqueYears.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            lg={2}
            sx={{ mt: { xs: 2, md: 0 } }}
          >
            <Button
              fullWidth
              sx={{
                bgcolor: "secondary.main",
                color: "primary.contrastText",
                marginLeft: { sm: 0, md: 2, lg: 2 },
                boxShadow:
                  "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                ":hover": {
                  bgcolor: "#ebebeb",
                  boxShadow:
                    "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                },
              }}
              onClick={() => {
                setFilters({
                  centerId: "",
                  clientId: "",
                  month: "",
                  year: "",
                });
              }}
            >
              Limpiar Filtros
            </Button>
          </Grid>
        </Grid>
        <Box
          sx={{
            border: "1px solid #e0e0e0",
            padding: 1,
            borderRadius: 1,
            my: 1,
            display: { xs: "none", sm: "none", md: "inherit" },
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              align="center"
              sx={{ px: "0!important" }}
            >
              <Typography variant="body1">Centro</Typography>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              align="center"
              sx={{ px: "0!important" }}
            >
              <Typography variant="body1">Fecha</Typography>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
            <Grid
              item
              xs={12}
              sm={12}
              md={1.5}
              lg={1.5}
              align="center"
              sx={{ px: "0!important" }}
            >
              <Box sx={{ maxWidth: "85%" }}>
                <Typography variant="body1">Hora Inicio- Hora Fin</Typography>
              </Box>
            </Grid>

            <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              align="center"
              sx={{ px: "0!important" }}
            >
              <Box sx={{ maxWidth: "90%" }}>
                <Typography variant="body1" noWrap>
                  Nota
                </Typography>
              </Box>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
            <Grid
              item
              xs={12}
              sm={12}
              md={2.2}
              lg={2.2}
              align="center"
              sx={{ px: "0!important" }}
            >
              <Typography variant="body1" noWrap>
                Paciente
              </Typography>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
            <Grid
              item
              xs={12}
              sm={12}
              md={1.9}
              lg={1.9}
              align="center"
              sx={{ px: "0!important" }}
            >
              <Typography variant="body1" noWrap>
                Acciones
              </Typography>
            </Grid>
          </Grid>
        </Box>
        {filteredAppointments.length < 1 && (
          <Stack mt={2}>
            <Alert severity="warning">No hay citas agendadas.</Alert>
          </Stack>
        )}
        {filteredAppointments &&
          filteredAppointments.map((row, index) => {
            return (
              <Box
                key={index}
                sx={{
                  border: "1px solid #e0e0e0",
                  padding: 2,
                  borderRadius: 1,
                  my: 1,
                }}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={2}
                    lg={2}
                    align="center"
                    sx={{ px: "0!important" }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ display: { md: "none" } }}
                    >
                      Centro
                    </Typography>
                    <Typography variant="body1" fontWeight={400}>
                      {row.center_name}
                    </Typography>
                  </Grid>
                  <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={2}
                    lg={2}
                    align="center"
                    sx={{ px: "0!important" }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ display: { md: "none" } }}
                    >
                      Fecha
                    </Typography>
                    <Typography variant="body1" fontWeight={400}>
                      {DateCusmton(row.date + "T00:00:00.000Z")}
                    </Typography>
                  </Grid>
                  <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={1.5}
                    lg={1.5}
                    align="center"
                    sx={{ px: "0!important" }}
                  >
                    <Box sx={{ maxWidth: "85%" }}>
                      <Typography
                        variant="body1"
                        sx={{ display: { md: "none" } }}
                      >
                        Hora Inicio - Hora Fin
                      </Typography>
                      <Typography variant="body1" fontWeight={400}>
                        {row.startAt} {" - "}
                        {row.endAt}
                      </Typography>
                    </Box>
                  </Grid>
                  <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={2}
                    lg={2}
                    align="center"
                    sx={{ px: "0!important" }}
                  >
                    <Box sx={{ maxWidth: "90%" }}>
                      <Typography
                        variant="body1"
                        sx={{ display: { md: "none" } }}
                      >
                        Nota
                      </Typography>
                      <Typography variant="body1" fontWeight={400} noWrap>
                        {row.note || "sin notas"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
                  <Grid item xs={12} sm={12} md={2.2} lg={2.2} align="center">
                    <Typography
                      variant="body1"
                      sx={{ display: { md: "none" } }}
                    >
                      Paciente
                    </Typography>
                    <Typography variant="body1" noWrap fontWeight={400}>
                      {capitalizarCadena(
                        `${row.client_name} ${row.client_lastname}`
                      )}
                    </Typography>
                  </Grid>
                  <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />

                  <Grid item xs={12} sm={12} md={1.9} lg={1.9} align="center">
                    <IconButton
                      aria-label="edit"
                      size="large"
                      title="Editar"
                      color="secondary"
                      sx={{
                        mx: 1,
                        bgcolor: "#aed6f1",
                      ":hover": {
                        bgcolor: "#C1CEE8!important",
                        boxShadow:
                          "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                      },
                      }}
                      onClick={(e) => handleEdit(row?.id)}
                    >
                      <CreateOutlinedIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                     aria-label="delete"
                     title="Eliminar"
                     size="large"
                     color="secondary"
                     sx={{
                       bgcolor: "#f5b7b1",
                       ":hover": {
                         bgcolor: "#DEBFBF",
                         boxShadow:
                           "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                       },
                     }}
                      onClick={() => setOpenModalDelete({ id: row.id, open: true })}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>
            );
          })}
      </Container>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openbackd}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <ModalDiary
        loadApi={loadApi}
        client_id={clientid}
        centers={centers}
        mainDataCreate={mainDataCreate}
        setMainDataCreate={setMainDataCreate}
        openModalCreateAppo={openModalCreateAppo}
        setOpenModalCreateAppo={setOpenModalCreateAppo}
        setOpenbackd={setOpenbackd}
        showSnack={showSnack}
        registros={registros}
        setRegistros={setRegistros}
      />

      <EditModalDiary
        loadApi={loadApi}
        client_id={clientid}
        centers={centers}
        mainDataEdit={mainDataEdit}
        setMainDataEdit={setMainDataEdit}
        openModalEditAppo={openModalEditAppo}
        setOpenModalEditAppo={setOpenModalEditAppo}
        setOpenbackd={setOpenbackd}
        showSnack={showSnack}
        registros={registros}
        setRegistros={setRegistros}
      />

      <ModalDelete
        loadApi={loadApi}
        openModalDelete={openModalDelete.open}
        setOpenModalDelete={setOpenModalDelete}
        title={"¿Está seguro que desea eliminar esta cita?"}
        setDeleteBool={setDeleteBool}
        />

      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
    </>
  );
}
