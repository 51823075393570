export function fetchChats(
  clientid,
  setChats,
  showSnack,
  setOpenbackd,
  loadApi
) {
  setOpenbackd(true);
  loadApi(`externalChats/sentMessages/${clientid}`, true, "get")
    .then((response) => {
      setChats(response.data.external_chats);
    })
    .catch((e) => {
      showSnack(e.message);
      setOpenbackd(false);
    })
    .finally((e) => {
      setOpenbackd(false);
    });
}

export function fetchProfile(
  setPhoto_profile,
  showSnack,
  setOpenbackd,
  loadApi
) {
  setOpenbackd(true);
  loadApi("profile", true, "get")
    .then((response) => {
      setPhoto_profile(response.data.photo_profile.url);
    })
    .catch((e) => {
      showSnack(e.message);
      setOpenbackd(false);
    })
    .finally((e) => {
      setOpenbackd(false);
    });
}
