export function fetchCenterOn(setCentersfiltred, showSnack, loadApi) {
  loadApi("center/centers_on", true, "get")
    .then((response) => {
      setCentersfiltred(response.data);
    })
    .catch((e) => {
      showSnack(e.message);
    });
}

export function fetchInvoices(setOpenbackd, loadApi, setIncomes, showSnack) {
  setOpenbackd(true);
  loadApi("invoice/get_invoices", true, "get")
    .then((response) => {
      const updatedData = response.data.invoices.map((item) => ({
        ...item,
        amount: parseFloat(item.amount.replace(/,/g, "")), // Eliminar la coma
      }));
      setIncomes(updatedData);
    })
    .catch((e) => {
      showSnack(e.message);
      setOpenbackd(false);
    })
    .finally((e) => {
      setOpenbackd(false);
    });
}

export function fetchInvoicesById(
  setOpenbackd,
  loadApi,
  setNamecomplete,
  showSnack,
  setInvoices,
  clientid
) {
  setOpenbackd(true);
  loadApi(`invoice/get_client/${clientid}`, true, "get")
    .then((response) => {
      setNamecomplete(
        response.data?.client.name + " " + response.data?.client.lastname
      );

      setInvoices(response.data.invoices);
    })
    .catch((e) => {
      showSnack(e.message);
      setOpenbackd(false);
    })
    .finally((e) => {
      setOpenbackd(false);
    });
}

export function fetchClients(loadApi, setClients, showSnack) {
  loadApi("client/clients", true, "get")
    .then((response) => {
      setClients(response.data);
    })
    .catch((e) => {
      showSnack(e.message);
    });
}

export function fetchInvoice_by_month(setInvoice, loadApi, showSnack) {
  loadApi("invoice/invoices_by_month", true, "get")
    .then((response) => {
      const rta = response.data?.invoicesWithDetails;
      const updatedData = rta.map((item) => ({
        ...item,
        amount: parseFloat(item.amount.replace(/,/g, "")), // Eliminar la coma
      }));

      setInvoice(updatedData);
    })
    .catch((e) => {
      showSnack(e.message);
    });
}

//invoicesByMonth
