export function getUniqueYearsFromIncomes(incomes) {
  const yearsSet = new Set();
  incomes.forEach((income) => {
    const year = new Date(income.date).getFullYear();
    yearsSet.add(year);
  });
  return Array.from(yearsSet);
}

export const getOption = (incomes, filters, amount) => {
  const selectedYear = filters.year ? parseInt(filters.year) : null;

  // Filtrar los datos de `amount` para solo incluir los meses del año seleccionado
  const filteredAmounts = Object.keys(amount).reduce((acc, month) => {
    const currentMonthData = incomes.filter((item) => {
      const itemDate = new Date(item.date);
      const itemYear = itemDate.getFullYear();
      const itemMonthName = monthNames[itemDate.getMonth() + 1];

      return (
        (selectedYear ? itemYear === selectedYear : true) &&
        itemMonthName === month
      );
    });

    if (currentMonthData.length > 0) {
      acc[month] = amount[month];
    } else {
      acc[month] = 0; // Asignar 0 si no hay datos para el mes actual
    }

    return acc;
  }, {});

  return {
    title: {
      text: "Ingresos por Mes",
      left: "center", // Centrar el título horizontalmente
      textStyle: {
        color: "#333",
        fontSize: 18,
      },
    },
    tooltip: {
      trigger: "axis",
    },
    xAxis: {
      type: "category",
      data: [
        "ENE",
        "FEB",
        "MAR",
        "ABR",
        "MAY",
        "JUN",
        "JUL",
        "AGO",
        "SEP",
        "OCT",
        "NOV",
        "DIC",
      ],
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        name: "Ingreso",
        type: "bar",
        data: Object.keys(filteredAmounts)?.map(
          (month) => filteredAmounts[month]
        ),
        itemStyle: {
          color: "#002B44",
        },
      },
    ],
  };
};

export const newAmounts = {
  Enero: 0,
  Febrero: 0,
  Marzo: 0,
  Abril: 0,
  Mayo: 0,
  Junio: 0,
  Julio: 0,
  Agosto: 0,
  Septiembre: 0,
  Octubre: 0,
  Noviembre: 0,
  Diciembre: 0,
};

export const monthNames = {
  1: "Enero",
  2: "Febrero",
  3: "Marzo",
  4: "Abril",
  5: "Mayo",
  6: "Junio",
  7: "Julio",
  8: "Agosto",
  9: "Septiembre",
  10: "Octubre",
  11: "Noviembre",
  12: "Diciembre",
};

export const newStatus = {
  Enero: {
    Pendiente: { cantidad: 0, valor: 0 },
    Cobrado: { cantidad: 0, valor: 0 },
    Atrasado: { cantidad: 0, valor: 0 },
  },
  Febrero: {
    Pendiente: { cantidad: 0, valor: 0 },
    Cobrado: { cantidad: 0, valor: 0 },
    Atrasado: { cantidad: 0, valor: 0 },
  },
  Marzo: {
    Pendiente: { cantidad: 0, valor: 0 },
    Cobrado: { cantidad: 0, valor: 0 },
    Atrasado: { cantidad: 0, valor: 0 },
  },
  Abril: {
    Pendiente: { cantidad: 0, valor: 0 },
    Cobrado: { cantidad: 0, valor: 0 },
    Atrasado: { cantidad: 0, valor: 0 },
  },
  Mayo: {
    Pendiente: { cantidad: 0, valor: 0 },
    Cobrado: { cantidad: 0, valor: 0 },
    Atrasado: { cantidad: 0, valor: 0 },
  },
  Junio: {
    Pendiente: { cantidad: 0, valor: 0 },
    Cobrado: { cantidad: 0, valor: 0 },
    Atrasado: { cantidad: 0, valor: 0 },
  },
  Julio: {
    Pendiente: { cantidad: 0, valor: 0 },
    Cobrado: { cantidad: 0, valor: 0 },
    Atrasado: { cantidad: 0, valor: 0 },
  },
  Agosto: {
    Pendiente: { cantidad: 0, valor: 0 },
    Cobrado: { cantidad: 0, valor: 0 },
    Atrasado: { cantidad: 0, valor: 0 },
  },
  Septiembre: {
    Pendiente: { cantidad: 0, valor: 0 },
    Cobrado: { cantidad: 0, valor: 0 },
    Atrasado: { cantidad: 0, valor: 0 },
  },
  Octubre: {
    Pendiente: { cantidad: 0, valor: 0 },
    Cobrado: { cantidad: 0, valor: 0 },
    Atrasado: { cantidad: 0, valor: 0 },
  },
  Noviembre: {
    Pendiente: { cantidad: 0, valor: 0 },
    Cobrado: { cantidad: 0, valor: 0 },
    Atrasado: { cantidad: 0, valor: 0 },
  },
  Diciembre: {
    Pendiente: { cantidad: 0, valor: 0 },
    Cobrado: { cantidad: 0, valor: 0 },
    Atrasado: { cantidad: 0, valor: 0 },
  },
};
