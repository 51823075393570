import { parse, format } from "@formkit/tempo";
const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export function getProfesionalAvailability(
  id,
  setAvailability,
  showSnack,
  loadApi
) {
  loadApi("web/availability/" + id, true, "get")
    .then((response) => {
      const data = response.data.availability;

      const updatedData = data.map((item) => {
        const formattedStart = format({
          date: `${item.date}T${item.start}:00.000Z`,
          format: "YYYY-MM-DD HH:mm",
          tz: timeZone,
        });

        const formattedEnd = format({
          date: `${item.date}T${item.end}:00.000Z`,
          format: "YYYY-MM-DD HH:mm",
          tz: timeZone,
        });

        // Verifica si el día en 'start' cambió
        const newDate = formattedStart.split(" ")[0]; // Toma solo la parte de la fecha

        return {
          ...item,
          date: newDate !== item.date ? newDate : item.date, // Actualiza 'date' si cambió
          start: formattedStart,
          end: formattedEnd,
          startUTC: item.start,
          endUTC: item.end,
          dateUTC: item.date,
        };
      });

      setAvailability({
        professionalInfo: response.data.professionalInfo,
        availability: updatedData,
      });
    })
    .catch((e) => {
      showSnack(e.message);
    });
}

export function postProfesionalAvailability(
  appointmentData,
  loadApi,
  showSnack,
  id,
  setFormData,
  setOpenbackd
) {
  setOpenbackd(true);
  loadApi("web/calendar/" + id, true, "post", appointmentData)
    .then((response) => {
      showSnack(response.data.message, "success");
      setFormData({
        name: "",
        lastname: "",
        national_id: "",
        email: "",
        phone: "",
        note: "",
        address: "",
      });
    })
    .catch((e) => {
      showSnack(e.message);
      setOpenbackd(false);
    })
    .finally((e) => {
      setOpenbackd(false);
    });
}
